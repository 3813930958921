import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");


/*** GetStudentsBirthdayDetails Action ***/
export const GetStudentsBirthdayDetailsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentsBirthdayDetails_START,
    };
};
export const GetStudentsBirthdayDetailsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentsBirthdayDetails_SUCCESS,
    };
};
export const GetStudentsBirthdayDetailsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentsBirthdayDetails_FAIL,
    };
};
export const GetStudentsBirthdayDetails = () => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentsBirthdayDetailsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentsBirthdayDetails`,
            params: {

            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentsBirthdayDetailsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentsBirthdayDetailsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** GetAllDashboardDiscadedAttendance Action ***/

export const GetAllDashboardDiscadedAttendanceStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllDashboardDiscadedAttendance_START,
    };
};
export const GetAllDashboardDiscadedAttendanceSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllDashboardDiscadedAttendance_SUCCESS,
    };
};
export const GetAllDashboardDiscadedAttendanceFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllDashboardDiscadedAttendance_FAIL,
    };
};
export const GetAllDashboardDiscadedAttendance = (user_id,start_date,end_date ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllDashboardDiscadedAttendanceStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllDashboardDiscadedAttendance`,
            params: {
               user_id:user_id,
               start_date:start_date,
               end_date:end_date
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllDashboardDiscadedAttendanceSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllDashboardDiscadedAttendanceFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** GetDashboardStudentsMedicalIssued ***/
export const GetDashboardStudentsMedicalIssuedStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetDashboardStudentsMedicalIssued_START,
    };
};
export const GetDashboardStudentsMedicalIssuedSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetDashboardStudentsMedicalIssued_SUCCESS,
    };
};
export const GetDashboardStudentsMedicalIssuedFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetDashboardStudentsMedicalIssued_FAIL,
    };
};

export const GetDashboardStudentsPastoralIssuedStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetDashboardStudentsPastoralIssued_START,
    };
};
export const GetDashboardStudentsPastoralIssuedSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetDashboardStudentsPastoralIssued_SUCCESS,
    };
};
export const GetDashboardStudentsPastoralIssuedFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetDashboardStudentsPastoralIssued_FAIL,
    };
};

export const GetDashboardStudentsOperationalIssuedStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetDashboardStudentsOperationalIssued_START,
    };
};
export const GetDashboardStudentsOperationalIssuedSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetDashboardStudentsOperationalIssued_SUCCESS,
    };
};
export const GetDashboardStudentsOperationalIssuedFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetDashboardStudentsOperationalIssued_FAIL,
    };
};

export const GetDashboardStudentsMedicalIssued = (start_date, end_date, dormitory_id , class_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetDashboardStudentsMedicalIssuedStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetDashboardStudentsMedicalIssued`,
            params: {
                start_date: start_date,
                end_date: end_date,
                dormitory_id: dormitory_id,
                class_id : class_id
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetDashboardStudentsMedicalIssuedSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetDashboardStudentsMedicalIssuedFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
export const GetDashboardStudentsPastoralIssued = (start_date, end_date, dormitory_id , class_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetDashboardStudentsPastoralIssuedStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetDashboardStudentsPastoralIssued`,
            params: {
                start_date: start_date,
                end_date: end_date,
                dormitory_id: dormitory_id,
                class_id : class_id
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetDashboardStudentsPastoralIssuedSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetDashboardStudentsPastoralIssuedFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const GetDashboardStudentsOperationalIssued = (start_date, end_date, dormitory_id , class_id) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetDashboardStudentsOperationalIssuedStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetDashboardStudentsOperationalIssued`,
            params: {
                start_date: start_date,
                end_date: end_date,
                dormitory_id: dormitory_id,
                class_id : class_id
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetDashboardStudentsOperationalIssuedSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetDashboardStudentsOperationalIssuedFail("Something went wrong, Please try again."));
                return error;
            });
    };
};





/*** GetUserAttendanceCountForChart ***/
export const GetUserAttendanceCountForChartStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetUserAttendanceCountForChart_START,
    };
};
export const GetUserAttendanceCountForChartSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetUserAttendanceCountForChart_SUCCESS,
    };
};
export const GetUserAttendanceCountForChartFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetUserAttendanceCountForChart_FAIL,
    };
};
export const GetUserAttendanceCountForChart = (user_id, start_date, end_date) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetUserAttendanceCountForChartStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetUserAttendanceCountForChart`,
            params: {
                user_id: user_id,
                start_date: start_date,
                end_date: end_date
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetUserAttendanceCountForChartSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetUserAttendanceCountForChartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** ExportMedicalIssuedStudentsPdf ***/

export const ExportMedicalIssuedStudentsPdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportMedicalIssuedStudentsPdf_START,
    };
};
export const ExportMedicalIssuedStudentsPdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportMedicalIssuedStudentsPdf_SUCCESS,
    };
};
export const ExportMedicalIssuedStudentsPdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportMedicalIssuedStudentsPdf_FAIL,
    };
};
export const ExportMedicalIssuedStudentsPdf = (d) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportMedicalIssuedStudentsPdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ExportMedicalIssuedStudentsPdf`,
            params: {
               
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportMedicalIssuedStudentsPdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportMedicalIssuedStudentsPdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** ExportPastoralIssuedStudentsPdf ***/

export const ExportPastoralIssuedStudentsPdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportPastoralIssuedStudentsPdf_START,
    };
};
export const ExportPastoralIssuedStudentsPdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportPastoralIssuedStudentsPdf_SUCCESS,
    };
};
export const ExportPastoralIssuedStudentsPdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportPastoralIssuedStudentsPdf_FAIL,
    };
};
export const ExportPastoralIssuedStudentsPdf = (d) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportPastoralIssuedStudentsPdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ExportPastoralIssuedStudentsPdf`,
            params: {
               
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportPastoralIssuedStudentsPdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportPastoralIssuedStudentsPdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const ExportOperationalIssuedStudentsPdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportOperationalIssuedStudentsPdf_START,
    };
};
export const ExportOperationalIssuedStudentsPdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportOperationalIssuedStudentsPdf_SUCCESS,
    };
};
export const ExportOperationalIssuedStudentsPdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportOperationalIssuedStudentsPdf_FAIL,
    };
};

export const ExportOperationalIssuedStudentsPdf = (d) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportOperationalIssuedStudentsPdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/ExportOperationalIssuedStudentsPdf`,
            params: {
               
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportOperationalIssuedStudentsPdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportOperationalIssuedStudentsPdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
