import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import IconButton from '@mui/material/IconButton';
import { toast } from "react-toastify";
import * as userActions from "../../actions/index";
import { connect, useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import { Link } from 'react-router-dom';



const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info.school_code;

function DeleteRoleModal(props) {
    const [managersByRole, setManagersByRole] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();

    const GetAllManagersByRoleId = async () => {
        dispatch(userActions.GetAllManagersByRoleId(props.role_id)).then((response) => {
            if (response.success === true) {
                setManagersByRole(response.data.rows)
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    };


    useEffect(() => {
        GetAllManagersByRoleId()
    }, [])


    const submitData = () => {
        props.DeleteRole(props.role_uuid)
        setShow(false)

    }

    return (
        <>
            <Tooltip title="Delete Role" placement="top">
                <IconButton variant="primary" onClick={handleShow}>
                    <i className="fas fa-trash-alt fa-xs"></i>
                </IconButton>
            </Tooltip>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                {/* “This Role is currently assigned to the following Manager/s:- David Warner; Meg Lanning. Please assign the names to a different Role before deleting or deactivating it.” */}
                    <div>
                        {managersByRole.length > 0 ?
                            <p className='text-start mb-0' >
                              The Role that you are trying to delete is associated to the following Manager:-  {managersByRole.map((item) => {
                                    return (<Link to={`/${school_code}/manager-list/edit-manager/${item.user_uuid}`}>{item.manger_name} </Link>)
                                })}. 
                                Please assign a different role to this Manager to be able to delete this role.
                            </p>
                            :
                            <p className="text-center mb-0">
                                Do you want delete this role ?
                            </p>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0 justify-content-center pt-0'>
                    <Button className='btn-sm' variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    {managersByRole.length === 0 ?
                        <Button className='btn-sm' variant="primary" onClick={submitData}>
                            Delete
                        </Button>
                        : ""
                    }
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default DeleteRoleModal;