import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip } from "@mui/material";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import * as userActions from "../../../actions/index";
import BorderLeaveApprovalModal from '../LeaveModals/BorderLeaveApprovalModal';
import BorderLeaveRejectModal from '../LeaveModals/BorderLeaveRejectModal';
import BorderLeaveApproveModal from '../LeaveModals/BorderLeaveApproveModal';
import ArchiveBorderLeaveModal from '../LeaveModals/ArchiveBorderLeaveModal';
import HostLeaveApproval from '../../../containers/Auth/HostLeaveApproval';
import CapitalFirstLetter from '../../Utility/CapitalFirstLetter';
const customStyles = {
   option: (provided, state) => ({
      ...provided,
      "&:hover": {
         backgroundColor: state.isFocused ? "#e7f5fe" : "",
      },
      backgroundColor: state.isSelected ? "#4a8fb8" : "",
   }),
};

const BoardersLeaveDetails = (props) => {
   const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
   const school_code = user_info?.school_code ?? null;
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const [rowVisibility, setRowVisibility] = useState({}); // State for row visibility
   const [page, setPage] = useState(1);
   const [limit, setLimit] = useState(10);
   const [count, setCount] = useState();
   const [sort, setSort] = useState("departure_date");
   const [order, setOrder] = useState("desc");
   const [search, setSearch] = useState("");
   const [from_date, setFromDate] = useState("");
   const [to_date, setToDate] = useState("");
   const [dormitory_id, setdormitory_id] = useState([]);
   const [dormitory_ids, setdormitory_ids] = useState("");
   const [class_id, setclass_id] = useState([]);
   const [class_ids, setclass_ids] = useState("");
   const [BorderLeaveList, setBorderLeaveList] = useState([]);
   const [userApprovalCounts, setUserApprovalCounts] = useState('');
   const [showModal, setShowModal] = useState("")
   const [approvedBy, setApprovedBy] = useState("")
   const [boarderLeaveData, setBoarderLeaveData] = useState({})
   const [showBoarderLeaveApprovedModal, setShowBoarderLeaveApprovedModal] = useState(false)
   const [showBoarderLeaveRejectModal, setShowBoarderLeaveRejectModal] = useState(false)
   const [filterBySort, setFilterBySort] = useState("departure_date")
   const [filterByorder, setFilterByorder] = useState(false)
   const [isLoading, setIsLoading] = useState(false)
   const permission_info = JSON.parse(localStorage.getItem("permissions"));

   const row_limit = [
      { value: 5, label: 5 },
      { value: 10, label: 10 },
      { value: 15, label: 15 },
      { value: 20, label: 20 },
      { value: 25, label: 25 }
   ]

   const handleToggleRowVisibility = (index) => {
      setRowVisibility((prevState) => ({
         ...prevState,
         [index]: !prevState[index], // Toggle visibility for the specified index
      }));
   };
   // 
   const handleErrorResponse = (response) => {
      try {
         if (response.status === 401) {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
               navigate('/logout');
            }, 5000);
         } else {
            //  error cases
         }
      }
      catch (e) {
      }
   };

   useEffect(() => {
      GetAllBorderLeavesDetails();
   }, [page, limit, sort, filterByorder, search, from_date, to_date, class_ids, dormitory_ids, filterBySort]);


   const GetAllBorderLeavesDetails = async () => {
      dispatch(userActions.GetAllBorderLeavesDetails(page, limit, filterBySort, filterByorder, search, from_date, to_date, class_ids, dormitory_ids)).then((response) => {
         if (response.success === true) {
            const arrayData = response.data.rows;
            setBorderLeaveList(arrayData);
            setCount(response.data.count)
            setUserApprovalCounts(response.user_approval_counts[0])
         }
         else {
            handleErrorResponse(response);
         }
      });
      // eslint-disable-next-line
   };


   useEffect(() => {
      GetAllClassesList();
      GetAllDormitoryStudent();
   }, []);

   const GetAllClassesList = async () => {
      props.onGetAllClassesList().then((response) => {
         if (response.success === true) {
            const arrayData = response.data.rows;
            let state_data = [];
            state_data.push({
               label: "Select Year",
               value: "",
            })
            if (permission_info) {
               const permClassDormArray = permission_info?.perm_class_dorm;
               const filteredPermDorm = permClassDormArray?.filter(item => item?.class_id !== null)
               const allowedDormIds = filteredPermDorm?.map(item => item?.class_id);
               const filteredData = arrayData?.filter(item => allowedDormIds?.includes(item?.class_id));
               for (let key in filteredData) {
                  state_data.push({
                     label: filteredData[key].class_name,
                     value: filteredData[key].class_id,
                     class_id: filteredData[key].class_id,
                  });
               }
            } else {
               for (let key in arrayData) {
                  state_data.push({
                     label: arrayData[key].class_name,
                     value: arrayData[key].class_id,
                     class_id: arrayData[key].class_id,
                  })
               }
            }
            setclass_id(state_data);
         }
      });
   };

   const GetAllDormitoryStudent = async () => {
      props.onGetAllDormitoryStudent().then((response) => {
         if (response.success === true) {
            let state_data = [];
            state_data.push({
               label: "Select Dormitory",
               value: "",
            });
            const arrayData = response.data.rows;
            if (permission_info) {
               const permClassDormArray = permission_info?.perm_class_dorm;
               const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
               const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
               const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
               for (let key in filteredData) {
                  state_data.push({
                     label: filteredData[key].dormitory_name,
                     value: filteredData[key].dormitory_id,
                     dormitory_id: filteredData[key].dormitory_id,
                  });
               }
            } else {
               for (let key in arrayData) {
                  state_data.push({
                     label: arrayData[key].dormitory_name,
                     value: arrayData[key].dormitory_id,
                     dormitory_id: arrayData[key].dormitory_id,
                  });
               }
            }
            setdormitory_id(state_data);
         } else {
            //notify.show(response.message, "error");
         }
      });
   };



   const ArchiveOrUnarchiveBorderLeave = async (border_leave_id, is_leave_archived) => {
      try {
         const { success, message } = await dispatch(userActions.ArchiveOrUnarchiveBorderLeave({ border_leave_id, is_leave_archived: true }));
         if (success) {
            toast.success(message, {
               position: toast.POSITION.TOP_CENTER,
            });

            navigate(`/${school_code}/leave/boarders-leave-details`)
            GetAllBorderLeavesDetails();
         } else {
            toast.error(message, {
               position: toast.POSITION.TOP_CENTER,
            });
         }
      } catch (error) {

      }
      // eslint-disable-next-line
   };



   const approveBoarderLeave = (leaveData, approvedBy) => {
      setShowModal(true)
      setBoarderLeaveData(leaveData)
      setApprovedBy(approvedBy)
   }

   const hideApprovelLeaveModal = (value) => {
      setShowModal(value)
   };

   const approveAndRejectModal = (approvel) => {
      setShowModal(approvel.value)
      if (approvel.mode === "reject") { setShowBoarderLeaveRejectModal(true) }
      if (approvel.mode === "approve") { setShowBoarderLeaveApprovedModal(true) }
   };

   const hideRejectLeaveModal = (value) => {
      setShowBoarderLeaveRejectModal(value)
   };

   const hideApprovedLeaveModal = (value) => {
      setShowBoarderLeaveApprovedModal(value)
   };

   const BorderLeaveApproval = async (form_data) => {
      setIsLoading(true);
      try {
         const { success, message } = await dispatch(userActions.BorderLeaveApproval(form_data));
         if (success) {
            setIsLoading(false);
            toast.success(message, {
               position: toast.POSITION.TOP_CENTER,
            });
            setShowBoarderLeaveRejectModal(false)
            setShowBoarderLeaveApprovedModal(false)
            GetAllBorderLeavesDetails()
         } else {
            setIsLoading(false);
            toast.error(message, {
               position: toast.POSITION.TOP_CENTER,
            });
         }
      } catch (error) {
         // Handle error if necessary
      }
   };

   return (
      <>
         {showModal &&
            <BorderLeaveApprovalModal
               showModal={showModal}
               hideApprovelLeaveModal={(value) => hideApprovelLeaveModal(value)}
               approveAndRejectModal={(approvel) => approveAndRejectModal(approvel)}
               boarderLeaveData={boarderLeaveData}
            />
         }

         {showBoarderLeaveRejectModal &&
            <BorderLeaveRejectModal
               showBoarderLeaveRejectModal={showBoarderLeaveRejectModal}
               hideRejectLeaveModal={(value) => hideRejectLeaveModal(value)}
               boarderLeaveData={boarderLeaveData}
               approvedBy={approvedBy}
               isLoading={isLoading}
               BorderLeaveApproval={(form_data) => BorderLeaveApproval(form_data)}
            />
         }
         {showBoarderLeaveApprovedModal &&
            <BorderLeaveApproveModal
               showBoarderLeaveApprovedModal={showBoarderLeaveApprovedModal}
               hideApprovedLeaveModal={(value) => hideApprovedLeaveModal(value)}
               boarderLeaveData={boarderLeaveData}
               approvedBy={approvedBy}
               isLoading={isLoading}
               BorderLeaveApproval={(form_data) => BorderLeaveApproval(form_data)}
            />
         }
         <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <SideBarLeave />
                  <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                     <TopBar />
                     <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                           <div id="kt_content_container" className="container-fluid">
                              <div className="row">
                                 <div className="col-lg-3">
                                    <div className="card bg-light-warning card-xl-stretch">
                                       <div className="card-body d-flex align-items-center p-5 justify-content-between">
                                          <a href="#" className="card-title text-dark fw-bolder fs-6 d-block mb-0">Total Applications</a>
                                          <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center">
                                             <span className="text-dark fs-1 fw-bolder ">{userApprovalCounts.total_leaves}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-lg-3">
                                    <div className="card bg-light-success card-xl-stretch">
                                       <div className="card-body d-flex align-items-center p-5 justify-content-between">
                                          <a href="#" className="card-title fw-bolder text-dark  fs-6 d-block mb-0">Approved Leave</a>
                                          <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center">
                                             <span className="text-dark fs-1 fw-bolder ">{userApprovalCounts.total_approved}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-lg-3">
                                    <div className="card bg-light-danger card-xl-stretch">
                                       <div className="card-body d-flex align-items-center p-5 justify-content-between">
                                          <a href="#" className="card-title fw-bolder text-dark  fs-6 d-block mb-0">Rejected Leave</a>
                                          <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center">
                                             <span className="text-dark fs-1 fw-bolder ">{userApprovalCounts.total_rejected}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-lg-3">
                                    <div className="card bg-light-primary  card-xl-stretch">
                                       <div className="card-body d-flex align-items-center p-5 justify-content-between">
                                          <a href="#" className="card-title fw-bolder text-dark  fs-6 d-block mb-0">Pending Leave</a>
                                          <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center">
                                             <span className="text-dark fs-1 fw-bolder ">{userApprovalCounts.total_pending}</span>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className='row mt-5'>
                                 <div className='col-md-12'>
                                    <div className='card'>
                                       <div className='card-header align-items-center'>
                                          <h3 className='card-title'>Student Search</h3>
                                       </div>
                                       <div className='card-body'>

                                          <div className='row align-items-end'>
                                             <div className="col">
                                                <label className='form-label'>Select Year</label>
                                                <Select
                                                   styles={customStyles}
                                                   className="react-bootstrap-typeahead tag-outer select2-selection select2-selection--single " options={class_id}
                                                   value={class_id.filter((option) => {
                                                      return option.value === class_ids;
                                                   })}
                                                   onChange={(event) => {
                                                      setclass_ids(event.value);
                                                   }}
                                                />
                                             </div>
                                             <div className="col"><label className='form-label'>Select Dormitory</label>
                                                <Select
                                                   styles={customStyles}
                                                   className="react-bootstrap-typeahead tag-outer"
                                                   options={dormitory_id}
                                                   value={dormitory_id.filter((option) => {
                                                      return option.value === dormitory_ids;
                                                   })}
                                                   onChange={(event) => {
                                                      setdormitory_ids(event.value);
                                                   }}
                                                />
                                             </div>
                                             <div className="col">
                                                <label className="me-3 form-label">From Date</label>
                                                <div className='position-relative'>
                                                   <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                      <i class="far fa-calendar-alt"></i>
                                                   </span>
                                                   <input
                                                      id="session-date"
                                                      type="date"
                                                      className="form-control bg-transparent"
                                                      placeholder='Date'
                                                      value={from_date}
                                                      onChange={(e) => setFromDate(e.target.value)}
                                                      required
                                                   />
                                                </div>
                                             </div>
                                             <div className="col">
                                                <label className="ms-3 me-3 form-label">To Date</label>
                                                <div className='position-relative'>
                                                   <span className='position-absolute apply_adhoc_leave_date_and_time'>
                                                      <i class="far fa-calendar-alt"></i>
                                                   </span>
                                                   <input
                                                      id="session-date"
                                                      type="date"
                                                      className="form-control bg-transparent"
                                                      placeholder='to'
                                                      value={to_date}
                                                      onChange={(e) => setToDate(e.target.value)}
                                                      min={from_date}
                                                      required
                                                   />
                                                </div>
                                             </div>
                                             <div className='col'>
                                                <button
                                                   type="reset"
                                                   className="ms-3  btn btn-secondary btn-sm btn-color-gray-700 btn-flex btn-active-primary  border-0 fw-bolder px-4 px-lg-6 me-2 me-lg-3 w-200 white-space"
                                                   onClick={(event) => {
                                                      setclass_ids("");
                                                      setdormitory_ids("");
                                                      setFromDate("");
                                                      setToDate("");
                                                   }}
                                                >
                                                   Clear Search
                                                </button>
                                             </div>
                                          </div>

                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div className='row'>
                                 <div className="col-lg-12 mt-5">
                                    <div className='card'>
                                       <div className="card-header align-items-center">
                                          <h3 className="card-title">Approve Leaves Details</h3>
                                       </div>
                                       <div className='card-body'>
                                          <div className='table-responsive'>
                                             <MDBTable className='table-row-bordered'>
                                                <MDBTableHead>
                                                   <tr style={{ borderBottom: "1x solid #cccccc3d" }}>
                                                      <th></th>
                                                      <th>Unique pin</th>
                                                      <th>Student Name</th>
                                                      <th>Year</th>
                                                      <th>Dormitory</th>
                                                      <th>Student Host</th>
                                                      <th className='min-w-150px'>
                                                         Departure Date 
                                                         <i 
                                                            className={`bi bi-filter cursor-pointer ${filterBySort === 'departure_date' ? 'text-primary' : ''}`} 
                                                            onClick={() => { setFilterBySort('departure_date'); setFilterByorder((prev) => !prev) }}>
                                                         </i>
                                                      </th>
                                                      <th className='min-w-100px'>
                                                         Departure Time 
                                                         <i 
                                                            className={`bi bi-filter cursor-pointer ${filterBySort === 'departure_time' ? 'text-primary' : ''}`} 
                                                            onClick={() => { setFilterBySort('departure_time'); setFilterByorder((prev) => !prev) }}>
                                                         </i>
                                                      </th>
                                                      <th>Applied</th>
                                                      <th>Parent Approval</th>
                                                      <th>Host Approval</th>
                                                      <th>Manager Approval</th>
                                                      <th>Leave Status</th>
                                                      <th>Actions</th>
                                                   </tr>
                                                </MDBTableHead>
                                                <MDBTableBody>
                                                {console.log("BorderLeaveList::",BorderLeaveList)}
                                                   {BorderLeaveList?.map((item, index) => {
                                                      return (
                                                         <React.Fragment key={item.border_leave_id}>
                                                            <tr key={item.border_leave_id}>
                                                               <td>
                                                                  <button
                                                                     className={`btn ${rowVisibility[index]
                                                                        ? ' '
                                                                        : ''
                                                                        } btn-sm w-32px`}
                                                                     type="button"
                                                                     onClick={() => handleToggleRowVisibility(index)}
                                                                  >
                                                                     {
                                                                        rowVisibility[index] ?
                                                                           <img src="/media/downarrow.gif" alt="" style={{ width: "32px", transform: "scaleY(-1)" }} />
                                                                           :
                                                                           <img src="/media/downarrow.gif" alt="" style={{ width: "32px" }} />
                                                                     }
                                                                     {/* <i
                                                                        className={`bi ${rowVisibility[index]
                                                                           ? 'up-arrow'
                                                                           : 'down-arrow'
                                                                           } p-0`}
                                                                     ></i> */}
                                                                  </button>
                                                               </td>
                                                               <td>{item.leave_stu_data?.unique_pin || 'N/A'}</td>
                                                               <td>{CapitalFirstLetter(item.leave_stu_data?.student_name) || 'N/A'}</td>
                                                               <td>{item.leave_stu_data?.stu_class_data?.class_name || 'N/A'}</td>
                                                               <td>{item.leave_stu_data?.dormitory_data?.dormitory_name || 'N/A'}</td>
                                                               <td>{item.host_id ? item?.stu_host_data?.host_name : item.parent_id && item.parent_type === 'father' ? item?.parent_data?.father_name : item?.parent_data?.mother_name || 'N/A'}</td>
                                                               <td>{moment(item.departure_date).format('Do MMM. YYYY')}</td>
                                                               <td>{item.departure_time}</td>
                                                               <td>
                                                                  {item.leave_applied_ontime === true ?
                                                                     <Tooltip title="On Time" placement="top">
                                                                        <i className="bi bi-hourglass-split text-success fs-2"></i>
                                                                     </Tooltip>
                                                                     :
                                                                     <Tooltip title="Late" placement="top">
                                                                        <i className="bi bi-hourglass-bottom text-danger fs-2"></i>
                                                                     </Tooltip>
                                                                  }
                                                               </td>
                                                               <td>
                                                                  {item.check_in_out === "cancel" ?
                                                                     <div>
                                                                        <span className="badge badge-light-info me-2">Cancel</span>
                                                                        <Tooltip title="leave canceled due to student not turning up on time" placement="top">
                                                                           <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                 <rect x="0" y="0" width="24" height="24" />
                                                                                 <circle fill="#000000" opacity="0.1" cx="12" cy="12" r="10" />
                                                                                 <rect fill="#4a8fb8 " x="11" y="10" width="2" height="7" rx="1" />
                                                                                 <rect fill="#4a8fb8 " x="11" y="7" width="2" height="2" rx="1" />
                                                                              </g>
                                                                           </svg>
                                                                        </Tooltip>
                                                                     </div>
                                                                     : item?.is_parent_approval === "pending" ?
                                                                        <span className="badge me-2 badge-light-warning cursor-pointer" onClick={() => approveBoarderLeave(item, "parent")}>pending</span>
                                                                        :
                                                                        <div className='d-flex '>
                                                                           <Tooltip title={`(${item?.is_parent_approval})${moment(item.last_parent_approval_date).format('Do MMM YYYY')}`} placement="top">
                                                                              <span className={`badge me-2 badge-light-${item?.is_parent_approval === 'approved' ? 'success' : 'danger'}`}>
                                                                                 {item?.is_parent_approval === 'approved' ? 'Approved' : 'Rejected'}
                                                                              </span>
                                                                           </Tooltip>
                                                                           <Tooltip style={{whiteSpace: 'nowrap'}} title={`${item?.number_matched == true ? "Same IP Detected" : ""}`} placement="top">
                                                                           {item?.number_matched && item?.number_matched === true ? <img
                                                                                 src="/media/red.png"
                                                                                 style={{ width: "16px" , marginRight: '3px' }} 
                                                                              /> : null}
                                                                              {item?.number_matched && item?.number_matched === 'Not Detected' && <img
                                                                                 src="/media/black.png"
                                                                                 style={{ width: "16px" , marginRight: '3px' }} 
                                                                              />}
                                                                           </Tooltip>
                                                                           <Tooltip style={{whiteSpace: 'nowrap'}} title={`${item?.is_parent_approval == 'approved' ? (item.parent_approved_comments ? item.parent_approved_comments : "" ) : (item.parent_rejected_reason_data?.reject_reason? item.parent_rejected_reason_data?.reject_reason: "")}`} placement="top">
                                                                              

                                                                                 {/* {console.log("item?.number_matched",item?.number_matched)} */}
                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                 <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                    <circle fill="#000000" opacity="0.1" cx="12" cy="12" r="10" />
                                                                                    <rect fill="#4a8fb8 " x="11" y="10" width="2" height="7" rx="1" />
                                                                                    <rect fill="#4a8fb8 " x="11" y="7" width="2" height="2" rx="1" />
                                                                                 </g>
                                                                              </svg>
                                                                           </Tooltip>
                                                                        </div>
                                                                  }
                                                               </td>

                                                               <td>
                                                                  {item.check_in_out === "cancel" ?
                                                                     <div>
                                                                        <span className="badge badge-light-info me-2">Cancel</span>
                                                                        <Tooltip title="leave canceled due to student not turning up on time" placement="top">
                                                                           <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                 <rect x="0" y="0" width="24" height="24" />
                                                                                 <circle fill="#000000" opacity="0.1" cx="12" cy="12" r="10" />
                                                                                 <rect fill="#4a8fb8 " x="11" y="10" width="2" height="7" rx="1" />
                                                                                 <rect fill="#4a8fb8 " x="11" y="7" width="2" height="2" rx="1" />
                                                                              </g>
                                                                           </svg>
                                                                        </Tooltip>
                                                                     </div>
                                                                     : item?.is_host_approval === "pending" ?
                                                                        <span className="badge me-2 badge-light-warning cursor-pointer" 
                                                                        // onClick={() => approveBoarderLeave(item, "parent")}
                                                                        >pending</span>
                                                                        :
                                                                        <div className='d-flex '>
                                                                           <Tooltip title={`(${item?.is_host_approval})${moment(item.last_host_approval_date).format('Do MMM YYYY')}`} placement="top">
                                                                              <span className={`badge me-2 badge-light-${item?.is_host_approval === 'approved' ? 'success' : 'danger'}`}>
                                                                                 {item?.is_host_approval === 'approved' ? 'Approved' : 'Rejected'}
                                                                              </span>
                                                                           </Tooltip>{console.log(item.host_approved_comments===null,"::item.host_approved_comments897")}
                                                                           <Tooltip title={`${item?.is_host_approval == 'approved' ? (item.host_approved_comments===null ? "leave approved" : item.host_approved_comments) : item.host_rejected_comments}`} placement="top">
                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                 <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                    <circle fill="#000000" opacity="0.1" cx="12" cy="12" r="10" />
                                                                                    <rect fill="#4a8fb8 " x="11" y="10" width="2" height="7" rx="1" />
                                                                                    <rect fill="#4a8fb8 " x="11" y="7" width="2" height="2" rx="1" />
                                                                                 </g>
                                                                              </svg>
                                                                           </Tooltip>
                                                                        </div>
                                                                  }
                                                                  {/* <span className={`badge me-2 badge-light-${item?.is_host_approval === 'pending' ? 'warning' : item?.is_host_approval === 'approved' ? 'success' : 'danger'}`}>{item.is_host_approval}</span> */}
                                                               </td>
                                                               <td>
                                                                  {item.check_in_out === "cancel" ?
                                                                     <div>
                                                                        <span className="badge badge-light-info me-2">Cancel</span>
                                                                        <Tooltip title="leave canceled due to student not turning up on time" placement="top">
                                                                           <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                 <rect x="0" y="0" width="24" height="24" />
                                                                                 <circle fill="#000000" opacity="0.1" cx="12" cy="12" r="10" />
                                                                                 <rect fill="#4a8fb8 " x="11" y="10" width="2" height="7" rx="1" />
                                                                                 <rect fill="#4a8fb8 " x="11" y="7" width="2" height="2" rx="1" />
                                                                              </g>
                                                                           </svg>
                                                                        </Tooltip>
                                                                     </div>
                                                                     :
                                                                     item?.is_user_approval === "pending" ?
                                                                        <span className="badge me-2 badge-light-warning cursor-pointer" onClick={() => approveBoarderLeave(item, "user")}>pending</span>
                                                                        :
                                                                        <div className='d-flex'>
                                                                           <Tooltip title={`(${item?.is_user_approval})${moment(item.last_user_approval_date).format('Do MMM YYYY')}`} placement="top">
                                                                              <span className={`badge me-2 badge-light-${item?.is_user_approval === 'approved' ? 'success' : 'danger'}`}>
                                                                                 {item?.is_user_approval === 'approved' ? 'Approved' : 'Rejected'}
                                                                              </span>
                                                                           </Tooltip>
                                                                           <Tooltip title={`${item?.is_user_approval == 'approved' ? item.user_approved_comments : item.user_rejected_reason_data?.reject_reason}`} placement="top">
                                                                              <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                                                 <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                                                    <rect x="0" y="0" width="24" height="24" />
                                                                                    <circle fill="#000000" opacity="0.1" cx="12" cy="12" r="10" />
                                                                                    <rect fill="#4a8fb8 " x="11" y="10" width="2" height="7" rx="1" />
                                                                                    <rect fill="#4a8fb8 " x="11" y="7" width="2" height="2" rx="1" />
                                                                                 </g>
                                                                              </svg>
                                                                           </Tooltip>
                                                                        </div>
                                                                  }
                                                               </td>
                                                               <td>
                                                                  <span className={`badge me-2 badge-light-${item.check_in_out === 'in' || item.check_in_out === 'approved' ? 'success' : item.check_in_out === 'out' ? "danger" : item.check_in_out === 'pending' ? "warning" : "info"}`}>
                                                                     {item.check_in_out}
                                                                  </span>
                                                               </td>
                                                               <td>
                                                                  <Tooltip title="View" placement="top">
                                                                     <Link to={`/${school_code}/leave/edit-boarders-leave/${item.border_leave_id}`}>
                                                                        <i className="bi bi-eye-fill text-primary fs-2"></i>
                                                                     </Link>
                                                                  </Tooltip>
                                                               </td>
                                                            </tr>
                                                            {rowVisibility[index] && (
                                                               <>
                                                                  <tr className='text-bg-light'>
                                                                     <th></th>
                                                                     <th colSpan={2}>Return Date</th>
                                                                     <th colSpan={2}>Return Time</th>
                                                                     <th colSpan={2}>Mode Of Travel Departure</th>
                                                                     <th colSpan={2}>Mode Of Travel Return</th>
                                                                     <th colSpan={3}>Submit Date</th>
                                                                     <th colSpan={2}>Submit Time</th>
                                                                     {/* <th>Action</th> */}
                                                                  </tr>
                                                                  <tr className='text-bg-light'>
                                                                     <td></td>
                                                                     <td colSpan={2}>{moment(item.return_date).format('Do MMM. YYYY')}</td>
                                                                     <td colSpan={2}>{item.return_time}</td>
                                                                     <td colSpan={2}>{item.departure_mode_data?.travel_mode_name}</td>
                                                                     <td colSpan={2}>{item.return_mode_data?.travel_mode_name}</td>
                                                                     <td colSpan={3}>{moment(item.created_date).format('Do MMM. YYYY')}</td>
                                                                     <td colSpan={2}>{moment(item.created_date).format('hh:mm a')}</td>
                                                                     {/* <td>
                                                                        <ArchiveBorderLeaveModal ArchiveOrUnarchiveBorderLeave={() => {
                                                                           ArchiveOrUnarchiveBorderLeave(item.border_leave_id, item.is_leave_archived);
                                                                        }} />
                                                                     </td> */}
                                                                  </tr>
                                                               </>
                                                            )}
                                                         </React.Fragment>
                                                      )
                                                   })}
                                                </MDBTableBody>
                                             </MDBTable>
                                          </div>
                                          <div className="d-flex justify-content-between">
                                             <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={row_limit[1]}
                                                name="color"
                                                options={row_limit}
                                                onChange={(e) => setLimit(e.value)}
                                             />
                                             <Stack>
                                                <Pagination
                                                   count={Math.ceil(count / limit)}
                                                   page={page}
                                                   onChange={(e, value) => setPage(value)}
                                                   color="primary"
                                                />
                                             </Stack>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        {/* <HostLeaveApproval/> */}
                     </div>
                     <Footer />
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
   onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
   onGetAllClassesList: userActions.GetAllClassesList,
};
export default connect(mapStateToProps, mapDispatchToProps)(BoardersLeaveDetails);

