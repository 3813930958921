
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import * as userActions from "../../actions/index";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DiscardedSessionItem from "./DiscardedSessionItem"
import { Tooltip } from "@mui/material";
import moment from "moment";


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};

const DiscardedSession = (props) => {
    const dispatch = useDispatch();
    const [allRollCallYear, setAllRollCallYear] = useState([]);
    const [allRollCallMonth, setAllRollCallMonth] = useState([])
    const [allRollcallWeek, setAllRollCallWeek] = useState([])

    const [rollCallYear, setRollCallYear] = useState("")
    const [rollCallMonth, setRollCallMonth] = useState("")
    const [rollCallWeek, setRollCallWeek] = useState("")

    const [allManagers, setAllManagers] = useState([]);
    const [user_id, setUser_id] = useState("");

    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")

    const [sessionData, setSessionData] = useState([]);
    const [SessionDuration, SetSessionDuration] = useState();

    const navigate = useNavigate();


    useEffect(() => {
        if (user_id !== "" && start_date !== "" && end_date !== "") {
            GetAllDashboardDiscadedAttendance();
        }
    }, [user_id, start_date, end_date])

    useEffect(() => {
        GetRollCallDetails();
        GetAllManagersListBySchoolId();
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        let yearOption = [{ label: "Select Year", value: "" },]
        let monthOption = [{ label: "Select Month", value: "" },]
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const timezone = user_info?.user_school?.timezone;
        const current = new Date();
        for (let i = 0; i <= 5; i++) {
            yearOption.push({
                label: moment(current)?.tz(timezone)?.subtract(i, "years").format('YYYY'),
                value: moment(current)?.tz(timezone)?.subtract(i, "years").format('YYYY')
            })
        }
        for (let i = 0; i <= monthNames.length - 1; i++) {
            monthOption.push({ label: monthNames[i], value: i + 1 <= 9 ? `0${i + 1}` : i + 1 })
        }
        setAllRollCallYear(yearOption)
        setAllRollCallMonth(monthOption)
        setDefaultValue()
    }, []);



    const GetRollCallDetails = useCallback(async () => {
        dispatch(userActions.GetRollCallDetails()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data;
                if (arrayData.length > 0) {
                    SetSessionDuration(arrayData[0]?.meta_value_one);
                }
            }
            else {
            }
        });
    }, [dispatch]);

    const GetAllManagersListBySchoolId = useCallback(async () => {
        dispatch(userActions.GetAllManagersListBySchoolId()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let Call_level = [];
                Call_level.push({
                    label: "Select Manager",
                    value: "",
                    managerid: "",
                })
                for (let key in arrayData) {
                    Call_level.push({
                        label: arrayData[key].first_name + " " + arrayData[key].last_name,
                        value: arrayData[key].user_id,
                        managerid: arrayData[key].user_id,
                    })
                }
                // Call_level.push({
                //     label: "All Managers",
                //     value: "All Managers",
                //     managerid: "All Managers",
                // })
                setAllManagers(Call_level);
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);

    const GetAllDashboardDiscadedAttendance = async () => {
        props.onGetAllDashboardDiscadedAttendance(user_id, start_date, end_date).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let Calllevel = [];
                for (let key in arrayData) {
                    Calllevel.push({
                        alotedSessionTime: arrayData[key].alloted_time_limit,
                        sessionEndTime: arrayData[key].created_date,
                        sessionStartTime: arrayData[key].session_start_at,
                        manager: arrayData[key].bss_user.first_name + " " + arrayData[key].bss_user.last_name,
                        managerid: arrayData[key].bss_user.user_id,
                        customName: arrayData[key].attendance_title
                    });
                }
                setSessionData(Calllevel)

            }
            else {
            }
        });
        // eslint-disable-next-line
    };

    const setDefaultValue = () => {
        const year = new Date().getFullYear();
        const month_index = new Date().getMonth();
        setRollCallMonth(month_index + 1 <= 9 ? `0${month_index + 1}` : month_index + 1)
        setRollCallYear(year.toString())

        const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        const month_length = new Date(year, (month_index + 1), 0).getDate();
        const get_day = day_array[new Date(year, (month_index + 1) - 1).getDay()];
        const current_date = new Date().getDate();
        const weeks_data = []
        let week_array;
        const month_dates = []
        for (let i = 1; i <= month_length; i++) {
            month_dates.push(i)
        }
        if (get_day === 'Sun') {
            const all_weeks = Math.floor(month_length / 7);
            const remain_last_day = month_length % 7;
            const all_weeks_slice = month_dates.slice(0, all_weeks * 7)
            const remaining_days = month_dates.slice((all_weeks * 7), month_dates.length)
            weeks_data.push(remaining_days)
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `Week ${weeks_data.length - index}`,
                    value: `${item[0] < 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] < 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        } else {
            const pre_week_days = day_array.length - day_array.indexOf(get_day)
            const remain_last_day = (month_length - pre_week_days) % 7;
            const all_weeks = Math.floor((month_length - pre_week_days) / 7);
            const pre_days_slice = month_dates.slice(0, pre_week_days)
            const all_weeks_slice = month_dates.slice(pre_week_days, (all_weeks * 7) + pre_week_days)
            const remaining_days = month_dates.slice((all_weeks * 7) + pre_week_days, month_dates.length)
            if (remaining_days.length > 0) {
                weeks_data.push(remaining_days);
            }
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            weeks_data.push(pre_days_slice)
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `Week ${weeks_data.length - index}`,
                    value: `${item[0] < 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] < 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        }
        setAllRollCallWeek(week_array.reverse());
        week_array.forEach((item) => {
            if (item.value.length === 3) {
                const roll_start_date = parseInt(item.value.slice(0, 1))
                const roll_end_date = parseInt(item.value.slice(-1))
                if (current_date >= roll_start_date && current_date <= roll_end_date) {
                    setRollCallWeek(item.value)
                    setStart_date(`${year}-${month_index + 1 <= 9 ? `0${month_index + 1}` : month_index + 1}-${roll_start_date <= 9 ? `0${roll_start_date}` : roll_start_date}`)
                    setEnd_date(`${year}-${month_index + 1 <= 9 ? `0${month_index + 1}` : month_index + 1}-${roll_end_date <= 9 ? `0${roll_end_date}` : roll_end_date}`)
                }
            } else {
                const roll_start_date = item.value.length === 4 ? parseInt(item.value.slice(0, 1)) : parseInt(item.value.slice(0, 2));
                const roll_end_date = parseInt(item.value.slice(-2))
                if (current_date >= roll_start_date && current_date <= roll_end_date) {
                    setRollCallWeek(item.value)
                    setStart_date(`${year}-${month_index + 1 <= 9 ? `0${month_index + 1}` : month_index + 1}-${roll_start_date <= 9 ? `0${roll_start_date}` : roll_start_date}`)
                    setEnd_date(`${year}-${month_index + 1 <= 9 ? `0${month_index + 1}` : month_index + 1}-${roll_end_date <= 9 ? `0${roll_end_date}` : roll_end_date}`)
                }
            }
        })
    }


    const handleChange = (data, identifier) => {
        console.log("identifire===========", data, identifier);
        if (identifier === "rollcall_year") {
            setRollCallYear(data.value);
        }
        else if (identifier === "rollcall_month") {
            setRollCallMonth(data.value);
            const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
            const year = rollCallYear;
            const month = data.value;
            const month_length = new Date(year, month, 0).getDate();
            const get_day = day_array[new Date(year, month - 1).getDay()];
            const weeks_data = []
            let week_array;
            const month_dates = []
            for (let i = 1; i <= month_length; i++) {
                month_dates.push(i)
            }
            if (get_day === 'Sun') {
                const all_weeks = Math.floor(month_length / 7);
                const remain_last_day = month_length % 7;
                const all_weeks_slice = month_dates.slice(0, all_weeks * 7)
                const remaining_days = month_dates.slice((all_weeks * 7), month_dates.length)
                weeks_data.push(remaining_days)
                for (let i = 1; i <= all_weeks; i++) {
                    const thirdPart = all_weeks_slice.splice(-7);
                    weeks_data.push(thirdPart)
                }
                week_array = weeks_data.map((item, index) => {
                    return {
                        label: `Week ${weeks_data.length - index}`,
                        value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                    }
                })
            } else {
                const pre_week_days = day_array.length - day_array.indexOf(get_day)
                const remain_last_day = (month_length - pre_week_days) % 7;
                const all_weeks = Math.floor((month_length - pre_week_days) / 7);
                const pre_days_slice = month_dates.slice(0, pre_week_days)
                const all_weeks_slice = month_dates.slice(pre_week_days, (all_weeks * 7) + pre_week_days)
                const remaining_days = month_dates.slice((all_weeks * 7) + pre_week_days, month_dates.length)
                if (remaining_days.length > 0) {
                    weeks_data.push(remaining_days);
                }
                for (let i = 1; i <= all_weeks; i++) {
                    const thirdPart = all_weeks_slice.splice(-7);
                    weeks_data.push(thirdPart)
                }
                weeks_data.push(pre_days_slice)
                week_array = weeks_data.map((item, index) => {
                    return {
                        label: `Week ${weeks_data.length - index}`,
                        value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                    }
                })
            }
            setAllRollCallWeek(week_array.reverse());
        }
        else if (identifier === "rollcall_week") {
            let roll_start_date = `${rollCallYear}-${rollCallMonth}-${data.value.slice(0, 2)}`
            let roll_end_date = `${rollCallYear}-${rollCallMonth}-${data.value.slice(-2)}`
            setRollCallWeek(data.value)
            setStart_date(roll_start_date)
            setEnd_date(roll_end_date)
        }
        else if (identifier === "select_manager") {
            setUser_id(data.value);
        }
    }



    return (
        <>
            <div className="card">
                <div className="card-header align-items-center">
                    <h2 className="mb-0 card-title">
                        Number of discarded Rollcall sessions
                        <Tooltip title={`Duration of Roll Call sessions to be completed at this School, has been set to ${SessionDuration} minutes`} placement="top">
                            <i className="fas fa-question-circle ms-2"></i>
                        </Tooltip>
                    </h2>
                </div>



                <div className="row m-0 px-2 py-4">
                    <div className="col-lg-4">
                        <label className="fw-bold mb-2">Select Year</label>
                        <Select
                            styles={customStyles}
                            className="react-bootstrap-typeahead tag-outer "
                            options={allRollCallYear}
                            value={allRollCallYear.filter(function (option) {
                                return option.value === rollCallYear;
                            })}
                            onChange={(newValue) => handleChange(newValue, "rollcall_year")}
                        />
                    </div>

                    <div className="col-lg-4 fv-row">
                        <label className="fw-bold mb-2">Select month</label>
                        <Select
                            styles={customStyles}
                            className="react-bootstrap-typeahead tag-outer "
                            options={allRollCallMonth}
                            value={allRollCallMonth.filter(function (option) {
                                return option.value === rollCallMonth;
                            })}
                            onChange={(newValue) => handleChange(newValue, "rollcall_month")}
                        />
                    </div>

                    <div className="col-lg-4 fv-row" >
                        <label className="fw-bold mb-2">Select Week</label>
                        <Select
                            styles={customStyles}
                            className="react-bootstrap-typeahead tag-outer "
                            options={allRollcallWeek}
                            value={allRollcallWeek.filter(function (option) {
                                return option.value === rollCallWeek;
                            })}
                            onChange={(newValue) => handleChange(newValue, "rollcall_week")}
                        />
                    </div>

                    <div className="p-5">
                        <label className="fw-bold mb-2">Select Manager</label>
                        <Select
                            styles={customStyles}
                            className="react-bootstrap-typeahead tag-outer "
                            options={allManagers}
                            value={allManagers.filter(function (option) {
                                return option.value === user_id;
                            })}
                            onChange={(newValue) => handleChange(newValue, "select_manager")}
                        />
                    </div>

                    <div className="col-lg-12">
                        <div className="d-flex align-items-center border border-dashed border-gray-300 rounded px-5 py-3 mt-6 font-medium">
                            Discarded Sessions 0{sessionData.length}
                        </div>
                    </div>
                </div>

                <div>
                    <DiscardedSessionItem
                        data={sessionData}
                    />
                </div>


            </div>
        </>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetRollCallDetails: userActions.GetRollCallDetails,
    onGetAllManagersListBySchoolId: userActions.GetAllManagersListBySchoolId,
    onGetAllDashboardDiscadedAttendance: userActions.GetAllDashboardDiscadedAttendance
};
export default connect(mapStateToProps, mapDispatchToProps)(DiscardedSession);
