import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import Validations from "../../../component/Utility/Validations";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    "&:hover": {
      backgroundColor: state.isFocused ? "#e7f5fe" : "",
    },
    backgroundColor: state.isSelected ? "#4a8fb8" : "",
  }),
};
const BorderLeaveRejectModal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [showBoarderLeaveRejectMessage, setShowBoarderLeaveRejectMessage] = useState("")
  const [error, setError] = useState(false)

  const closeModal = () => {
    props.hideRejectLeaveModal(false)
  }

  const SubmitHandler = async (event) => {
    if (showBoarderLeaveRejectMessage.trim() != "") {
      setError(false);
      props.leaveSubmit("rejected", showBoarderLeaveRejectMessage);
    } else {
      setError(true);
    }
  }

  useEffect(() => {
    setShowModal(props.showBoarderLeaveRejectModal)
  }, [props]);

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Leave Reject Reason</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form w-100" onSubmit={SubmitHandler}>
          <div className="row">
            <div className="col-lg-12 fv-row mb-4">
              <div className="row">
                <div className="col-sm-12">
                  <label className="fw-bold mb-2 me-4">Add Comment</label>
                  <textarea
                    type="text"
                    placeholder="Leave Rejected on the behalf of Host"
                    className="form-control h-100px"
                    onChange={(e) => setShowBoarderLeaveRejectMessage(e.target.value)}
                  />
                  {error ? <div className="error field-error">This field is required</div> : ""}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary btn-sm" onClick={closeModal}>Close</Button>
        <Button type="submit" className="btn btn-sm btn-primary px-10 btn-sm" onClick={() => SubmitHandler()}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
};


const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  onGetAllLeaveRejectReasons: userActions.GetAllLeaveRejectReasons,
};
export default connect(mapStateToProps, mapDispatchToProps)(BorderLeaveRejectModal);

