import React, { useState, useEffect, useCallback } from "react";
import CalendarCard from "./calendar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as userActions from "../../actions/index";
import GetBirthdayItem from "./GetBirthdayItem";
import moment from "moment";
import "moment-timezone";
import Rightsidebar from "./rightsidebar";


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;


function Calendar({ navigateToSnapshot }) {

    const [BirthdayList, setBirthdayList] = useState([]);
    const [BirthdayListcount, setBirthdayListcount] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const todate = new Date();
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school?.timezone;
    const date = moment(todate)?.tz(timezone)?.format('DD/MM/YYYY');
    const GetStudentsBirthdayDetails = useCallback(async () => {
        dispatch(userActions.GetStudentsBirthdayDetails()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data;
                // let MemberId_level = [];
                // const sumvalue = 1;
                // for (let key in arrayData) {
                //     const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                //     MemberId_level.push({

                //         student_name: arrayData[key].student_first_name + " " + arrayData[key].student_last_name,
                //         class_name: arrayData[key].class_name,

                //     });
                // }
                setBirthdayList(arrayData);
                setBirthdayListcount(response.data.count);
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate(`/${school_code}/dashboard`)
                }, 5000);
            }

            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetStudentsBirthdayDetails();
    }, [GetStudentsBirthdayDetails]);



    return (
        <>
            <div className="row ">
                <div className="col-md-12 col-lg-12 col-xl-4 mb-5">
                    <div className="card h-100">
                        <div className="card-header" >
                            <h2 className="card-title">Students With Birthday Today</h2>
                        </div>
                        <div className="card-body p-4">
                            <div className="text-center  birthday-block">
                                <GetBirthdayItem
                                    currentDate={date}
                                    BirthdayListcount={BirthdayListcount}
                                    data={BirthdayList}
                                />

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 col-lg-12 mb-5 col-xl-5">
                    <div className="card h-100">
                        <div className="card-header" >
                            <h2 className="card-title"> Calendar </h2>
                        </div>

                        <div className="card-body p-4">
                            <CalendarCard></CalendarCard>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-xl-3">
                    <Rightsidebar navigateToSnapshot={navigateToSnapshot}></Rightsidebar>
                </div>
            </div>
        </>
    );
}

export default Calendar;
