import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import Validations from "../../../component/Utility/Validations";

const BorderLeaveApproveModal = (props) => {
  const [showModal, setShowModal] = useState(false)
  const [showBoarderLeaveApprovedMessage, setShowBoarderLeaveApprovedMessage] = useState("")




  const SubmitHandler = async (event) => {
    props.leaveSubmit("approved", showBoarderLeaveApprovedMessage);
  }

  const closeModal = () => {
    props.hideApprovedLeaveModal(false)
  };

  useEffect(() => {
    setShowModal(props.showBoarderLeaveApprovedModal)
  }, [props]);


  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Any comments relative to this particular Leave?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="form w-100" onSubmit={SubmitHandler}>
          <div className="row">
            <div className="col-lg-12 fv-row mb-4">
              <div className="row">
                <div className="col-sm-12">
                  <label className="fw-bold mb-2 me-4">Add Comment</label>
                  <textarea
                    type="text"
                    placeholder="Leave Approved on the behalf of Host"
                    className="form-control h-100px"
                    onChange={(e) => setShowBoarderLeaveApprovedMessage(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn btn-secondary btn-sm" onClick={closeModal}>Close</Button>
        <Button type="button" className="btn btn-sm btn-primary px-10 btn-sm" onClick={() => SubmitHandler()}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BorderLeaveApproveModal;
