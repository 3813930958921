import React, { useState, useEffect, useCallback } from "react";
import Calendar from "./calendarmain";
import { connect, useDispatch } from "react-redux";
import * as userActions from "../../actions/index";
import { toast } from "react-toastify";
import AddOperationalComment from "./Modal/AddOperationalComment";
import AddPastrolComment from "./Modal/AddPastrolComment";
import AddMedicalComment from "./Modal/AddMedicalComment";
import DiaryComment from "./Modal/GetDiaryComment";
import { useNavigate } from "react-router-dom";




const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info.school_code;

const Rightsidebar = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filterpage, setfilterpage] = useState(1);
    const [confirm_modal, setconfirm_modal] = useState(false);
    const [add_operational_comment_modal, setadd_operational_comment_modal] = useState(false);
    const [add_pastrol_comment_modal, setadd_pastrol_comment_modal] = useState(false);
    const [add_medical_comment_modal, setadd_medical_comment_modal] = useState(false);
    const [get_diary_comment_modal, setget_diary_comment_modal] = useState(false);
    const AddOperationalpoup = async (event) => {
        setadd_operational_comment_modal(true)
    }
    const CreateOperationalComment = async (form_data) => {
        props.onCreateOperationalComment(form_data).then((response) => {
            if (response.success === true) {

                setadd_operational_comment_modal(false)
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    const showAddModalHandleOperationalComment = async (event) => {
        setadd_operational_comment_modal(false)
    }
    const AddPastrolpoup = async (event) => {
        setadd_pastrol_comment_modal(true)
    }
    const CreateDiaryPastoralComments = async (form_data) => {
        props.onCreateDiaryPastoralComments(form_data).then((response) => {
            if (response.success === true) {

                setadd_pastrol_comment_modal(false)
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    }

    const showAddModalHandlePastrolComment = async (event) => {
        setadd_pastrol_comment_modal(false)
    }

    const AddMedicalpoup = async (event) => {
        setadd_medical_comment_modal(true)
    }

    const CreateDiaryMedicalComment = async (form_data) => {
        props.onCreateDiaryMedicalComment(form_data).then((response) => {
            if (response.success === true) {
                props.navigateToSnapshot();
                setadd_medical_comment_modal(false);
                setfilterpage(1);
                setTimeout(() => {
                    navigate(`/${school_code}/dashboard`, { replace: true });
                    toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }, 50);

            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
    }

    const showAddModalHandleMedicalComment = async (event) => {
        setadd_medical_comment_modal(false)
    }

    const GetDiaryCommentpoup = async (event) => {
        console.log("click for read diary comment");
        setget_diary_comment_modal(true)
    }
    // const CreateDiaryMedicalComments = async (form_data) => {
    //     props.onCreateDiaryMedicalComments(form_data).then((response) => {
    //         if (response.success === true) {

    //             setadd_medical_comment_modal(false)
    //             setfilterpage(1);
    //             toast.success(response.message, {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         } else {
    //             toast.error(response.message, {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         }

    //     });
    // }

    const showModalHandleGetDiaryComment = async (event) => {
        setget_diary_comment_modal(false)
    }

    const GenerateAllergicReportForDashboard = () => {
        navigate(`/${school_code}/student/allergic-student`);
        // props.onGenerateAllergicReportForDashboard().then((response) => {
        //     if (response.success === true) {
        //         const csv_url = response.file;
        //         DownloadPdf(csv_url);
        //         // GetAllCourses();
        //         toast.success(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        //     else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        // });
    }
    const DownloadPdf = (csv_url) => {
        const link = document.createElement("a");
        link.href = csv_url;
        link.target = "_blank"
        // link.setAttribute("download");
        document.body.appendChild(link);
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    return (
        <>

            {add_operational_comment_modal &&
                <AddOperationalComment
                    add_operational_comment_modal={add_operational_comment_modal}
                    CreateOperationalComment={(event) => CreateOperationalComment(event)}
                    add_operational_comment_modal_action={(modal_action) => showAddModalHandleOperationalComment(modal_action)}


                />
            }

            {add_pastrol_comment_modal &&
                <AddPastrolComment
                    add_pastrol_comment_modal={add_pastrol_comment_modal}
                    CreateDiaryPastoralComments={(event) => CreateDiaryPastoralComments(event)}
                    add_pastrol_comment_modal_action={(modal_action) => showAddModalHandlePastrolComment(modal_action)}


                />
            }

            {add_medical_comment_modal &&
                <AddMedicalComment
                    add_medical_comment_modal={add_medical_comment_modal}
                    CreateDiaryMedicalComment={(event) => CreateDiaryMedicalComment(event)}
                    add_medical_comment_modal_action={(modal_action) => showAddModalHandleMedicalComment(modal_action)}

                />
            }
            {get_diary_comment_modal &&
                <DiaryComment
                    navigateToSnapshot={() => props.navigateToSnapshot()}
                    get_diary_comment_modal={get_diary_comment_modal}
                    // CreateDiaryMedicalComments={(event) => CreateDiaryMedicalComments(event)}
                    get_diary_comment_modal_action={(modal_action) => showModalHandleGetDiaryComment(modal_action)}
                />
            }
            <div className="row ">
                <div className="col-md-12 mb-3">
                    <div className="card h-100 ">

                        <div className="card-header" >
                            <h2 className="card-title">Diary Comments</h2>
                        </div>
                        <div className="card-body px-4 py-2">
                            <div className="d-flex flex-column gap-3">
                                <button type="button" className="btn btn-gray btn-sm text-start" onClick={(event) => {
                                    AddOperationalpoup(event);
                                }}>
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>  Add Operational Comments
                                </button>

                                <button type="button" className="btn btn-green-success btn-sm text-start" onClick={(event) => {
                                    AddPastrolpoup(event);
                                }} >
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Student Pastoral Comments
                                </button>

                                <button type="button" className="btn btn-primary btn-sm text-start" onClick={(event) => {
                                    AddMedicalpoup(event);
                                }} >
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Add Student Medical Comments
                                </button>

                                <button type="button" className="btn btn-info btn-sm text-start fs-5"
                                    onClick={(event) => { GetDiaryCommentpoup(event); }}
                                >
                                    <i className="fa fa-book" aria-hidden="true"></i> Read Diary Comments
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card h-100 ">
                        <div className="card-header" >
                            <h2 className="card-title">Reports</h2>
                        </div>
                        <div className="card-body px-4 py-2">
                            <div className="d-flex flex-column gap-3">
                                <button type="button" onClick={() => navigate(`/${school_code}/leave/reports/adhoc-report`)} className="btn btn-success-yellow btn-sm text-start">
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Adhoc leaves Report
                                </button>

                                <button onClick={() => GenerateAllergicReportForDashboard()} type="button" className="btn btn-primary-light   btn-sm text-start">
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i> Allergies Report
                                </button>

                                <button type="button" onClick={() => navigate(`/${school_code}/leave/reports/catering-report`)} className="btn btn-success btn-sm text-start">
                                    <i class="fa fa-plus-circle" aria-hidden="true"></i>  Catering Report
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {

    onExportManagersCsv: userActions.ExportManagersCsv,
    onExportManagersPdf: userActions.ExportManagersPdf,
    onArchiveOrUnarchiveManager: userActions.ArchiveOrUnarchiveManager,
    onCreateOperationalComment: userActions.CreateOperationalComment,
    onCreateDiaryPastoralComments: userActions.CreateDiaryPastoralComments,
    onCreateDiaryMedicalComment: userActions.CreateDiaryMedicalComment,
    onGenerateAllergicReportForDashboard: userActions.GenerateAllergicReportForDashboard,

};
export default connect(mapStateToProps, mapDispatchToProps)(Rightsidebar);