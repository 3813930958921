import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");




/*** GetAllStudentsforConductRollCall Action ***/

export const GetAllStudentsforConductRollCallStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllStudentsforConductRollCall_START,
    };
};
export const GetAllStudentsforConductRollCallSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllStudentsforConductRollCall_SUCCESS,
    };
};
export const GetAllStudentsforConductRollCallFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllStudentsforConductRollCall_FAIL,
    };
};
export const GetAllStudentsforConductRollCall = (sort, order , dormitory_ids , class_ids , gender ) => {
    // console.log("Action Console dormitory_id ", dormitory_id)
    // console.log("Action Console class_id ", class_id)
    // console.log("Action Console gender ", gender)
    // console.log("Action Console order ", order)
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllStudentsforConductRollCallStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllStudentsforConductRollCall`,
            params: {
                sort: sort,
                order: order,
                dormitory_ids:dormitory_ids,
                class_ids:class_ids,
                gender:gender,

            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetAllStudentsforConductRollCallSuccess(response.data));
                return response.data;
                
            })
            .catch(function (error) {
                dispatch(GetAllStudentsforConductRollCallFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** CreateStudentsConductRollCall Action ***/
export const CreateStudentsConductRollCallStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateStudentsConductRollCall_START,
    };
};
export const CreateStudentsConductRollCallSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateStudentsConductRollCall_SUCCESS,
    };
};
export const CreateStudentsConductRollCallFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateStudentsConductRollCall_FAIL,
    };
};
export const CreateStudentsConductRollCall = (form_data) => {

    // console.log("form_data in create rollcall API actions", form_data)
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateStudentsConductRollCallStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateStudentsConductRollCall`,
        })
            .then(function (response) {
                dispatch(CreateStudentsConductRollCallSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateStudentsConductRollCallFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** CreateStudentsConductRollCall Action ***/
export const CreateRollCallSessionDiscardStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.CreateRollCallSessionDiscard_START,
    };
};
export const CreateRollCallSessionDiscardSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.CreateRollCallSessionDiscard_SUCCESS,
    };
};
export const CreateRollCallSessionDiscardFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.CreateRollCallSessionDiscard_FAIL,
    };
};
export const CreateRollCallSessionDiscard = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(CreateRollCallSessionDiscardStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/CreateRollCallSessionDiscard`,
        })
            .then(function (response) {
                dispatch(CreateRollCallSessionDiscardSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(CreateRollCallSessionDiscardFail("Something went wrong, Please try again."));
                return error;
            });
    };
};







/*** GetStudentsRollCallReport Action ***/

export const GetStudentsRollCallReportStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetStudentsRollCallReport_START,
    };
};
export const GetStudentsRollCallReportSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetStudentsRollCallReport_SUCCESS,
    };
};
export const GetStudentsRollCallReportFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetStudentsRollCallReport_FAIL,
    };
};
export const GetStudentsRollCallReport = ( class_ids , start_date , end_date ,dormitory_ids , user_id , gender ) => {
    // console.log("Manager id check", user_id)
    // console.log("gender  check", gender)
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetStudentsRollCallReportStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetStudentsRollCallReport`,
            params: {

                class_ids:class_ids,
                start_date:start_date,
                end_date:end_date,
                dormitory_ids:dormitory_ids,
                user_id:user_id,
                gender:gender,

            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetStudentsRollCallReportSuccess(response.data));
                // console.log("API RESPONSE action", response)
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetStudentsRollCallReportFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** ExportStudentsAttendancePdf Action ***/
export const ExportStudentsAttendancePdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportStudentsAttendancePdf_START,
    };
};


export const ExportStudentsAttendancePdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportStudentsAttendancePdf_SUCCESS,
    };
};


export const ExportStudentsAttendancePdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportStudentsAttendancePdf_FAIL,
    };
};

export const ExportStudentsAttendancePdf = (class_id,start_date,end_date, dormitory_id, user_id, gender) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const current = new Date();
    const date = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}  ${current.getHours()}:${current.getMinutes()}`;
    return (dispatch) => {
        dispatch(ExportStudentsAttendancePdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },

            url: `${actionType.environment_url}/api/bss-admin/ExportStudentsAttendancePdf`,
            params: {
                class_id: class_id,
                start_date:start_date,
                end_date:end_date,
                dormitory_id: dormitory_id,
                user_id: user_id,
                gender: gender,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportStudentsAttendancePdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportStudentsAttendancePdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** GetAllSnapshotStudents Action ***/

export const GetAllSnapshotStudentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetAllSnapshotStudents_START,
    };
};
export const GetAllSnapshotStudentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetAllSnapshotStudents_SUCCESS,
    };
};
export const GetAllSnapshotStudentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetAllSnapshotStudents_FAIL,
    };
};
export const GetAllSnapshotStudents = (sort, order , page, limit, search, dormitory_ids , class_ids , gender, location_status, oncampus_ids, reason_id, rollcall_compare_status, is_compare_rollcall_required  ) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetAllSnapshotStudentsStart());

        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetAllSnapshotStudents`,
            params: {
                // page: page,
                // limit: limit,
                sort: sort,
                order: order,
                search: search,
                gender:gender,
                dormitory_ids:dormitory_ids,
                class_ids:class_ids,
                // is_student_activate: is_student_activate,
                location_status: location_status,
                oncampus_ids: oncampus_ids,
                reason_id: reason_id,
                rollcall_compare_status,
                is_compare_rollcall_required,
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
            })
            .then(function (response) {
                // console.log("@@@@@@-----response", response)
                dispatch(GetAllSnapshotStudentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetAllSnapshotStudentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};




/*** ExportStudentLocationHistoryPdf Action ***/
export const ExportStudentLocationHistoryPdfStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.ExportStudentLocationHistoryPdf_START,
    };
};


export const ExportStudentLocationHistoryPdfSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.ExportStudentLocationHistoryPdf_SUCCESS,
    };
};


export const ExportStudentLocationHistoryPdfFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.ExportStudentLocationHistoryPdf_FAIL,
    };
};

export const ExportStudentLocationHistoryPdf = (student_uuid, dormitory_name, search_by_days) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(ExportStudentLocationHistoryPdfStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
                school_detail_id: user_info.school_detail_id,
            },
          

            url: `${actionType.environment_url}/api/bss-admin/ExportStudentLocationHistoryPdf`,
            params: {
                student_uuid: student_uuid,
                dormitory_name: dormitory_name,
                search_by_days: search_by_days,
             
         
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(ExportStudentLocationHistoryPdfSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(ExportStudentLocationHistoryPdfFail("Something went wrong, Please try again."));
                return error;
            });
    };
};


/*** SignInStudentReasonOrOncampus Action ***/
export const SignInStudentReasonOrOncampusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.SignInStudentReasonOrOncampus_START,
    };
};
export const SignInStudentReasonOrOncampusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.SignInStudentReasonOrOncampus_SUCCESS,
    };
};
export const SignInStudentReasonOrOncampusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.SignInStudentReasonOrOncampus_FAIL,
    };
};
export const SignInStudentReasonOrOncampus = (form_data) => {
    
    // console.log("form_data in sign in reason API actions", form_data)
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(SignInStudentReasonOrOncampusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        // console.log("FORM DATA", form_data)
        return axios({
            method: "post",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/SignInStudentReasonOrOncampus`,
        })
            .then(function (response) {
                dispatch(SignInStudentReasonOrOncampusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SignInStudentReasonOrOncampusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** SignOutStudentReasonOrOncampus Action ***/
export const UpdateSnapshotRollCallCompareStatusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.UpdateSnapshotRollCallCompareStatus_START,
    };
};
export const UpdateSnapshotRollCallCompareStatusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.UpdateSnapshotRollCallCompareStatus_SUCCESS,
    };
};
export const UpdateSnapshotRollCallCompareStatusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.UpdateSnapshotRollCallCompareStatus_FAIL,
    };
};
export const UpdateSnapshotRollCallCompareStatus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(UpdateSnapshotRollCallCompareStatusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/UpdateSnapshotRollCallCompareStatus`,
        })
            .then(function (response) {
                dispatch(UpdateSnapshotRollCallCompareStatusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(UpdateSnapshotRollCallCompareStatusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

/*** SignOutStudentReasonOrOncampus Action ***/
export const SignOutStudentReasonOrOncampusStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.SignOutStudentReasonOrOncampus_START,
    };
};
export const SignOutStudentReasonOrOncampusSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.SignOutStudentReasonOrOncampus_SUCCESS,
    };
};
export const SignOutStudentReasonOrOncampusFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.SignOutStudentReasonOrOncampus_FAIL,
    };
};
export const SignOutStudentReasonOrOncampus = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(SignOutStudentReasonOrOncampusStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }

        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/SignOutStudentReasonOrOncampus`,
        })
            .then(function (response) {
                dispatch(SignOutStudentReasonOrOncampusSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SignOutStudentReasonOrOncampusFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** SnapshotSignInOrSignOutStudents Action ***/
export const SnapshotSignInOrSignOutStudentsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.SnapshotSignInOrSignOutStudents_START,
    };
};
export const SnapshotSignInOrSignOutStudentsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.SnapshotSignInOrSignOutStudents_SUCCESS,
    };
};
export const SnapshotSignInOrSignOutStudentsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.SnapshotSignInOrSignOutStudents_FAIL,
    };
};
export const SnapshotSignInOrSignOutStudents = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(SnapshotSignInOrSignOutStudentsStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/SnapshotSignInOrSignOutStudents`,
        })
            .then(function (response) {
                dispatch(SnapshotSignInOrSignOutStudentsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SnapshotSignInOrSignOutStudentsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};



/*** SnapshotReturnStuLeave ***/
export const SnapshotReturnStuLeaveStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.SnapshotReturnStuLeave_START,
    };
};
export const SnapshotReturnStuLeaveSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.SnapshotReturnStuLeave_SUCCESS,
    };
};
export const SnapshotReturnStuLeaveFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.SnapshotReturnStuLeave_FAIL,
    };
};
export const SnapshotReturnStuLeave = (form_data) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    
    return (dispatch) => {
        dispatch(SnapshotReturnStuLeaveStart());

        let formdata = new FormData();
        for (let key in form_data) {
            formdata.append(key, form_data[key]);
        }
        return axios({
            method: "put",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info.country_host ,
                school_code: user_info.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/SnapshotReturnStuLeave`,
        })
            .then(function (response) {
                dispatch(SnapshotReturnStuLeaveSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(SnapshotReturnStuLeaveFail("Something went wrong, Please try again."));
                return error;
            });
    };
};
