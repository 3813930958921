import axios from "axios";
import * as actionType from "../constants/actionTypes";
let qs = require("qs");



/*** GetSystemLogs ***/

export const GetSystemLogsStart = () => {
    return {
        error: null,
        loader: true,
        isAuthenticated: true,
        type: actionType.GetSystemLogs_START,
    };
};
export const GetSystemLogsSuccess = (response) => {
    return {
        error: false,
        loader: false,
        isAuthenticated: response.status === 401 ? false : true,
        type: actionType.GetSystemLogs_SUCCESS,
    };
};
export const GetSystemLogsFail = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: actionType.GetSystemLogs_FAIL,
    };
};




export const GetSystemLogs = ( page,limit,sort,order,search,id ) => {
    console.log("Ewrwetretwtrt",limit);
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    return (dispatch) => {
        dispatch(GetSystemLogsStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("bss_authtoken")}`,
                country_host: user_info?.country_host ,
                school_code: user_info?.school_code,
            },
            url: `${actionType.environment_url}/api/bss-admin/GetSystemLogs`,
            params: {
               page:page,
               limit:limit,
               sort:sort,
               order:order,
               search:search,
               student_id:id
            },
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                dispatch(GetSystemLogsSuccess(response.data));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetSystemLogsFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

