import React, { useState, useEffect, useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import moment from "moment";
import "moment-timezone";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import * as userActions from "../../../actions/index";
import ReturnScheduleChangeModal from '../LeaveModals/ReturnScheduleChangeModal';
import ReturnApprovalModal from '../LeaveModals/ReturnApprovalModal';
import { updateBooleanValue } from '../../../reducers/Snapshot';
import * as actionType from "../../../constants/actionTypes";
import IconButton from '@mui/material/IconButton';
import CapitalFirstLetter from '../../Utility/CapitalFirstLetter';



const ManageStudentReturn = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const school_code = user_info?.school_code ?? null;
    const timezone = user_info?.user_school?.timezone;
    let current_date = moment().tz(timezone)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rowVisibility, setRowVisibility] = useState({}); // State for row visibility
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState();
    const [sort, setSort] = useState("border_leave_id");
    const [order, setOrder] = useState("desc");
    const [search, setSearch] = useState("");
    const [from_date, setFromDate] = useState("");
    const [to_date, setToDate] = useState("");
    const [BorderLeaveList, setBorderLeaveList] = useState([]);
    const [leaveData, setLeaveData] = useState({})
    const [showChangeShedule, setShowChangeShedule] = useState(false)
    const [showReturnStudent, setShowReturnStudent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 }
    ]


    // search 
    const handleSearchChange = (e, value) => {
        GetReturnStudentDetails(from_date, to_date)
    }

    const handleToggleRowVisibility = (index) => {
        setRowVisibility((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle visibility for the specified index
        }));
    };

    useEffect(() => {
        GetReturnStudentDetails();
    }, [page, limit, sort, order, search, from_date, to_date]);

    const GetReturnStudentDetails = async () => {
        props.onGetReturnStudentDetails(page, limit, sort, order, search, from_date, to_date).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                setBorderLeaveList(arrayData);
                setCount(response.data.count)
            }
            else {

            }
        });
        // eslint-disable-next-line
    };

    const ReturnStuLeaveChange = async (form_data) => {
        setIsLoading(true);
        props.onReturnStuLeaveChange(form_data).then((response) => {
            if (response.success === true) {
                setIsLoading(false);
                setShowChangeShedule(false)
                setShowReturnStudent(false)
                GetReturnStudentDetails();
                dispatch(updateBooleanValue(true))
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                setIsLoading(false);
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
        // eslint-disable-next-line
    };


    const hideModal = (value) => {
        setShowChangeShedule(value)
        setShowReturnStudent(value)
    }



    return (
        <>
            {showChangeShedule &&
                <ReturnScheduleChangeModal
                    showChangeShedule={showChangeShedule}
                    returnLeaveData={leaveData}
                    ReturnStuLeaveChange={(form_data) => ReturnStuLeaveChange(form_data)}
                    hideReturnStuLeaveChangeModal={(value) => hideModal(value)}
                    isLoading={isLoading}
                />
            }
            {showReturnStudent &&
                <ReturnApprovalModal
                    showReturnStudent={showReturnStudent}
                    returnLeaveData={leaveData}
                    ReturnStuLeaveChange={(form_data) => ReturnStuLeaveChange(form_data)}
                    hideReturnStudentModal={(value) => hideModal(value)}
                    isLoading={isLoading}
                />
            }
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className='card'>
                                            <div className='card-body p-6'>
                                                <div className='row'>
                                                    <div className='col-md-3 my-auto'>
                                                        <div className="fs-6 text-gray-800 fw-bolder">
                                                            <label className='d-block'>Today</label><span className='text-muted'>{moment().tz(timezone)?.format('MMMM Do YYYY, h:mm a')}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-9'>
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <label className="form-label">From Date</label>
                                                                <div className='position-relative'>
                                                                    <span className='position-absolute apply_adhoc_leave_date_and_time'>

                                                                    </span>
                                                                    <input
                                                                        id="session-date"
                                                                        type="date"
                                                                        className="form-control bg-transparent"
                                                                        placeholder='From Date'
                                                                        value={from_date}
                                                                        onChange={(e) => setFromDate(e.target.value)}
                                                                        required
                                                                    />
                                                                </div>                                                        </div>
                                                            <div className='col'>
                                                                <label className="form-label">To Date</label>
                                                                <div className='position-relative'>
                                                                    <span className='position-absolute apply_adhoc_leave_date_and_time'>

                                                                    </span>
                                                                    <input
                                                                        id="session-date"
                                                                        type="date"
                                                                        className="form-control bg-transparent"
                                                                        placeholder='To Date'
                                                                        value={to_date}
                                                                        onChange={(e) => setToDate(e.target.value)}
                                                                        min={from_date}
                                                                        required
                                                                    />
                                                                </div>                                                        </div>
                                                            <div className='col mt-auto text-right'>
                                                                <div className='d-flex'>
                                                                    <button
                                                                        type="reset"
                                                                        className="ms-3  btn btn-secondary btn-sm btn-color-gray-700 btn-flex btn-active-primary  border-0 fw-bolder px-4 px-lg-6 me-2 me-lg-3 w-200 white-space"
                                                                        onClick={(event) => {
                                                                            setFromDate("");
                                                                            setToDate("");
                                                                        }}
                                                                    >
                                                                        Clear Search
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary btn-flex btn-sm  border-0 fw-bolder px-4 px-lg-6"
                                                                        onClick={handleSearchChange}
                                                                    >
                                                                        Search
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className='card mt-5'>
                                            <div className='card-header align-items-center'>
                                                <h3 className="card-title">Manage Student Return</h3>
                                            </div>
                                            <div className='card-body'>
                                                <div className='table-responsive'>
                                                    <MDBTable className='table-row-bordered'>
                                                        <MDBTableHead>
                                                            <tr>
                                                                <th></th>
                                                                {/* <th>Sr.No</th> */}
                                                                <th>Student Name</th>
                                                                <th>Student Host</th>
                                                                <th className='min-w-150px'>Planned Returned Date </th>
                                                                <th className='min-w-100px'>Planned Returned Time </th>
                                                                <th className='min-w-100px'>Mode of Travel (Return)</th>
                                                                <th>Return Status</th>
                                                                <th>Schedule Status</th>
                                                                <th className='min-w-150px'>Actual Returned Date </th>
                                                                <th className='min-w-100px'>Actual Returned Time </th>
                                                                <th className='text-center'>Actions</th>
                                                            </tr>
                                                        </MDBTableHead>
                                                        <MDBTableBody>
                                                            {BorderLeaveList.map((item, index) => {
                                                                console.log(item,item?.return_students_data?.last_returned_date,moment(item?.return_students_data?.last_returned_date).format("HH:mm"),moment("2025-01-06T17:12:02.004+11:00").format("HH:mm"),"::ghfgh")
                                                                return (
                                                                    <React.Fragment key={item.border_leave_id}>
                                                                        <tr key={item.border_leave_id}>
                                                                            <td>
                                                                                <button
                                                                                    className={`btn ${rowVisibility[index]
                                                                                        ? 'btn-light-secondary '
                                                                                        : 'btn-light-secondary '
                                                                                        } btn-sm w-32px`}
                                                                                    type="button"
                                                                                    onClick={() => handleToggleRowVisibility(index)}
                                                                                >
                                                                                    <i
                                                                                        className={`bi ${rowVisibility[index]
                                                                                            ? 'bi bi-chevron-down'
                                                                                            : 'bi bi-chevron-up'
                                                                                            } p-0`}
                                                                                    ></i>
                                                                                </button>
                                                                            </td>
                                                                            {/* <th scope='row'>{index + 1}</th> */}
                                                                            <td>
                                                                                <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                                                                    {item.leave_stu_data?.student_avatar == null || item.leave_stu_data?.student_avatar == "" ?
                                                                                        <span className='round_text'>{item.leave_stu_data?.student_name.split("")[0]}</span>
                                                                                        :
                                                                                        <img src={`${actionType.environment_url}/${item.leave_stu_data?.student_avatar}`} />
                                                                                    }
                                                                                </div>
                                                                                {CapitalFirstLetter(item.leave_stu_data?.student_name) || 'N/A'}
                                                                            </td>
                                                                            <td>{item.host_id ? CapitalFirstLetter(item?.stu_host_data?.host_name) : item.parent_id && item.parent_type === 'father' ? CapitalFirstLetter(item?.parent_data?.father_name) : CapitalFirstLetter(item?.parent_data?.mother_name) || 'N/A'}</td>
                                                                            <td>{moment(item.return_students_data?.planned_return_date).format('Do MMM. YYYY')}</td>
                                                                            <td>{item.return_students_data?.return_status === 'schedule_changed' ? item.return_students_data?.planned_return_time : item.return_time}</td>
                                                                            <td>{item.return_students_data?.return_status === 'schedule_changed' ? CapitalFirstLetter(item.return_students_data?.planned_return_mode?.travel_mode_name) : CapitalFirstLetter(item.return_mode_data?.travel_mode_name)}</td>
                                                                            <td>
                                                                                {item.return_students_data?.return_status === 'returned' ?
                                                                                    <img src="/assets/media/return_student.png" alt="" class="h-50px transformImg" title="Returned" /> :
                                                                                    <span className='fw-bold'>Not Returned</span>
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                {/* {item.return_students_data?.return_status === "returned" ? } */}
                                                                                {/* {console.log("dddddddddddddddd", current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "days"))} */}
                                                                                {console.log("item.return_students_data?.return_status", item.leave_stu_data?.student_name, item.return_students_data?.return_status, current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours"))}
                                                                                {console.log("item.return_students_data?.return_status=====================", item.leave_stu_data?.student_name, 48 > 44 > 36, current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours"), current_date < moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'))}

                                                                                {item.return_students_data?.return_status === 'pending' ?

                                                                                    (current_date < moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss') ? "On Schedule" :

                                                                                        current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") < 24 ? "On Schedule" :

                                                                                            36 > current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 24 ?
                                                                                                "24 hrs late for return"
                                                                                                :
                                                                                                48 > current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 36 ?
                                                                                                    "36 hrs late for return"
                                                                                                    :
                                                                                                    72 > current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 48 ?
                                                                                                        "48 hrs late for return"
                                                                                                        :
                                                                                                        `${current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "days")} days late for return`

                                                                                    )
                                                                                    :

                                                                                    (item.return_students_data?.return_status === 'returned' ?
                                                                                        (
                                                                                            36 > moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'hours') && moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'hours') > 24 ?
                                                                                                "24 hrs early"
                                                                                                :
                                                                                                48 > moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'hours') && moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'hours') > 36 ?
                                                                                                    "36 hrs early"
                                                                                                    :
                                                                                                    72 > moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'hours') && moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'hours') > 48 ?
                                                                                                        "48 hrs early"
                                                                                                        :
                                                                                                        moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'days') >= 3 ?
                                                                                                            `${moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'days')} days early`
                                                                                                            :
                                                                                                            (
                                                                                                                36 > current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 24 ?
                                                                                                                    "24 hrs late"
                                                                                                                    :
                                                                                                                    48 > current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 36 ?
                                                                                                                        "36 hrs late"
                                                                                                                        :
                                                                                                                        72 > current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 48 ?
                                                                                                                            "48 hrs late"
                                                                                                                            :
                                                                                                                            current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "days") >= 3 ?
                                                                                                                                `${current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "days")} days late`
                                                                                                                                :
                                                                                                                                "On Schedule"
                                                                                                            )
                                                                                        )
                                                                                        :
                                                                                        <img src="/assets/media/schedule_change.png" alt="" class="h-50px" />
                                                                                    )
                                                                                }



                                                                                {/* {item.return_students_data?.return_status === 'returned' ?
                                                                                    (
                                                                                        moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').diff(item.return_students_data?.last_returned_date, 'days') > 1 ?
                                                                                            "24 hrs early" : "on schdule"
                                                                                    )
                                                                                    :
                                                                                    (
                                                                                        current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") < 24 ?
                                                                                            <span>{current_date.diff(moment(`${item.return_students_data?.planned_return_date} ${item.return_students_data?.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours")}hrs late</span> : ""

                                                                                    )
                                                                                    // item.return_students_data?.return_status === 'schedule_changed' ? <img src="/assets/media/schedule_change.png" alt="" class="h-50px" /> : <span className='fw-bold'>On Schedule</span>
                                                                                    // :
                                                                                }
                                                                                {/* {
                                                                                    item.return_students_data?.return_status === 'schedule_changed' ? <img src="/assets/media/schedule_change.png" alt="" class="h-50px" /> : <span className='fw-bold'>On Schedule</span>
                                                                                } */}
                                                                            </td>
                                                                            <td>{item?.return_students_data?.last_returned_date ? moment.parseZone(item?.return_students_data?.last_returned_date).format("Do MMM. YYYY") : "-"}</td>
                                                                            <td>{item?.return_students_data?.last_returned_date ? moment.parseZone(item?.return_students_data?.last_returned_date).format("HH:mm") : "-"}</td>
                                                                            <td>
                                                                                {/* {item.return_students_data?.return_status !== 'returned' &&
                                                                                    <button
                                                                                        type="button"
                                                                                        class="btn btn-primary btn-sm" 
                                                                                        onClick={() => {
                                                                                            setShowChangeShedule(true);
                                                                                            setLeaveData(item);
                                                                                        }}
                                                                                    >
                                                                                        Schedule Change
                                                                                    </button>
                                                                                } */}
                                                                                {/* <button
                                                                                    type="button"
                                                                                    class="btn btn-primary btn-sm" 
                                                                                    onClick={() => {
                                                                                        setShowReturnStudent(true);
                                                                                        setLeaveData(item);
                                                                                    }}
                                                                                >
                                                                                    Return Approval
                                                                                </button> */}
                                                                                {item.return_students_data?.return_status !== 'returned' &&
                                                                                    <Tooltip title="Schedule Change" placement="top">
                                                                                        <IconButton
                                                                                            className='me-4'
                                                                                            onClick={() => {
                                                                                                setShowChangeShedule(true);
                                                                                                setLeaveData(item);
                                                                                            }}
                                                                                        >
                                                                                            <img src="/assets/media/change.png" style={{ filter: "invert(60%) sepia(7%) saturate(93%) hue-rotate(182deg) brightness(92%) contrast(85%)" }} />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                }
                                                                                {item.return_students_data?.return_status !== 'returned' ?
                                                                                    <Tooltip title="Sign Student Back In" placement="top">
                                                                                        <IconButton
                                                                                            onClick={() => {
                                                                                                setShowReturnStudent(true);
                                                                                                setLeaveData(item);
                                                                                            }}
                                                                                        >
                                                                                            <img src="/assets/media/airport.png" style={{ filter: "invert(88%) sepia(4%) saturate(34%) hue-rotate(314deg) brightness(79%) contrast(85%)", transform: "rotateY(180deg)" }} />
                                                                                            {/* <i class="fas fa-plane-departure fs-6"></i> */}
                                                                                        </IconButton>
                                                                                    </Tooltip> :
                                                                                    <span className="fw-bold badge me-2 badge-light-success">Returned Back</span>
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                        {rowVisibility[index] && (
                                                                            <>
                                                                                <tr className='text-bg-light'>
                                                                                    <th></th>
                                                                                    <th colSpan={2}>Actual return Date</th>
                                                                                    <th colSpan={2}>Actual return Time</th>
                                                                                    <th colSpan={2}>Actual Mode Of Travel return</th>
                                                                                    <th colSpan={2}>Submit Date</th>
                                                                                    <th colSpan={3}>Submit Time</th>
                                                                                </tr>
                                                                                <tr className='text-bg-light'>
                                                                                    <td></td>
                                                                                    <td colSpan={2}>{moment(item.return_date).format('Do MMM. YYYY')}</td>
                                                                                    <td colSpan={2}>{item.return_time}</td>
                                                                                    <td colSpan={2}>{item.return_mode_data?.travel_mode_name}</td>
                                                                                    <td colSpan={2}>{moment(item.created_date).format('Do MMM. YYYY')}</td>
                                                                                    <td colSpan={3}>{moment(item.created_date).format('hh:mm a')}</td>
                                                                                </tr>
                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={row_limit[1]}
                                                        name="color"
                                                        options={row_limit}
                                                        onChange={(e) => setLimit(e.value)}
                                                    />
                                                    <Stack>
                                                        <Pagination
                                                            count={Math.ceil(count / limit)}
                                                            page={page}
                                                            onChange={(e, value) => setPage(value)}
                                                            color="primary"
                                                        />
                                                    </Stack>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <Footer />

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}
// export default ManageStudentreturn;

function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = {

    onReturnStuLeaveChange: userActions.ReturnStuLeaveChange,
    onGetReturnStudentDetails: userActions.GetReturnStudentDetails

};
export default connect(mapStateToProps, mapDispatchToProps)(ManageStudentReturn);