import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "react-bootstrap";

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { Box, FormControl, FormHelperText, OutlinedInput } from "@mui/material";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import { display } from "@mui/system";
import moment from "moment";
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

const ImportCsvModal = ({ showModal, csv_student_details, setCsv_student_details, import_student_form, showModalHandler, getDatabyFirstModal, openSecondModal, onGetImportStudentDetails, onGetReviewAgainImportStudents }) => {
    const [csv_student_detail, setCsv_student_detail] = useState([])
    const [emailDomian, setEmailDomian] = useState("")
    const [dimensions, setDimensions] = useState({ width: 1200, height: 600 });
    const [position, setPosition] = useState({ top: 50, left: 50 });
    const [isFinalValid, setIsFinalValid] = useState(false)

    console.log(csv_student_detail, "::csv_student_detail")
    useEffect(() => {
        setCsv_student_detail(csv_student_details)
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const startPoint = user_info.email.lastIndexOf("@")
        const email_extansion = user_info.email.slice(startPoint, user_info.email.length);
        setEmailDomian(email_extansion)

    }, [csv_student_details])

    const changeVailid = (e, index) => {
        let setObj = csv_student_detail.map((item, ind) => {
            if (index === ind) {
                return ({ ...item, valid: e.target.checked })
            } else {
                return item
            }
        })
        setCsv_student_detail(setObj)
    }
    let headers1 = csv_student_detail[0] && Object.keys(csv_student_detail?.[0]);
    let headers = headers1 && headers1.filter(item => item !== "valid" && item !== "error");

    // Validation function for cells
    const validateCell = (header, value, row) => {
        // debugger
        const backendValidationEmail = row?.error !== undefined ? (row?.error[0]?.toString().includes("email") || row?.student_email === "" ? true : "") : ""
        // const backendValidationPrefferName = row?.error !== undefined ? (row?.error[0]?.toString().includes("preferred") || row?.preferred_name === "" ? true : "") : ""
        const backendValidationDateOdBirth = row?.error !== undefined ? (row?.error[0]?.toString().includes("age") || row["date_of_birth(DD-MM-YYYY)"] === "" ? true : "") : ""
        const backendValidationKiosk = row?.error !== undefined ? (row?.error[0]?.toString().includes("Kiosk") || row?.error[0]?.toString().includes("kiosk") || row?.kiosk_mode_pin === "" ? true : "") : ""
        const backendValidationUnique = row?.error !== undefined ? (row?.error[0]?.toString().includes("Unique") || row?.error[0]?.toString().includes("unique") || row?.unique_pin === "" ? true : "") : ""

        console.log("backendValidationKiosk:::",backendValidationKiosk,"k",backendValidationEmail);
        

        switch (header) {
            case "kiosk_mode_pin":
                // return value?.trim() !== "" && !backendValidationKiosk;
                // return value?.trim().length >= 4 && !backendValidationKiosk;
                return value?.trim().length === 4 && !backendValidationKiosk;

            case "preferred_name":
                // return /^[a-zA-Z0-9]+$/.test(value) && value?.trim() !== "" && !backendValidationPrefferName;
                return /^[a-zA-Z0-9]+$/.test(value) && value?.trim() !== "";

                case "student_first_name":
                    // No spaces allowed
                    return /^[a-zA-Z0-9]+$/.test(value) && value?.trim() !== "";
            
                case "father_name":
                case "mother_name":
                    // Spaces allowed
                    return /^[a-zA-Z0-9 ]+$/.test(value) && value?.trim() !== "";
            
            case "student_email":
                return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value) !== "" && !backendValidationEmail;
            case "gender":
                return value.toLowerCase() === "male" || value.toLowerCase() === "female";

            case "father_as_host":
            case "mother_as_host":
                return value.toLowerCase() === "yes" || value.toLowerCase() === "no";

            case "student_mobile_cell":
            case "father_mobile_cell (RemoveZero)":
            case "mother_mobile_cell (RemoveZero)":
            case "father_home_phone":
            case "father_work_phone":
            case "mother_home_phone":
            case "mother_work_phone":
                return value === "" || /^[0-9]{1,15}$/.test(value);

            case "father_email":
            case "mother_email":
            case "tutor_email":
                return value === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

            case "date_of_birth(DD-MM-YYYY)":
                return /^[0-9\-\/]+$/.test(value) && value?.trim() !== "" && !backendValidationDateOdBirth;

            case "unique_pin":
                return value === "" || value?.trim().length === 4 && !backendValidationUnique ;
                
            case "father_address_line1":
            case "father_address_line2":
            case "mother_address_line1":
            case "mother_address_line2":
            case "student_last_name":
            case "campus_name":
            case "sporting_house":
            case "laundry_number":
            case "tutor_caregroup_teachername":
            case "father_country":
            case "mother_country":
            case "father_postcode":
            case "mother_postcode":
                return true
            default:
                return value?.trim() !== "";
        }
    };
    const handleCellChange = (rowIndex, header, value, row) => {
        console.log(value, emailDomian, value + emailDomian, "::13456row")
        const updatedData = [...csv_student_detail];
        updatedData[rowIndex][header] = value;

        // Optional: Add `valid` property to track validation per row
        updatedData[rowIndex].valid = validateCell(header, value, row);

        setCsv_student_detail(updatedData);
    };


    const validateTableData = (data) => {
        for (let row of data) {
            for (let key in row) {
                const value = row[key];
                if (!validateCell(key, value, row)) {
                    return false; // Invalid cell data found
                }
            }
        }
        return true; // All data is valid
    };



    const handleUploadClick = () => {
        console.log(csv_student_details, "::54csv_student_details")
        const filteredCsvStudentDetails = csv_student_detail.map(student => {
            // Create a shallow copy of the student object excluding 'error' and 'valid'
            const { error, valid, ...filteredStudent } = student;
            return filteredStudent;
        });

        onGetReviewAgainImportStudents(JSON.stringify(filteredCsvStudentDetails))
            .then((response) => {
                // let arrayData = []
                if (response.success === true) {
                    const impStuData = response.data;
                    setCsv_student_detail(response.data)
                    const allFieldsValid = response?.data?.every(item => item.valid === true);
                    console.log(validateTableData(filteredCsvStudentDetails) && allFieldsValid, "::checklingthefunctionli")
                    if (validateTableData(filteredCsvStudentDetails) && allFieldsValid) {
                        // All fields are valid
                        console.log(csv_student_detail, "::csv_student_detailsomethingworng895")
                        // openSecondModal(response.data);
                        setIsFinalValid(true);
                    }
                    else {
                        toast.error("Please correct all errors or fill in all required fields before proceeding.", {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } else {
                    toast.error(response?.response?.data?.error || "Please correct all errors or fill in all required fields before proceeding.", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })
            .catch((error) => {
                console.error("Error during upload:", error);
                alert("An error occurred during upload.");
                // toast.error(error, {
                //     position: toast.POSITION.TOP_CENTER,
                // });
            });

    };


    const handleMouseDown = (e) => {
        const startWidth = dimensions.width;
        const startHeight = dimensions.height === "auto" ? 400 : dimensions.height; // Default to a value if "auto"
        const startX = e.clientX;
        const startY = e.clientY;

        const handleMouseMove = (e) => {
            const newWidth = startWidth + (e.clientX - startX);
            const newHeight = startHeight + (e.clientY - startY); // Proper vertical resize calculation
            setDimensions({
                width: Math.max(newWidth, 200), // Minimum width
                height: Math.max(newHeight, 200), // Minimum height
            });
        };

        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };
    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                showModalHandler(false);
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    return (
        <>

            <Modal
                backdrop="static"
                // keyboard={false}
                className="confirm_delete_modal confirm_modal"
                show={showModal}
                aria-labelledby="lg-modal"
                size="xl"
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: `${position.top}%`,
                        left: `${position.left}%`,
                        transform: "translate(-50%, 0%)",
                        width: `${dimensions.width}px`,
                        height: `${dimensions.height}px`,
                        bgcolor: "background.paper",
                        borderRadius: "8px",
                        boxShadow: 24,
                        p: 4,
                        paddingBottom: "0px",
                        overflow: "auto",
                    }}
                >
                    <Modal.Header>
                        <Modal.Title>Import Students</Modal.Title>
                        <button type="reset" onClick={() => showModalHandler(false, csv_student_detail)} >
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </button>
                    </Modal.Header>
                    <Modal.Body style={{ paddingBottom: "0px" }}>
                        <TableContainer style={{ minHeight: "400px" }}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {headers?.map((header, index) => {
                                            console.log(header, "::header232")
                                            return <TableCell key={index}>
                                                {header === "date_of_birth(DD-MM-YYYY)" ? "Date Of Birth" : header
                                                    .replace(/_/g, ' ')
                                                    .replace(/\b\w/g, (char) => char.toUpperCase())
                                                }
                                            </TableCell>
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {csv_student_detail?.map((row, rowIndex) => (
                                        <TableRow hover key={rowIndex}>
                                            {headers?.map((header, colIndex) => {
                                                const value = row[header] || "";
                                                const isValid = validateCell(header, value, row);
                                                const backendValidationEmail = row?.error !== undefined ? (row?.error[0]?.toString().includes("email") || row?.student_email === "" ? true : "") : ""
                                                // const backendValidationPrefferName = row?.error !== undefined ? (row?.error[0]?.toString().includes("preferred") || row?.preferred_name === "" ? true : "") : ""
                                                const backendValidationDateOdBirth = row?.error !== undefined ? (row?.error[0]?.toString().includes("age") || row["date_of_birth(DD-MM-YYYY)"] === "" ? true : "") : ""
                                                const backendValidationKiosk = row?.error !== undefined ? (row?.error[0]?.toString().includes("Kiosk") || row?.error[0]?.toString().includes("kiosk") || row?.kiosk_mode_pin === "" ? true : "") : ""
                                                const backendValidationUnique = row?.error !== undefined ? (row?.error[0]?.toString().includes("Unique") || row?.error[0]?.toString().includes("unique") || row?.unique_pin === "" ? true : "") : ""


                                                return (
                                                    <TableCell key={colIndex}>
                                                        <FormControl
                                                            sx={{ width: header === "student_email" ? "40ch" : "25ch" }}
                                                            error={!isValid}
                                                        >
                                                            <OutlinedInput
                                                                type="text"
                                                                value={value}
                                                                onChange={(e) =>
                                                                    handleCellChange(rowIndex, header, e.target.value, row)
                                                                }
                                                                readOnly={isFinalValid}
                                                            />
                                                            {!isValid && (
                                                                <FormHelperText sx={{ marginLeft: "0px" }}>
                                                                    {/* {header === "student_email"
                                                                        ? (backendValidationEmail ? "Email already exists or invalid domain." : "Invalid email address")
                                                                        : header === "student_mobile_cell"
                                                                            ? "Invalid phone number"
                                                                            : header === "date_of_birth(DD-MM-YYYY)"
                                                                                ? (backendValidationDateOdBirth ? "Age must be above 4 years" : "Invalid date of birth")
                                                                                : header === "preferred_name"
                                                                                    ? (backendValidationPrefferName ? "Preferred name already exists" : "Invalid Preferred Name") :
                                                                                    header === "kiosk_mode_pin" ? (backendValidationKiosk ? "Kiosk mode pin already exists or must be unique." : "This field is required")
                                                                                        : "This field is required"
                                                                    } */}
                                                                    {header === "student_email"
                                                                        ? (backendValidationEmail ? "Email already exists or invalid domain." : "Invalid email address")
                                                                        : header === "student_mobile_cell"
                                                                            ? "Invalid phone number"
                                                                            : header === "date_of_birth(DD-MM-YYYY)"
                                                                                ? (backendValidationDateOdBirth ? "Age must be above 4 years" : "Invalid date of birth")
                                                                                :
                                                                                header === "kiosk_mode_pin" ? (backendValidationKiosk ? "Kiosk mode pin already exists or must be unique or must be 4 digit." : "This field is required")
                                                                                :
                                                                                header === "unique_pin" ? (backendValidationUnique ? "Unique Student pin already exists or must be unique or must be 4 digit." : "This field is required")
                                                                                : "This field is required"
                                                                    }
                                                                </FormHelperText>
                                                            )}
                                                        </FormControl>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Modal.Footer style={{ backgroundColor: "white", position: "sticky", bottom: "0", right: "0", padding: "10px" }}>
                            <div className="d-flex align-items-end">
                                <button type="reset" className="btn btn-sm btn-light me-2 min-150px ms-auto" onClick={() => showModalHandler(false, csv_student_detail)} >
                                    Cancel
                                </button>
                                {
                                    isFinalValid ?
                                        <button
                                            type="button"
                                            onClick={() => openSecondModal(csv_student_detail)}
                                            className="btn btn-sm btn-primary apply-btn min-150px ms-auto">
                                            Upload
                                        </button>
                                        :
                                        < button
                                            type="button"
                                            onClick={handleUploadClick}
                                            className="btn btn-sm btn-primary apply-btn min-150px ms-auto">
                                            Validate
                                        </button>
                                }
                            </div>
                            <div
                                style={{
                                    position: "relative",
                                    bottom: "-12px",
                                    right: "-68px",
                                    width: "20px",
                                    height: "20px",
                                    backgroundColor: "transparent",
                                    cursor: "nwse-resize",
                                }}
                                onMouseDown={handleMouseDown}
                            ><AspectRatioIcon /></div>
                        </Modal.Footer>
                    </Modal.Body>
                </Box>
            </Modal >
        </>
    )
};


export default ImportCsvModal
