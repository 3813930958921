import React from "react";
import { useLocation,useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DormitoryAdd from "../component/Dormitories/DormitoryAdd";
import DormitoriesEdit from "../component/Dormitories/DormitoryEdit";
import DormitoryList from "../component/Dormitories/DormitoryList";
import ChangeDormitory from "../component/Student/ChangeDormitory/ChangeDormitory";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const Dormitory = (props) => {
    const Renderview = () => {
        let location = useLocation();
        let userId = useParams();

        if (location.pathname === `/${school_code}/dormitory-list` || location.pathname === `/${school_code}/dormitory-list/`) {
            return <DormitoryList />;
        }
        if (location.pathname === `/${school_code}/dormitory-list/add-dormitory` || location.pathname === `/${school_code}/dormitory-list/add-dormitory/`) {
            return <DormitoryAdd />;
        }
        if (location.pathname === `/${school_code}/dormitory-list/edit-dormitory/${userId.id}` || location.pathname === `/${school_code}/dormitory-list/edit-dormitory/${userId.id}/`) {
            return <DormitoriesEdit dormitory_uuid={userId.id}  /> ;
        }
        if (location.pathname === `/${school_code}/settings/change-dormitory` || location.pathname === `/${school_code}/settings/change-dormitory/`) {
            return <ChangeDormitory /> ;
        }
        


        

    };

    return (
        <React.Fragment>
            <ToastContainer />
            {Renderview()}
        </React.Fragment>
    );
};
export default Dormitory;