import React from "react";
import Validations from "../Utility/Validations";
import Select from "react-select";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../actions/index";
import { Chart } from "react-google-charts";
import { parse } from "date-fns";
import moment from "moment";

//API to get data GetUserAttendanceCountForChart

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const options = {
    vAxis: {
        format: "", // Ensures no decimals on y-axis
        minValue: 0,       // Start y-axis at 0
    },

};
class Rollcall extends React.Component {
    constructor(props) {
        super(props);
        console.log("props_data", props);
        this.state = {
            manager_name: "",
            manager_name_admin: "",
            // graph_data: [
            //     ["Element", "Total Attendence", { role: "style" },],
            //     ["Sun",5, "#009ef7"],
            //     ["Mon", , "#009ef7"],
            //     ["Tue", 1, "#009ef7"],
            //     ["Wed", , "#009ef7"],
            //     ["Thu", , "#009ef7"],
            //     ["Fri", , "#009ef7"],
            //     ["Sat", , "#009ef7"],
            // ],
            timeline_label: [
                [
                    { type: "string", id: "day" },
                    { type: "date", id: "Start" },
                    { type: "date", id: "End" },
                ],
            ],
            timeline_data: [],
            graph_data: [],
            countAttennd_stu: false,
            rollcall_form: {
                rollcall_year: {
                    type: "select",
                    label: "Select year",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select year",
                    validations: { required: true },
                    options: [],
                },

                rollcall_month: {
                    type: "select",
                    label: "Select month",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select year",
                    validations: { required: true },
                    options: [
                        { label: "Select month", value: "" },
                        { label: "January", value: "1" },
                        { label: "February", value: "2" },
                        { label: "March", value: "3" },
                        { label: "April", value: "4" },
                        { label: "May", value: "5" },
                        { label: "June", value: "6" },
                        { label: "July", value: "7" },
                        { label: "August", value: "8" },
                        { label: "September", value: "9" },
                        { label: "October", value: "10" },
                        { label: "November", value: "11" },
                        { label: "December", value: "12" },
                    ],
                },

                rollcall_week: {
                    type: "select",
                    label: "Select week",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select year",
                    validations: { required: true },
                    options: [],
                },

                select_manager: {
                    type: "select",
                    label: "Select Manager",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select manager",
                    validations: { required: true },
                    options: [],
                },
            },
        };
    }
    inputChangeHandler(event, identifier) {
        const update_rollcall_form = { ...this.state.rollcall_form };
        const form_element = { ...update_rollcall_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_rollcall_form[identifier] = form_element;
        let is_form_valid = true;
        for (let identifier in update_rollcall_form) {
            is_form_valid = update_rollcall_form[identifier].valid && is_form_valid;
        }
        this.setState({
            rollcall_form: update_rollcall_form,
            is_form_valid: is_form_valid,
        });
    }

    componentDidMount() {
        this.GetAllManagersListBySchoolId();
        this.setDefaultValue();
        this.GetSchoolById();

        let yearOption = [{ label: "Select year", value: "" },]
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const timezone = user_info?.user_school?.timezone;
        const current = new Date();
        this.setState({ manager_name_admin: user_info?.user_id })
        this.setState({ manager_name: user_info?.first_name + " " + user_info?.last_name })
        for (let i = 0; i <= 5; i++) {
            yearOption.push({
                label: moment(current)?.tz(timezone)?.subtract(i, "years").format('YYYY'),
                value: moment(current)?.tz(timezone)?.subtract(i, "years").format('YYYY')
            })
        }
        const update_rollcall_form = { ...this.state.rollcall_form };
        update_rollcall_form["rollcall_year"].options = yearOption;
        this.setState({ rollcall_form: update_rollcall_form })

        // debugger
        const date_year = update_rollcall_form['rollcall_year'].value;
        const month_filter = update_rollcall_form['rollcall_month'].value;
        let date_month = month_filter.length === 1 ? `0${month_filter}` : `${month_filter}`
        const date_week = update_rollcall_form['rollcall_week'].value;

        let user_id = this.state.manager_name_admin;
        let start_date = `${date_year}-${date_month}-${date_week.slice(0, 2)}`
        let end_date = `${date_year}-${date_month}-${date_week.slice(-2)}`

        this.GetUserAttendanceCountForChart(user_info?.user_id, start_date, end_date)

        console.log(this.state.manager_name_admin, ":jhfgv7update_rollcall_form")
    }

    GetSchoolById = () => {
        const user_data = JSON.parse(localStorage.getItem("bss_authuser"))
        const user_uuid = user_data.user_school.school_detail_uuid
        this.props.onGetSchoolById(user_uuid).then((response) => {
            if (response.success === true) {
                let school_data = response.data;
                console.log(school_data.admin_user[0].user_id, ":school_data")
                // const boarding_head_name = school_data["admin_user"][0].first_name + " " + school_data["admin_user"][0].last_name;
                const boarding_head_name = school_data?.admin_user[0].user_id;
                console.log("school_data", boarding_head_name);
                this.setState({ manager_name_admin: boarding_head_name })
            } else {
            }
        });
    }
    GetAllManagersListBySchoolId = async (form_data) => {
        this.props.onGetAllManagersListBySchoolId().then((response) => {
            let state_level = [];
            state_level.push({
                label: "Select Manager",
                value: "",
            });
            const update_rollcall_form = { ...this.state.rollcall_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    state_level.push({
                        label: arrayData[key].first_name + " " + arrayData[key].last_name,
                        value: arrayData[key].user_id,

                    });
                }
            }
            update_rollcall_form['select_manager'].options = state_level;

            // const managerDefaultValue = state_level.filter((name) => {
            //     return name.label === this.state.manager_name_admin;
            // }).map((name) => name.value);

            update_rollcall_form['select_manager'].value = this.state.manager_name_admin;
            // console.log(state_level.filter((name) => name.label === this.state.manager_name_admin), this.state.manager_name_admin, managerDefaultValue,managerDefaultValue[0], "::state_level78")
            // update_rollcall_form['select_manager'].value = state_level[0].value;
            this.setState({ rollcall_form: update_rollcall_form });
        });
    }

    GetUserAttendanceCountForChart = (user_id, start_date, end_date) => {
        const update_rollcall_form = { ...this.state.rollcall_form }
        const update_graph_data = { ...this.state.graph_data }
        const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        // this.props.onGetUserAttendanceCountForChart(user_id, start_date, end_date).then((response) => {
        //     const graph_data_array = [["Element", "Roll Call", { role: "style" },],]
        //     if (response.success) {
        //         const stu_countAttennd = response.countAttennd;
        //         console.log(stu_countAttennd.map((item) => item.atten_date),"::stu_countAttennd99")
        //         // const day_array1 = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        //         let date_array = stu_countAttennd.map((item) => item.atten_date);

        //         if (stu_countAttennd.length) {
        //             this.setState({ countAttennd_stu: true })
        //         } else {
        //             this.setState({ countAttennd_stu: false })
        //         }
        //         const timeData = response.data;
        //         const selected_week = update_rollcall_form["rollcall_week"].value;
        //         if (selected_week.length === 3) {
        //             const start_date = parseInt(selected_week.slice(0, 1))
        //             const end_date = parseInt(selected_week.slice(-1))
        //             const middle_week_days = end_date - start_date;
        //             if (middle_week_days === 6) {
        //                 for (let i = 0; i <= middle_week_days; i++) {
        //                     graph_data_array.push([day_array[i]])
        //                 }
        //             } else {
        //                 for (let i = middle_week_days; i >= 0; i--) {
        //                     graph_data_array.push([day_array[(day_array.length - 1) - i]])
        //                 }
        //             }
        //         } else {
        //             const start_date = selected_week.length === 4 ? parseInt(selected_week.slice(0, 1)) : parseInt(selected_week.slice(0, 2));
        //             const end_date = parseInt(selected_week.slice(-2))
        //             const middle_week_days = end_date - start_date;
        //             for (let i = 0; i <= middle_week_days; i++) {
        //                 graph_data_array.push([day_array[i]])
        //             }
        //         }

        //         stu_countAttennd.forEach((item) => {
        //             const getDay = day_array[new Date(item.atten_date).getDay()]
        //             graph_data_array.forEach((days) => {
        //                 console.log(getDay,days,":getDay")
        //                 if (days[0] == getDay) {
        //                     days.push(parseInt(item.count))
        //                     days.push("#009ef7")
        //                     return days
        //                 }

        //             })
        //         })

        //         let modify_array = graph_data_array.map((days) => {
        //             console.log(days, "::342424ertyui")
        //             if (days.length < 3) {
        //                 days.push(0)
        //                 days.push("#009ef7")
        //                 return days
        //             } else {
        //                 return days
        //             }
        //         })
        //         const timeArray_data = timeData.map((time_item) => {
        //             const getDay = day_array[new Date(time_item.created_date).getDay()];
        //             const start_hour = new Date(time_item.created_date).getHours('en-US')
        //             const start_minutes = new Date(time_item.created_date).getMinutes('en-US');
        //             const start_seconds = new Date(time_item.created_date).getSeconds('en-US');
        //             const end_hour = new Date(time_item.duration_time).getHours('en-US')
        //             const end_minutes = new Date(time_item.duration_time).getMinutes('en-US');
        //             const end_seconds = new Date(time_item.duration_time).getSeconds('en-US');
        //             const start_time = new Date(0, 0, 0, start_hour, start_minutes, start_seconds);
        //             const end_time = new Date(0, 0, 0, end_hour, end_minutes, end_seconds);
        //             return [getDay, end_time, start_time]
        //         })
        //         this.setState({ timeline_data: timeArray_data })
        //         this.setState({ graph_data: modify_array })
        //     } else {

        //     }
        // });

        this.props.onGetUserAttendanceCountForChart(user_id, start_date, end_date).then((response) => {
            const updatedStartDate = start_date;
            const updatedEndDate = end_date;


            const graph_data_array = [["Element", "Roll Call", { role: "style" }]];  // Header for the chart
            const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

            if (response.success) {
                const stu_countAttennd = response.countAttennd || [];
                console.log('stu_countAttennd', stu_countAttennd);

                // Create date_array with day and date combination
                const date_array = day_array.map((day, index) => {
                    const matchingItem = stu_countAttennd.find(
                        (item) => new Date(item.atten_date).getDay() === index
                    );
                    if (matchingItem) {
                        const formattedDate = moment(matchingItem.atten_date).format("DD/MM/YYYY");
                        return `${day} (${formattedDate})`;  // Add day with date
                    } else {
                        return `${day} (N/A)`;  // Mark days with no data
                    }
                });


                // Set attendance status
                if (stu_countAttennd.length) {
                    this.setState({ countAttennd_stu: true });
                } else {
                    this.setState({ countAttennd_stu: false });
                }

                const selected_week = update_rollcall_form["rollcall_week"].value;
                // Logic for processing selected_week
                if (selected_week.length === 7) {
                    const start_date = selected_week.split("-")[0]
                    const end_date = selected_week.split("-")[1]
                    const middle_week_days = end_date - start_date;
                    // debugger
                    // function getSelectedWeekDays(selectedDate) {
                    //     const date = new Date(selectedDate);
                    //     const weekdays = [];

                    //     // Add each day to the array until the end of the week (Sunday)
                    //     for (let i = 0; i < 7; i++) {
                    //       const currentDay = new Date(date);
                    //       currentDay.setDate(date.getDate() + i);

                    //       // Stop if the current day is Sunday
                    //       if (currentDay.getDay() === 0 && i !== 0) break;

                    //       weekdays.push({
                    //         date: currentDay.toISOString().split('T')[0], // Format as YYYY-MM-DD
                    //         day: currentDay.toLocaleDateString('en-US', { weekday: 'long' }), // Weekday name
                    //       });
                    //     }

                    //     return weekdays;
                    //   }
                    function getWeekdaysBetweenDates(startDate, endDate) {
                        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                    
                        const start = new Date(startDate);
                        const end = new Date(endDate);
                    
                        const result = [];
                    
                        while (start <= end) {
                            const dayIndex = start.getDay(); // Get the day of the week (0-6)
                            const formattedDate = start.toISOString().split('T')[0]; // Format the date as YYYY-MM-DD
                            result.push(`${weekdays[dayIndex]} (${formattedDate})`); // Add weekday + date to the result
                            start.setDate(start.getDate() + 1); // Move to the next day
                        }
                    
                        return result;
                    }
                    


                    // Example usage:
                    console.log("start_date::", updatedStartDate)
                    const selectedDate = updatedStartDate; // Replace with your desired date

                    const weekDays = getWeekdaysBetweenDates(selectedDate, updatedEndDate);
                    console.log("getweakDays::", weekDays);
                    for (const item in weekDays) {
                        graph_data_array.push([weekDays[item], 0, "#009ef7"]);  // Add 0 and color for each day
                    }
                    // if (middle_week_days === 6) {
                    //     for (let i = 0; i <= middle_week_days; i++) {
                    //         graph_data_array.push([date_array[i], 0, "#009ef7"]);  // Add 0 and color for each day
                    //     }
                    // } else {
                    //     // for (let i = middle_week_days; i >= 0; i--) {
                    //     //     graph_data_array.push([date_array[(date_array.length - 1) - i], 0, "#009ef7"]);
                    //     // }
                    //     // for (let i = 0; i <= middle_week_days; i++) {
                    //     //     graph_data_array.push([weekDays[i], 0, "#009ef7"]);  // Add 0 and color for each day
                    //     // }
                    //     for (const item in weekDays) {
                    //         graph_data_array.push([weekDays[item], 0, "#009ef7"]);  // Add 0 and color for each day
                    //     }
                    // }
                } else {
                    const start_date = selected_week.length === 4
                        ? parseInt(selected_week.slice(0, 1))
                        : parseInt(selected_week.slice(0, 2));
                    const end_date = parseInt(selected_week.slice(-2));
                    const middle_week_days = end_date - start_date;

                    for (let i = 0; i <= middle_week_days; i++) {
                        graph_data_array.push([date_array[i], 0, "#009ef7"]);
                    }
                    console.log(selected_week.length, graph_data_array, middle_week_days, end_date, start_date, "::graph_data_array")
                }
                // Assign counts and styles to graph_data_array
                stu_countAttennd.forEach((item) => {
                    const getDay = day_array[new Date(item.atten_date).getDay()];
                    graph_data_array.forEach((days) => {
                        if (days[0].includes(getDay)) {  // Check if the day matches
                            days[1] = parseInt(item.count);  // Set the "Roll Call" value
                            days[2] = "#009ef7";  // Set the color
                        }
                    });
                });

                // Ensure days with no data are filled with 0 and color
                const modify_array = graph_data_array.map((days) => {
                    if (days.length < 3) {
                        days.push(0);
                        days.push("#009ef7");
                    }
                    return days;
                });
                console.log("graph_data_array", graph_data_array)
                // Update the state with the graph data
                this.setState({ graph_data: modify_array });

                // Process time data
                const timeArray_data = response.data.map((time_item) => {
                    // Get the day of the week from created_date
                    const getDay = day_array[new Date(time_item.created_date).getDay()];

                    // Parse the start and end times
                    const start_date = new Date(time_item.created_date);
                    const end_date = new Date(time_item.duration_time);

                    const start_hour = start_date.getHours();
                    const start_minutes = start_date.getMinutes();
                    const start_seconds = start_date.getSeconds();

                    const end_hour = end_date.getHours();
                    const end_minutes = end_date.getMinutes();
                    const end_seconds = end_date.getSeconds();

                    // Format start and end times (could also use moment.js if needed)
                    const start_time = new Date(0, 0, 0, start_hour, start_minutes, start_seconds);
                    const end_time = new Date(0, 0, 0, end_hour, end_minutes, end_seconds);

                    // Return day and formatted times for the timeline
                    return [getDay, end_time, start_time];
                });

                // Set the timeline data in state
                this.setState({ timeline_data: timeArray_data });
            } else {
                // Handle failure case
            }
        });
    }

    setDefaultValue = () => {
        const update_rollcall_form = { ...this.state.rollcall_form };
        const year = new Date().getFullYear();
        const month_index = new Date().getMonth();
        const month = update_rollcall_form['rollcall_month'].options[month_index + 1].value;
        update_rollcall_form['rollcall_month'].value = month;
        update_rollcall_form['rollcall_year'].value = year.toString()

        const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
        const month_length = new Date(year, month, 0).getDate();
        const get_day = day_array[new Date(year, month - 1).getDay()];
        const current_date = new Date().getDate();
        const weeks_data = []
        let week_array;
        const month_dates = []
        for (let i = 1; i <= month_length; i++) {
            month_dates.push(i)
        }
        if (get_day === 'Sun') {
            const all_weeks = Math.floor(month_length / 7);
            const remain_last_day = month_length % 7;
            const all_weeks_slice = month_dates.slice(0, all_weeks * 7)
            const remaining_days = month_dates.slice((all_weeks * 7), month_dates.length)
            weeks_data.push(remaining_days)
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `Week ${weeks_data.length - index}`,
                    value: `${item[0] < 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] < 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        } else {
            const pre_week_days = day_array.length - day_array.indexOf(get_day)
            const remain_last_day = (month_length - pre_week_days) % 7;
            const all_weeks = Math.floor((month_length - pre_week_days) / 7);
            const pre_days_slice = month_dates.slice(0, pre_week_days)
            const all_weeks_slice = month_dates.slice(pre_week_days, (all_weeks * 7) + pre_week_days)
            const remaining_days = month_dates.slice((all_weeks * 7) + pre_week_days, month_dates.length)
            if (remaining_days.length > 0) {
                weeks_data.push(remaining_days);
            }
            for (let i = 1; i <= all_weeks; i++) {
                const thirdPart = all_weeks_slice.splice(-7);
                weeks_data.push(thirdPart)
            }
            weeks_data.push(pre_days_slice)
            week_array = weeks_data.map((item, index) => {
                return {
                    label: `Week ${weeks_data.length - index}`,
                    value: `${item[0] < 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] < 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                }
            })
        }
        update_rollcall_form["rollcall_week"].options = week_array.reverse();
        week_array.forEach((item) => {
            if (item.value.length === 3) {
                const start_date = parseInt(item.value.slice(0, 1))
                const end_date = parseInt(item.value.slice(-1))
                if (current_date >= start_date && current_date <= end_date) {
                    update_rollcall_form["rollcall_week"].value = item.value;
                }
            } else {
                const start_date = item.value.length === 4 ? parseInt(item.value.slice(0, 1)) : parseInt(item.value.slice(0, 2));
                const end_date = parseInt(item.value.slice(-2))
                if (current_date >= start_date && current_date <= end_date) {
                    update_rollcall_form["rollcall_week"].value = item.value;
                }
            }
        })
        this.setState({ rollcall_form: update_rollcall_form })
    }


    handleChange(data, identifier) {
        const update_rollcall_form = { ...this.state.rollcall_form };
        if (identifier === "rollcall_year") {
            update_rollcall_form[identifier].value = data.value;
        } else if (identifier === "rollcall_month") {
            update_rollcall_form[identifier].value = data.value;
            const day_array = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
            const year = this.state.rollcall_form.rollcall_year.value;
            const month = data.value;
            const month_length = new Date(year, month, 0).getDate();
            const get_day = day_array[new Date(year, month - 1).getDay()];
            console.log("dataa============", month_length, get_day);
            const weeks_data = []
            let week_array;
            const month_dates = []
            for (let i = 1; i <= month_length; i++) {
                month_dates.push(i)
            }
            if (get_day === 'Sun') {
                const all_weeks = Math.floor(month_length / 7);
                const remain_last_day = month_length % 7;
                const all_weeks_slice = month_dates.slice(0, all_weeks * 7)
                const remaining_days = month_dates.slice((all_weeks * 7), month_dates.length)
                weeks_data.push(remaining_days)
                for (let i = 1; i <= all_weeks; i++) {
                    const thirdPart = all_weeks_slice.splice(-7);
                    weeks_data.push(thirdPart)
                }
                week_array = weeks_data.map((item, index) => {
                    return {
                        label: `${item[0]} - ${item[item.length - 1]}`,
                        value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                    }
                })
            } else {
                const pre_week_days = day_array.length - day_array.indexOf(get_day)
                const remain_last_day = (month_length - pre_week_days) % 7;
                const all_weeks = Math.floor((month_length - pre_week_days) / 7);
                const pre_days_slice = month_dates.slice(0, pre_week_days)
                const all_weeks_slice = month_dates.slice(pre_week_days, (all_weeks * 7) + pre_week_days)
                const remaining_days = month_dates.slice((all_weeks * 7) + pre_week_days, month_dates.length)
                if (remaining_days.length > 0) {
                    weeks_data.push(remaining_days);
                }
                for (let i = 1; i <= all_weeks; i++) {
                    const thirdPart = all_weeks_slice.splice(-7);
                    weeks_data.push(thirdPart)
                }
                weeks_data.push(pre_days_slice)
                week_array = weeks_data.map((item, index) => {
                    return {
                        label: `${item[0]} - ${item[item.length - 1]}`,
                        value: `${item[0] <= 9 ? "0" + item[0] : item[0]} - ${item[item.length - 1] <= 9 ? "0" + item[item.length - 1] : item[item.length - 1]}`
                    }
                })
            }
            update_rollcall_form["rollcall_week"].options = week_array.reverse();
        } else if (identifier === "rollcall_week") {
            const date_year = update_rollcall_form['rollcall_year'].value;
            const month_filter = update_rollcall_form['rollcall_month'].value;
            let date_month = month_filter.length === 1 ? `0${month_filter}` : `${month_filter}`
            const date_week = data.value;
            console.log(data, "::handleinouiuy87")

            let user_id = update_rollcall_form.select_manager.value;
            let start_date = `${date_year}-${date_month}-${date_week.slice(0, 2)}`
            let end_date = `${date_year}-${date_month}-${date_week.slice(-2)}`

            update_rollcall_form["rollcall_week"].value = data.value;
            this.GetUserAttendanceCountForChart(user_id, start_date, end_date)
        } else if (identifier === "select_manager") {
            this.setState({ manager_name: data.label })
            const date_year = update_rollcall_form['rollcall_year'].value;
            const month_filter = update_rollcall_form['rollcall_month'].value;
            let date_month = month_filter.length === 1 ? `0${month_filter}` : `${month_filter}`
            const date_week = update_rollcall_form['rollcall_week'].value;
            let user_id = data.value;

            let start_date = `${date_year}-${date_month}-${date_week.slice(0, 2)}`
            let end_date = `${date_year}-${date_month}-${date_week.slice(-2)}`
            update_rollcall_form["select_manager"].value = data.value;
            this.GetUserAttendanceCountForChart(user_id, start_date, end_date)
        } else {
            update_rollcall_form[identifier].value = data.value;
        }
        this.setState({ rollcall_form: update_rollcall_form });
    }




    // SubmitHandler = async (event) => {
    //     console.log("gfdgjdfkj");
    //     event.preventDefault();
    //     let is_form_valid = true;
    //     const update_rollcall_form = this.state.rollcall_form;
    //     for (let key in update_rollcall_form) {
    //         let form_element = update_rollcall_form[key];
    //         let validate_element = Validations(form_element);

    //         is_form_valid = validate_element.valid === false ? false : is_form_valid;
    //         form_element.onBlur_out = true;
    //         form_element.valid = validate_element.valid;
    //         form_element.error_msg = validate_element.error_msg;

    //         update_rollcall_form[key] = form_element;
    //     }
    //     this.setState({ rollcall_form: update_rollcall_form });
    //     if (is_form_valid) {
    //         const form_data = {};
    //         for (let key in this.state.rollcall_form) {
    //             form_data[key] = this.state.rollcall_form[key].value;
    //         }
    //         console.log("form_data===", form_data);
    //     }
    // };

    render() {
        const { rollcall_form, countAttennd_stu } = this.state;
        console.log(rollcall_form, "::rollcall_form")

        return (
            <>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div className="card mb-6">
                    <div className="card-header align-items-center">
                        <h2 className="card-title">
                            Number of Rollcall sessions conducted
                        </h2>
                    </div>

                    <div className="row m-0 px-2 pt-5" >
                        <div className="col-lg-4 fv-row">
                            <label className="fw-bold mb-2">Select Year</label>
                            <Select
                                defaultValue={rollcall_form.rollcall_year.value}
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer "
                                options={rollcall_form.rollcall_year.options}
                                value={rollcall_form.rollcall_year.options.filter(function (option) {
                                    return option.value === rollcall_form.rollcall_year.value;
                                })}
                                onChange={(newValue) => this.handleChange(newValue, "rollcall_year")}
                            />
                            {!rollcall_form.rollcall_year.valid && rollcall_form.rollcall_year.onBlur_out ? <div className="error field-error">{rollcall_form.rollcall_year.error_msg}</div> : ""}
                        </div>

                        <div className="col-lg-4 fv-row">
                            <label className="fw-bold mb-2">Select month</label>
                            <Select
                                defaultValue={rollcall_form.rollcall_month.value}
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer "
                                options={rollcall_form.rollcall_month.options}
                                value={rollcall_form.rollcall_month.options.filter(function (option) {
                                    return option.value === rollcall_form.rollcall_month.value;
                                })}
                                onChange={(newValue) => this.handleChange(newValue, "rollcall_month")}
                            />

                            {!rollcall_form.rollcall_month.valid && rollcall_form.rollcall_month.onBlur_out ? <div className="error field-error">{rollcall_form.rollcall_month.error_msg}</div> : ""}
                        </div>

                        <div className="col-lg-4 fv-row" >
                            <label className="fw-bold mb-2">Select Week</label>
                            <Select
                                defaultValue={rollcall_form.rollcall_week.value}
                                styles={customStyles}
                                className="react-bootstrap-typeahead tag-outer "
                                options={rollcall_form.rollcall_week.options}
                                value={rollcall_form.rollcall_week.options.filter(function (option) {
                                    return option.value === rollcall_form.rollcall_week.value;
                                })}
                                onChange={(newValue) => this.handleChange(newValue, "rollcall_week")}
                            />
                            {!rollcall_form.rollcall_week.valid && rollcall_form.rollcall_week.onBlur_out ? <div className="error field-error">{rollcall_form.rollcall_week.error_msg}</div> : ""}
                        </div>
                    </div>

                    <div className="p-5">
                        <label className="fw-bold mb-2">Select Manager</label>
                        <Select
                            defaultValue={rollcall_form.select_manager.value}
                            styles={customStyles}
                            className="react-bootstrap-typeahead tag-outer "
                            options={rollcall_form.select_manager.options}
                            value={rollcall_form.select_manager.options.filter(function (option) {
                                return option.value === rollcall_form.select_manager.value;
                            })}
                            onChange={(newValue) => this.handleChange(newValue, "select_manager")}
                        />
                        {!rollcall_form.select_manager.valid && rollcall_form.select_manager.onBlur_out ? <div className="error field-error">{rollcall_form.select_manager.error_msg}</div> : ""}
                    </div>

                    <div>
                        {/* {console.log("graph_data_array",graph_data_array)} */}
                        {this.state.graph_data.length > 0 ?
                            <div className="p-5">
                                {
                                    countAttennd_stu &&
                                    <div>
                                        <h5 className="px-5 text-center"> Number of Rollcall sessions per day by {this.state.manager_name}</h5>
                                        <div className="for_hide_total_tag">
                                            <Chart
                                                chartType="ColumnChart"
                                                width="100%"
                                                height="400px"
                                                options={options}
                                                data={this.state.graph_data}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="px-5">
                                    <h5 className="text-center">Rollcall duration (time) for {this.state.manager_name}</h5>
                                    {this.state.timeline_data.length > 0 ?
                                        <Chart chartType="Timeline" data={[...this.state.timeline_label, ...this.state.timeline_data]} width="100%" />
                                        :
                                        <p className="text-center">no data</p>
                                    }
                                </div>
                            </div>
                            :
                            <div className="p-5 text-center">
                                <h6>Number of Roll Call sessions per day by {this.state.manager_name}</h6>
                                <h6>Roll Calls time duration by {this.state.manager_name}</h6>
                                <p>No Roll Call found</p>
                            </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onGetAllManagersListBySchoolId: userActions.GetAllManagersListBySchoolId,
    onGetUserAttendanceCountForChart: userActions.GetUserAttendanceCountForChart,
    onGetSchoolById: userActions.GetSchoolById,
};
export default connect(mapStateToProps, mapDispatchToProps)(Rollcall);