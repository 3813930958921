import React, { useCallback, useEffect, useState } from "react";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../../../actions/index";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import moment from "moment";




const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;

const AdhocLeave = (props) => {
    const [adhocData, setAdhocData] = useState([])
    useEffect(() => {
        GetAllAdhocLeaveByStudentId();
    }, [])

    const GetAllAdhocLeaveByStudentId = useCallback(async () => {
        let sort;
        let order;
        props.onGetAllAdhocLeaveByStudentId(props.user_uuid, sort = "departure_date", order = "asc").then((response) => {
            console.log("response", response);
            setAdhocData(response.data.rows)

        })
    }, [])

    return (
        <>
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                {/* <div className="container-fluid pb-6 d-flex align-items-center">
                                    <div id="topaccountmenudata" className="cursor-pointer symbol symbol-30px symbol-md-40px topaccountmenudata " data-kt-menu-placement="bottom-end">
                                        <img alt="Logo" 
                                            src={
                                                props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== "" 
                                                ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                : (props?.studentData?.gender === "male" 
                                                    ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                    : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                            }
                                        />
                                    </div>
                                    <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                </div> */}
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist" style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                            <div style={{ display: "flex", overflowX: "scroll"}}>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/student-profile/${props.user_uuid}`} className="nav-link" id="StudentProfile-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="home" aria-selected="true">
                                                        Student Profile
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/approved-host/${props.user_uuid}`} className="nav-link" id="ApprovedHosts-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="true">
                                                        Approved Hosts
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/leave-history/${props.user_uuid}`} className="nav-link" id="LeaveHistory-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Leave History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/attendance-history/${props.user_uuid}`} className="nav-link" id="AttendanceHistory-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Attendance History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/pastoral-notes/${props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Pastoral Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/medical-notes/${props.user_uuid}`} className="nav-link" id="MedicalNotes-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Medical Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/allergy-reports/${props.user_uuid}`} className="nav-link" id="AllergyReport-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Allergy Reports
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-leave/${props.user_uuid}`} className="nav-link active" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        AdhocLeave
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-actual/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Adhoc Actual
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/file-storage/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        File Storage
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/student-activities/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Student Activities
                                                    </Link>
                                                </li>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                                <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                    <img alt="Logo" style={{ marginBottom: "4px" }}
                                                        src={
                                                            props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== ""
                                                                ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                                : (props?.studentData?.gender === "male"
                                                                    ? "https://bssnew.dikonia.in/media/avatars/boy.jpg"
                                                                    : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </ul>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card mb-5 tabs-outer">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-sm-12 d-flex align-items-center justify-content-between mb-4">
                                                                <MDBTable className="table-row-bordered">
                                                                    <MDBTableHead>
                                                                        <tr>
                                                                            <th>Sr. No.</th>
                                                                            <th>Departure Date</th>
                                                                            <th>Return Date</th>
                                                                            <th>Day</th>
                                                                            <th>Venue</th>
                                                                            <th>Activity</th>
                                                                            <th>Departure Mode</th>
                                                                            <th>Return Mode</th>
                                                                        </tr>
                                                                    </MDBTableHead>
                                                                    <MDBTableBody>
                                                                        {
                                                                            adhocData.map((leave, index) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>{moment(leave.departure_date).format("DD/MM/YYYY")}</td>
                                                                                        <td>{moment(leave.arrival_date).format("DD/MM/YYYY")}</td>
                                                                                        <td>{leave.adhoc_leave_day}</td>
                                                                                        <td>{leave.adhoc_leave_data.venue}</td>
                                                                                        <td>{leave.adhoc_leave_data.adhoc_activity_data.activity_name}</td>
                                                                                        <td>{leave.adhoc_leave_data.depart_mode_data.travel_mode_name}</td>
                                                                                        <td>{leave.adhoc_leave_data.arrival_mode_data.travel_mode_name}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </MDBTableBody>
                                                                </MDBTable>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetAllAdhocLeaveByStudentId: userActions.GetAllAdhocLeaveByStudentId,
};
export default connect(mapStateToProps, mapDispatchToProps)(AdhocLeave);
