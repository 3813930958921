import React, { useState } from "react";
import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";
import StudentImport from "./ImpStd";
import ImportCSV from "../../../SampleFile.xlsx"

export default function ImportStudent() {
    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        console.log("form_data===", form_data);
        setconfirm_modal(false);
        // props.onDeleteCourse(form_data).then((response) => {
        //     if (response.success === true) {
        //         GetAllCourses();
        //         toast.success(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //         setadd_course_modal(false);
        //         setedit_course_modal(false);
        //         setconfirm_modal(false);
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        // });
    };
    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Student?`}
                    bodytitle="Are you sure you want to delete this Student?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <div className="school-edit-form">
                                            <div className="" id="Snapshot">
                                                <div className="card-body p-0">
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <StudentImport />
                                                        </div>

                                                        <div className="col-sm-4">
                                                            <div className="card ">
                                                                {/* <!--begin::Header--> */}
                                                                <div className="card-header border-bottom border-bottom-1">
                                                                    <h3 className="card-title align-items-start flex-column">
                                                                        <span className="card-label fw-bold text-dark">Download Sample File</span>
                                                                    </h3>
                                                                </div>

                                                                <div className="card-body p-6">
                                                                    <div className="d-flex flex-column align-items-center">
                                                                        <span className="download-csv">
                                                                            {" "}
                                                                            <a href={ImportCSV} download="Sample File"><i className="fas fa-file-csv"></i></a>
                                                                        </span>
                                                                        <a href={ImportCSV} download="Sample File"> <span className="btn btn-sm btn-primary apply-btn add-btns">Download Sample .xlsx </span> </a>
                                                                    </div>
                                                                    <div className="d-flex flex-column">
                                                                        <h4 className="import-title">Import Instruction</h4>

                                                                        <div className="import-out">
                                                                            <ul>
                                                                                <li>
                                                                                    Use the above ‘Sample File’ as a template to import your student data to this school portal - in batches.
                                                                                    <br />
                                                                                    The batches, as you can see on the left here - include “Class” & “Dormitory Name”  In other words - all the students in a particular class, (or year level) and dormitory are grouped in 1 CSV file.  You can name that
                                                                                    file just how you wish, but “Year12_Gryffindor.csv” might be a practical name to work with. <br />
                                                                                    Of course you may have Male or Female within the same .csv file - that would indicate you have both male or female in the same residence & of course, that you have exceptionally astute staff!
                                                                                    <br />
                                                                                    <br />
                                                                                    Download the file & populate it without changing ANY of the headings or structure of the .csv file. This means you may perform functions like resize column width, change filename, but . . . DON’T change Column Headings/Titles. Respect the example formatting ie use (DD/MM/YYYY) for Date of Birth.  Laundry numbers can be alphanumeric, don’t try to change left, right or center alignment, font size etc. Most spreadsheet apps will do it just fine. ie Excel or Sheets.
                                                                                    <br />
                                                                                    <br />
                                                                                    Add your data in accordance with the template student’s data in the top two rows. You MAY copy and paste preformatted data (like colour or different fonts) but then when you Save, just ensure you Save As .csv  You will likely get some warning about the data not remaining the way it appears – it won’t, and that is not a problem because it MUST be a .csv, which is simply a spreadsheet file which has minimal formatting.
                                                                                    <br />
                                                                                    <br />
                                                                                    The more information (data) you add, the more data you can work with in your Boarding School. As an absolute minimum, add data to:- <br />
                                                                                    <b>Student First Name, Student Last Name, Student Preferred Name </b> <br />
                                                                                    <b>Student Email, </b>(This MUST be according to your school URL, as provided.  Cannot be private email or any other URL) <br />
                                                                                    <b>Gender</b> Male or Female <br />
                                                                                    <b>Date of Birth</b> (DD/MM/YYYY) <br />
                                                                                    <b>Salutation Parents.</b> “Professor Albus Dumbledore & Mrs Gellert Grindelwald” or “Child Protective Services” or “Mr & Mrs Jones”<br />
                                                                                    <b>Father as Host, Mother as Host.</b> Yes or No, are the only responses here.  In most cases the response is “Yes”  This simply makes parents as “Automatically Approved Hosts” to take the students on Leave.  In rare cases, it may be “No”  This can easily be changed in “Student Profile” later on.<br />
                                                                                    Do not delete the vacant columns, just leave them blank. Remember the old IT adage GIGO  :-) <br />
                                                                                    Photos of students need to be uploaded individually. You can take to photos with this go-liberty system or you can upload existing photos.<br />
                                                                                </li>
                                                                                <li>
                                                                                    Please check the .pdf manual for these same instructions, maybe in a little more detail or with images, if more information is required.
                                                                                    Finally support@go-liberty.com will respond to your help requests. <br />
                                                                                    *If you can begin by creating .csv files. One for each Class (year level) of each Dorm and naming them accordingly – your work is done!
                                                                                </li>

                                                                                <li>
                                                                                    Reread Step 1 & 2
                                                                                    <br />
                                                                                    <br />
                                                                                    <b>STEPS</b> (Summary)<br />
                                                                                    i) Work out what files you will be needing.
                                                                                    <br />
                                                                                    ii) Name them, on paper – Write it down.
                                                                                    <br />
                                                                                    iii) Take the sample CSV file above – and do a “Save As” function applying each name that you have written down. Be sure that you are “Saving As” keeping the correct Filename, that you wrote down - as well as File Type, namely .csv
                                                                                    <br />
                                                                                    iv) Now go and populate each of those csv files you just created!  Easiest is to copy n paste from another file that your - ever helpful IT department has given you.
                                                                                    <br />
                                                                                    v) Maybe your IT support team will be kind enough to populate each of those files for you? All pretty easy considering this is the ‘hardest’ function that go-liberty will throw at you!
                                                                                    <br />
                                                                                    vi)     Oh - You may wish to DELETE Rows 1 and 2 or you might have those 2 sample students attending your Boarding School!
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
