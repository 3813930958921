import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import Sidebar from "../../Constant/sidebar";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


import * as userActions from "../../../actions/index";

import GenericItem from "./GenericItem";


import DeleteConfirmRemove from "../../Utility/DeleteConfirmRemove";

const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" :user_info?.school_code;
const permission_info = JSON.parse(localStorage.getItem("permissions"));
const GenericList = (props) => {
    const dispatch = useDispatch();

    const [filterlimit, setfilterlimit] = useState(10);
    const [filterpage, setfilterpage] = useState(1);
    const [filterorder, setfilterorder] = useState("asc");
    const [filtersort, setfiltersort] = useState("student_first_name");
    const [filtersearch, setfiltersearch] = useState(null);

    const [GenericList, setGenericList] = useState([]);
    const [GenericListcount, setGenericListcount] = useState(0);
    const [is_generic_activate, setis_generic_activate] = useState(true);

    const [confirm_modal, setconfirm_modal] = useState(false);

    const [student_id] = useState('');

    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);

    const navigate = useNavigate();


    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };
    const RemoveCourseModule = (event, course_data) => {
        seteditconfirmremovedata(course_data);
        setconfirm_modal(true);
    };

    const ConfirmRemove = (form_data) => {
        console.log('form_data===', form_data)
        setconfirm_modal(false);
        props.onDeleteStudentGeneric(form_data).then((response) => {
            if (response.success === true) {
                GetAllStudentsGenericList();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                // setadd_course_modal(false);
                // setedit_course_modal(false);
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    const GetAllStudentsGenericList = useCallback(async () => {
        dispatch(userActions.GetAllStudentsGenericList(filterpage, filterlimit, filtersort, filterorder, is_generic_activate, filtersearch , student_id)).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                let MemberId_level = [];
                const sumvalue = 1;
                for (let key in arrayData) {
                    const perpage = ((parseInt(filterpage) - parseInt(sumvalue)) * parseInt(filterlimit) + parseInt(key) + parseInt(sumvalue));

                    MemberId_level.push({
                        perpage: perpage,
                        country_id: arrayData[key].country_id,
                        name: arrayData[key].name,

                    });
                }
                setGenericList(arrayData);
                setGenericListcount(response.data.count);
            }

            else if (response.status === 401) {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setTimeout(() => {
                    navigate('/logout')
                }, 5000);
            }

            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch, filterpage, filterlimit, filtersort, filterorder, is_generic_activate, filtersearch , student_id]);

    useEffect(() => {
        GetAllStudentsGenericList();
    }, [GetAllStudentsGenericList, filterpage, filterlimit, filtersort, filterorder, is_generic_activate, filtersearch , student_id]);


    const handleChange = async (event, student_generic_uuid, value, student_name) => {
        console.log("hello")
        const Payload = {
            student_generic_uuid: student_generic_uuid,
            is_generic_activate: value,
            student_name:student_name
        };
        console.log('Payload===', Payload)


        props.onUpdateStudentGenericStatus(Payload).then((response) => {
            if (response.success === true) {
                GetAllStudentsGenericList();
                setfilterpage(1);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });

    }



    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete Student Reminder ?`}
                    bodytitle="Are you sure you want to delete this Student Reminder?"
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}

            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    {/* <!--begin::Container--> */}
                                    <div id="kt_content_container" className="container-fluid">


                                        <div className="tab-content" id="myTabContent">
                                            <div className="tab-pane fade" id="Snapshot" role="tabpanel" aria-labelledby="Snapshot-tab"></div>

                                            <div className="tab-pane fade show active" id="Rollcall" role="tabpanel" aria-labelledby="Rollcall-tab">
                                                <div className="row">
                                                    <div className="col-sm-8">
                                                        <div className="card  tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                <h2 className="card-title">Student Reminder</h2>
                                                                <div className="card-toolbar">


                                                                {permission_info?.add_flag_students !== false && 
                                                                (<Link to={`/${school_code}/student/flag-student/add-generic-student`} className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary addschool-button">
                                                                Add Student Reminder</Link>)}
                                                                </div>
                                                            </div>

                                                            <div className="card-body ">
                                                                <div className="card-toolbar">
                                                                    <div className="d-flex align-items-center justify-content-between">

                                                                        {/* <!-- <h4 className="card-title  m-0">Schools</h4> --> */}

                                                                        <div className="d-flex align-items-center position-relative">
                                                                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                                    <path
                                                                                        d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                                        fill="currentColor"
                                                                                    ></path>
                                                                                </svg>
                                                                            </span>

                                                                            <input type="text" className="form-control form-control-sm w-350px ps-12 search-out fs-6" placeholder="Search " style={{ background: "transparent" }}
                                                                                onChange={(event) => {
                                                                                    setfiltersearch(event.target.value);
                                                                                    setfilterpage(1);
                                                                                }}

                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <GenericItem

                                                                                GenericListcount={GenericListcount}
                                                                                data={GenericList}
                                                                                filterlimit={filterlimit}
                                                                                filterpage={filterpage}
                                                                                RemoveCourseModule={(event, data, studen_name) => RemoveCourseModule(event, data)}
                                                                                propsfilterpage={(event) => setfilterpage(event)}

                                                                                handleChange={(event, student_generic_uuid, value, studen_name) => handleChange(event, student_generic_uuid, value, studen_name)}
                                                                                propsfilterlimit={(filterlimit) => {
                                                                                    setfilterlimit(filterlimit);
                                                                                }}


                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-sm-4">
                                                        <div className="card">
                                                            {/* <!--begin::Header--> */}
                                                            <div className="card-header border-bottom border-bottom-1">
                                                                <h3 className="card-title align-items-start flex-column">
                                                                    <span className="card-label fw-bold text-dark">Filters</span>
                                                                </h3>
                                                            </div>

                                                            <div className="card-body p-6">
                                                                <div className="d-flex flex-column">
                                                                    <h5>Sort By</h5>
                                                                    <div className="d-flex">
                                                                        <button className=

                                                                            {"filter-badge me-2 filter-button " +
                                                                                (filtersort === "dormitory_name" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                if (filterorder === 'desc') {
                                                                                    setfilterorder('asc');
                                                                                }
                                                                                else {
                                                                                    setfilterorder('desc');
                                                                                }
                                                                                setfiltersort('dormitory_name');
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >Name</button>

                                                                        <button className=

                                                                            {"filter-badge me-2 filter-button " +
                                                                                (filtersort === "student_id" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                if (filterorder === 'desc') {
                                                                                    setfilterorder('asc');
                                                                                }
                                                                                else {
                                                                                    setfilterorder('desc');
                                                                                }
                                                                                setfiltersort('student_id');
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >S.No</button>

                                                                    </div>
                                                                </div>

                                                                <div className="d-flex flex-column mt-6">
                                                                    <h5>Show</h5>
                                                                    <div className="d-flex align-items-center">
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_generic_activate === "" ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_generic_activate('');
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >All</span>
                                                                        <span className=

                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_generic_activate === true ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_generic_activate(true);
                                                                                setfilterpage(1);

                                                                            }}
                                                                        >Active</span>
                                                                        <span className=
                                                                            {"filter-badge me-2 filter-button " +
                                                                                (is_generic_activate === false ? "btn btn-primary active-filter" : "btn btn-dark")
                                                                            }
                                                                            onClick={(event) => {
                                                                                setis_generic_activate(false);
                                                                                setfilterpage(1);

                                                                            }}

                                                                        >Inactive</span>
                                                                    </div>
                                                                </div>



                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!--end::Container--> */}
                                </div>
                            </div>
                            <Footer></Footer>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {


    onUpdateStudentGenericStatus: userActions.UpdateStudentGenericStatus,
    onDeleteStudentGeneric: userActions.DeleteStudentGeneric,

};
export default connect(mapStateToProps, mapDispatchToProps)(GenericList);