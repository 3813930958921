import { Tooltip } from '@mui/material';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import moment from 'moment';
import React from 'react'
import { useNavigate, Link } from "react-router-dom";
import CapitalFirstLetter from '../../../Utility/CapitalFirstLetter';
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const timezone = user_info?.user_school?.timezone;
const current = new Date();
const school_code = user_info?.school_code ?? null;
export default function ReturningAndDelayed({ rightSide, rightSideHeader }) {
    let current_date = moment().tz(timezone).format('MMMM Do YYYY, h:mm:ss a')
    let current_date2 = moment().tz(timezone)
    return (
        <>
            <div className='table-responsive'>
                <MDBTable className='table table-row-bordered'>
                    <MDBTableHead>
                        <tr>
                            <th className='min-w-150px'>Student Name</th>
                            <th>{rightSideHeader == "Students Late or Delayed" ?
                                "Duration"
                                :
                                <Tooltip title="Expected Time Departure" placement="top">
                                    ETD
                                </Tooltip>
                            }
                            </th>
                            <th>Dormitory</th>
                            <th>Year</th>
                            {rightSideHeader == "Students Returning Today" && <th>Status</th>}
                            {rightSideHeader == "Students Late or Delayed" && <th>Delay</th>}
                            {rightSideHeader == "Students Late or Delayed" && <th>Actions</th>}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {rightSide.map((item, index) => {
                            return (
                                <tr key={item.border_leave_id}>
                                    <td>
                                        {`${item?.student_first_name === undefined ? CapitalFirstLetter(item.student_data.student_first_name) : CapitalFirstLetter(item.student_first_name)} ${item.student_last_name === undefined ? item.student_data.student_last_name : item.student_last_name}`}
                                    </td>

                                    <td>
                                        {item.return_status === undefined ?
                                            <span>From <strong>{moment(item.departData.planned_depart_date).format('DD MMM')}</strong> To <strong> {moment(item.planned_return_date).format('DD MMM')}</strong></span>
                                            :
                                            moment(item.planned_return_time, "HH:mm").format("h:mm A")
                                        }
                                    </td>
                                    <td>
                                        {item.student_data?.dormitory_data?.dormitory_name}
                                    </td>
                                    <td>
                                        {item.student_data?.class_name}
                                    </td>
                                    {
                                        rightSideHeader == "Students Returning Today" &&
                                        <td>

                                            <span>
                                                {
                                                    item.return_status === "pending" ?
                                                        current_date > moment(`${item.planned_return_date} ${item.planned_return_time}`, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do YYYY, h:mm:ss a') ? <img src="/assets/media/late.png" style={{ maxHeight: "45px" }} /> : "-"
                                                        : <img src="/assets/media/down-arrow.png" style={{ maxHeight: "26px" }} />
                                                }
                                            </span>
                                        </td>
                                    }
                                    {rightSideHeader == "Students Late or Delayed" &&
                                        <td>
                                            {item.formattedDelay &&
                                                <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-1 px-4">
                                                    {item.formattedDelay}
                                                </div>
                                            }
                                        </td>
                                    }
                                    {item.return_status == undefined &&
                                        <td>
                                            <Tooltip title="View" placement="top">
                                                <Link className='ms-auto' to={`/${school_code}/leave/edit-boarders-leave/${item.departData.border_leave_id}`}>
                                                    <i className="bi bi-eye-fill text-primary fs-2 ms-auto"></i>
                                                </Link>
                                            </Tooltip>

                                        </td>}
                                </tr>
                            )
                        })}

                    </MDBTableBody>
                </MDBTable>
            </div>
            {/* <div className="d-flex justify-content-between">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={row_limit[1]}
                    name="color"
                    options={row_limit}
                    onChange={(e) => setLimit(e.value)}
                />
                <Stack>
                    <Pagination
                        count={Math.ceil(count / limit)}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        color="primary"
                    />
                </Stack>
            </div> */}
        </>
    )
}
