import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";
import * as userActions from "../../actions/index";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import AdhocLeaveSignInSignOutModal from "../Leave/AdhocLeaveModals/AdhocLeaveSignInSignOutModal";
const Adhocreport = (props) => {
    const [tableData, setTableData] = useState([])
    const [confirm_modal, setConfirm_modal] = useState(false)
    const [status_data, setStatus_data] = useState({})
    const [pendingCheckInOut, setPendingCheckInOut] = useState(false)
    const [isLoading, setIsloading] = useState(false)
    const [filterBySort, setFilterBySort] = useState("start_time")
    const [filterByorder, setFilterByorder] = useState(false)

    let time = new Date().toLocaleTimeString()

    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const timezone = user_info?.user_school?.timezone;

    const current = new Date();
    let date = moment(current)?.tz(timezone)?.format('MMMM Do YYYY');

    const [ctime, setTime] = useState(date)

    const UpdateTime = () => {
        date = moment(current)?.tz(timezone)?.format('MMMM Do YYYY');
        setTime(date)
    }
    setInterval(UpdateTime, 1000)

    useEffect(() => {
        // GetAllAdhocLeaveforSignOrSignOut()
        GetAllAdhocLeaveStudentDetails()
    }, [pendingCheckInOut, filterBySort, filterByorder])

    // const GetAllAdhocLeaveforSignOrSignOut = async () => {
    //     props.onGetAllAdhocLeaveforSignOrSignOut().then((response) => {
    //         if (response.success === true) {
    //             const adhoc_data = response.data.rows;
    //             const filter_adhoc_data = []
    //             adhoc_data.forEach((item) => {
    //                 const filter_stu_adhoc_data = item.stu_adhoc_data.filter(element => {
    //                     return date === moment(element.departure_date)?.tz(timezone)?.format('MMMM Do YYYY')
    //                 })
    //                 if(filter_stu_adhoc_data.length >=1){
    //                     filter_adhoc_data.push({ ...item, stu_adhoc_data: filter_stu_adhoc_data })
    //                 }
    //             })
    //             setTableData(filter_adhoc_data)
    //             console.log("adhoc_data", filter_adhoc_data);

    //         } else {
    //             toast.error(response.message, {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         }
    //     })
    // }

    const GetAllAdhocLeaveStudentDetails = async () => {
        const payload = {
            page: null,
            limit: null,
            sort: filterBySort,
            order: filterByorder,
            search: null,
            from_date: null,
            to_date: null,
            class_id: null,
            dormitory_id: null,
            activity_id: null,
            pending: pendingCheckInOut
        }
        props.onGetAllAdhocLeaveStudentDetails(payload.page, payload.limit, payload.sort, payload.order, payload.search, payload.from_date, payload.to_date, payload.class_id,
            payload.dormitory_id, payload.activity_id, payload.pending).then((response) => {
                if (response.success === true) {
                    const adhoc_data = response.data.rows;
                    const filter_adhoc_data = []
                    adhoc_data.forEach((item) => {
                        const filter_stu_adhoc_data = item.adhoc_leave_details.filter(element => {
                            return date === moment(element.departure_date)?.tz(timezone)?.format('MMMM Do YYYY')
                        })
                        if (filter_stu_adhoc_data.length >= 1) {
                            filter_adhoc_data.push({ ...item, adhoc_leave_details: filter_stu_adhoc_data })
                        }
                    })
                    setTableData(pendingCheckInOut ? response.data.rows : filter_adhoc_data)

                } else {
                    toast.error(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            })

    }


    const UpdateAdhocLeaveSignInOrSignOut = (leave_data) => {
        setIsloading(true);
        props.onUpdateAdhocLeaveSignInOrSignOut(leave_data).then((response) => {
            if (response.success === true) {
                setIsloading(false);
                setConfirm_modal(false)
                GetAllAdhocLeaveStudentDetails()
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                setIsloading(false);
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })

    }

    const showModal = (leave, status) => {
        setStatus_data({ leave: leave, status: status })
        setConfirm_modal(true)
    }
    const modalHide = () => { setConfirm_modal(false) };

    // const pendingStatusData = () => {
    //     const payload = {
    //         page:null,
    //         limit:null,
    //         sort:null,
    //         order:null,
    //         search:null,
    //         from_date:null,
    //         to_date:null,
    //         class_id:null,
    //         dormitory_id:null,
    //         activity_id:null,
    //         pending:pendingCheckInOut
    //     }

    //     props.onGetAllAdhocLeaveStudentDetails(payload.page, payload.limit, payload.sort, payload.order, payload.search, payload.from_date, payload.to_date, payload.class_id,
    //         payload.dormitory_id, payload.activity_id, payload.pending).then((response) => {
    //         if (response.success === true) {
    //             const adhoc_data = response.data.rows;
    //             // const filter_adhoc_data = []
    //             // adhoc_data.forEach((item) => {
    //             //     console.log("adhoc_data", item);
    //             //     const filter_stu_adhoc_data = item.adhoc_leave_details.filter(element => {
    //             //         return date === moment(element.departure_date)?.tz(timezone)?.format('MMMM Do YYYY')
    //             //     })
    //             //     if (filter_stu_adhoc_data.length >= 1) {
    //             //         filter_adhoc_data.push({ ...item, adhoc_leave_details: filter_stu_adhoc_data })
    //             //     }
    //             // })
    //             // console.log("::::::::::::::::test".filter_adhoc_data)
    //             setTableData(adhoc_data)

    //         } else {
    //             toast.error(response.message, {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         }
    //     })
    // }

    // useEffect(() => {
    //     pendingStatusData();
    // },[pendingCheckInOut])

    const handlependingCheckInOut = (event, name) => {
        setPendingCheckInOut(event.target.checked)
    }

    return (
        <>
            {confirm_modal && (
                <AdhocLeaveSignInSignOutModal
                    confirm_modal={confirm_modal}
                    modalHide={() => modalHide()}
                    status_data={status_data}
                    UpdateAdhocLeaveSignInOrSignOu={(data) => UpdateAdhocLeaveSignInOrSignOut(data)}
                    isLoading={isLoading}
                />
            )}

            <div className="card">
                <div className="card-header">
                    <h2 className="card-title">
                        Adhoc Live
                    </h2>
                </div>
                <div className="d-flex justify-content-end align-items-center">
                    <label class="fw-bold" for="pending">
                        <input class="form-check-input me-2" type="checkbox"
                            onChange={(event) => handlependingCheckInOut(event, "pending")}
                            name="exampleRadios" id="pending"
                            value={pendingCheckInOut}
                            checked={pendingCheckInOut == true}
                        />
                        Pending Check In
                    </label>
                    <div className=" px-6 pt-4 text-right">
                        <div className="fw-bold text-primaryfont-medium text-center  d-inline-block border border-dashed border-gray-300 rounded  px-7 py-3 mb-4 gap-5 ">Today : {date}</div>
                    </div>
                </div>

                <div className="card-body px-5 py-0">
                    <div className="table-responsive">
                        <table className="table table-row-bordered">
                            <thead>
                                <tr>
                                    <th scope="col cursor-pointer" onClick={() => { setFilterBySort('student_first_name'); setFilterByorder((prev) => !prev) }}>
                                        Name
                                        <i className={`bi bi-filter cursor-pointer ${filterBySort === 'student_first_name' ? 'text-primary' : ''}`}></i>
                                    </th>
                                    <th scope="col" className="text-center">Planned Time <br />
                                        <span className="colDevide cursor-pointer" onClick={() => { setFilterBySort('start_time'); setFilterByorder((prev) => !prev) }}>
                                            Depart
                                            <i className={`bi bi-filter cursor-pointer ${filterBySort === 'start_time' ? 'text-primary' : ''}`} ></i>
                                        </span>
                                        <span className="colDevide cursor-pointer" onClick={() => { setFilterBySort('end_time'); setFilterByorder((prev) => !prev) }}>
                                            Return
                                            <i className={`bi bi-filter cursor-pointer ${filterBySort === 'end_time' ? 'text-primary' : ''}`} ></i>
                                        </span>
                                    </th>
                                    <th scope="col">Destination</th>
                                    <th scope="col">Reason</th>

                                    <th scope="col" className="text-center">Transport <br />
                                        <span className="colDevide">Out</span>
                                        <span className="colDevide">In</span>
                                    </th>

                                    <th scope="col" className="text-center">Actual Time <br />
                                        <span className="colDevide cursor-pointer"  onClick={() => { setFilterBySort('actual_start_time'); setFilterByorder((prev) => !prev) }}>
                                            Depart
                                            <i className={`bi bi-filter cursor-pointer ${filterBySort === 'actual_start_time' ? 'text-primary' : ''}`}></i>
                                        </span>
                                        <span className="colDevide cursor-pointer" onClick={() => { setFilterBySort('actual_end_time'); setFilterByorder((prev) => !prev) }}>
                                            Return
                                            <i className={`bi bi-filter cursor-pointer ${filterBySort === 'actual_end_time' ? 'text-primary' : ''}`}></i>
                                        </span>
                                    </th>
                                    <th scope="col">Check In/Out</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!!tableData.length ?
                                    tableData.map((data) => {
                                        return (
                                            <tr>
                                                <td scope="row">{data.adhoc_stu_data[0].student_first_name + " " + data.adhoc_stu_data[0].student_last_name}</td>

                                                <td className="text-center">
                                                    <span className="colDevide">{moment(data.adhoc_leave_details[0].start_time, "hh:mm:ss A").format("hh:mm A")}</span>
                                                    <span className="colDevide">{moment(data.adhoc_leave_details[0].end_time, "hh:mm:ss A").format("hh:mm A")}</span>
                                                </td>

                                                <td>{data.venue.charAt(0).toUpperCase() + data.venue.slice(1)}</td>
                                                <td>{data.adhoc_activity_data === null ? " N/A" : data.adhoc_activity_data.activity_name}</td>

                                                <td className="text-center">
                                                    <span className="colDevide">{data.depart_mode_data.travel_mode_name.charAt(0).toUpperCase() + data.depart_mode_data.travel_mode_name.slice(1)}</span>
                                                    <span className="colDevide">{data.arrival_mode_data.travel_mode_name.charAt(0).toUpperCase() + data.arrival_mode_data.travel_mode_name.slice(1)}</span>
                                                </td>

                                                <td className="text-center">
                                                    <span className="colDevide">{moment(data.adhoc_leave_details[0].actual_start_time, "hh:mm:ss A").format("hh:mm") == "Invalid date" ? "--" : moment(data.adhoc_leave_details[0].actual_start_time, "hh:mm:ss A").format("hh:mm A")}</span>
                                                    <span className="colDevide">{moment(data.adhoc_leave_details[0].actual_end_time, "hh:mm:ss A").format("hh:mm") == "Invalid date" ? "--" : moment(data.adhoc_leave_details[0].actual_end_time, "hh:mm:ss A").format("hh:mm A")}</span>
                                                </td>
                                                <td>
                                                    <button
                                                        className={data.adhoc_leave_details[0].check_in_out === "pending" ? "btn btn-sm btn-outline-warning  text-center text-capitalize" :
                                                            (data.adhoc_leave_details[0].check_in_out === "out" ? "btn btn-outline-danger btn-sm text-center text-capitalize" : "btn btn-outline-success btn-sm text-capitalize text-center")}
                                                        onClick={() => data.adhoc_leave_details[0].check_in_out !== "in" && showModal(data.adhoc_leave_details[0].adhoc_leave_uuid, data.adhoc_leave_details[0].check_in_out)}
                                                    >
                                                        {data.adhoc_leave_details[0].check_in_out === "in" ? "Back" : data.adhoc_leave_details[0].check_in_out}
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colspan="7" className="text-center pt-10 pb-6">
                                            <h6>Data Not Found!</h6>
                                        </td>
                                    </tr>

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}


function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = {
    onGetAllAdhocLeaveforSignOrSignOut: userActions.GetAllAdhocLeaveforSignOrSignOut,
    onUpdateAdhocLeaveSignInOrSignOut: userActions.UpdateAdhocLeaveSignInOrSignOut,
    onGetAllAdhocLeaveStudentDetails: userActions.GetAllAdhocLeaveStudentDetails,
};
export default connect(mapStateToProps, mapDispatchToProps)(Adhocreport);
