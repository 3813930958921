import React, { useCallback, useEffect, useRef, useState } from "react";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../../../actions/index";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import moment from "moment";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box } from "@mui/system";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import { Card, CardActions, CardContent, CardMedia, Grid, IconButton, Typography } from "@mui/material";
import { toast } from "react-toastify";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmRemove from "../../../Utility/DeleteConfirmRemove";
import FileImage from "./studentAssets/image-file.png"
import DownloadIcon from '@mui/icons-material/Download';
import Select from "react-select";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 2,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 2,
});

const customStyles = {
    container: (provided) => ({
        ...provided,
        width: '200px',
    }),
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const fileType = [
    { value: "", label: "All" },
    { value: "image", label: "Image" },
    { value: "document", label: "Document" }
]

const row_limit = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
    // { value: 250, label: 250 },
    // { value: 500, label: 500 }
]


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;

const FileStorage = (props) => {
    const [confirm_modal, setconfirm_modal] = useState(false);
    const [editconfirmremovedata, seteditconfirmremovedata] = useState([]);
    const [studentFileType, setStudentFileType] = useState();
    const [count, setCount] = useState();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const [data, setData] = useState({
        student_id: props?.studentData?.student_id,
        file: []
    })
    const [studentFileData, setStudentFileData] = useState([])
    const handleFileType = (value) => {
        setPage(1)
        setStudentFileType(value);
    }

    const onGetStudentFileStorage = useCallback(async () => {
        let file_type;
        props.onGetStudentFileStorage(props?.studentData?.student_id, page, limit, file_type = studentFileType, search).then((response) => {
            setCount(response?.data?.count)
            setStudentFileData(response?.data?.rows);

        })
    }, [props?.studentData?.student_id, studentFileType, page, limit, search])

    useEffect(() => {
        onGetStudentFileStorage();
    }, [onGetStudentFileStorage, studentFileType, page, limit, search])


    const handleFiles = async (event) => {
        const selectedFiles = event.target.files;
        const fileArray = Array.from(selectedFiles);

        // Check if any file exceeds the 5MB limit
        const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes
        const oversizedFiles = fileArray.filter(file => file.size > maxFileSize);

        if (oversizedFiles.length > 0) {
            toast.error("Each file must be less than 5MB.", {
                position: toast.POSITION.TOP_CENTER,
            });
            return;
        }

        // Use props?.studentData?.student_id directly to ensure student_id is included
        const updatedData = {
            student_id: data.student_id || props?.studentData?.student_id, // fall back to props if data.student_id is missing
            file: fileArray,
        };

        setData(updatedData);  // Update state with the selected files and student_id

        // Make the API call with updatedData
        await props.onCreateStudentFileStorage(updatedData).then((response) => {
            if (response.success === true) {
                onGetStudentFileStorage();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            } else {
                console.log("error");
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    const DeleteFile = (event, student_data) => {
        seteditconfirmremovedata(student_data);
        setconfirm_modal(true);
    };

    const showAddModalHandle = (event) => {
        setconfirm_modal(false);
    };

    const ConfirmRemove = (form_data) => {
        setconfirm_modal(false);
        props.onDeleteStudentFileStorage(form_data).then((response) => {
            if (response.success === true) {
                onGetStudentFileStorage();
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setconfirm_modal(false);
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        });
    };

    // const downloadImg = async (wallet_id) => {
    //     await props.onGetStudentFileDownload(wallet_id).then((response) => {
    //         if (response.success === true) {
    //             // onGetStudentFileStorage();
    //             toast.success(response.message, {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         } else {
    //             console.log("error");
    //             toast.error(response.message, {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         }
    //     });
    // }

    const downloadImage = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = imageUrl.split('/').pop();
            link.click();

            URL.revokeObjectURL(blobUrl);
        } catch (error) {
            console.error('Error downloading the image:', error);
        }
    };

    return (
        <>
            {confirm_modal && (
                <DeleteConfirmRemove
                    title={`Delete File ?`}
                    bodytitle="Are you sure you want to delete this file? This action is permanent and cannot be undone."
                    editconfirmremovedata={editconfirmremovedata}
                    confirm_modal={confirm_modal}
                    confirmButtonText="Delete"
                    ConfirmRemove={(event) => ConfirmRemove(event)}
                    delete_confirm_modal_action={(modal_action) => showAddModalHandle(modal_action)}
                />
            )}
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <Sidebar />
                        <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">
                                        <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist" style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                                            <div style={{ display: "flex", overflowX: "scroll" }}>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/student-profile/${props.user_uuid}`} className="nav-link" id="StudentProfile-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="home" aria-selected="true">
                                                        Student Profile
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/approved-host/${props.user_uuid}`} className="nav-link" id="ApprovedHosts-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="true">
                                                        Approved Hosts
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/leave-history/${props.user_uuid}`} className="nav-link" id="LeaveHistory-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Leave History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/attendance-history/${props.user_uuid}`} className="nav-link" id="AttendanceHistory-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Attendance History
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/pastoral-notes/${props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Pastoral Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/medical-notes/${props.user_uuid}`} className="nav-link" id="MedicalNotes-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Medical Notes
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/allergy-reports/${props.user_uuid}`} className="nav-link" id="AllergyReport-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Allergy Reports
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-leave/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        AdhocLeave
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/adhoc-actual/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Adhoc Actual
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/file-storage/${props.user_uuid}`} className="nav-link active" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        File Storage
                                                    </Link>
                                                </li>
                                                <li className="nav-item me-6" role="presentation">
                                                    <Link to={`/${school_code}/student/student-list/student-activities/${props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}} aria-controls="process" aria-selected="false">
                                                        Student Activities
                                                    </Link>
                                                </li>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <b> {props?.studentData?.student_first_name ? props?.studentData?.student_first_name + " " + props?.studentData?.student_last_name : "BSS"}</b>
                                                <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                    <img alt="Logo" style={{ marginBottom: "4px" }}
                                                        src={
                                                            props?.studentData?.student_avatar !== null && props?.studentData?.student_avatar !== ""
                                                                ? "https://bssnew.dikonia.in/" + props?.studentData?.student_avatar
                                                                : (props?.studentData?.gender === "male"
                                                                    ? "https://bssnew.dikonia.in/media/avatars/boy.jpg"
                                                                    : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </ul>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card mb-5 tabs-outer">
                                                    <div className="card-header position-relative py-0 details-bg">
                                                        {/* <!--begin::Card title--> */}
                                                        <h2 className="card-title m-0">File Storage</h2>
                                                    </div>
                                                    <div className="card-body">

                                                        <Box sx={{ display: "flex", justifyContent: 'center', alignItems: "center", flexDirection: "column", border: "2px dotted #424549", padding: "20px 0" }}>
                                                            <div className="img-fluid">
                                                                <CloudUploadIcon sx={{ fontSize: "200px", color: "#E4E6EF" }} />
                                                            </div>
                                                            <Button
                                                                component="label"
                                                                role={undefined}
                                                                variant="contained"
                                                                tabIndex={-1}
                                                                startIcon={<CloudUploadIcon />}
                                                                sx={{ width: "200px", height: "50px" }}
                                                            >
                                                                Upload assets
                                                                <VisuallyHiddenInput
                                                                    type="file"
                                                                    onChange={(event) => handleFiles(event)}
                                                                    multiple
                                                                    accept=".pdf, .doc, .docx, .png, .jpg, .jpeg"
                                                                />
                                                            </Button>
                                                            <div className="text-center mt-5">
                                                                <span className="font-weight-bold rounded ">The maximum file size is 5MB. You can upload documents, PDFs, or images in PNG/JPG/JPEG formats.</span>
                                                            </div>
                                                        </Box>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* for card */}
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="card mb-5 tabs-outer">
                                                    <div className="card-header position-relative py-0 details-bg">
                                                        {/* <!--begin::Card title--> */}
                                                        <h2 className="card-title  m-0">Files</h2>
                                                        <div className="d-flex align-items-center m-0 gap-3">
                                                            {/* <label className="fw-bold mb-2 ">Manager Name</label> */}
                                                            <Select
                                                                styles={customStyles}
                                                                className="react-bootstrap-typeahead tag-outer "
                                                                options={fileType}
                                                                placeholder={"Select file type"}
                                                                // value={studentFileType}
                                                                onChange={(event) => handleFileType(event.value)}
                                                            />
                                                            <div className="d-flex align-items-center position-relative ">
                                                                <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                                                        <path
                                                                            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                                                            fill="currentColor"
                                                                        ></path>
                                                                    </svg>
                                                                </span>

                                                                <input
                                                                    type="text"
                                                                    onChange={(e) => { setSearch(e.target.value); setPage(1) }}
                                                                    // value={searchComment}
                                                                    className="form-control form-control-sm w-350px ps-12 search-out fs-6"
                                                                    placeholder="Search "
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <Grid container spacing={2} gap={"20px"}>
                                                            {
                                                                studentFileData?.length > 0 ? studentFileData?.map((item, index) => {
                                                                    const imageUrl = item.files ? `https://bssnew.dikonia.in/${item.files}` : '';
                                                                    return <Grid xs={3} key={item.student_id} sx={{ marginTop: "20px" }} style={{ maxWidth: "200px" }}>
                                                                        <Card sx={{ position: "relative" }}>
                                                                            {
                                                                                item?.file_type === "document" ?
                                                                                    <NavLink to={imageUrl}>
                                                                                        <CardMedia
                                                                                            component="img"
                                                                                            image={FileImage}
                                                                                            alt="Paella dish"
                                                                                            sx={{ cursor: 'pointer', height: "190px" }}
                                                                                        />
                                                                                    </NavLink>
                                                                                    :
                                                                                    <div onClick={() => downloadImage(imageUrl)} style={{ cursor: 'pointer' }}>
                                                                                        <CardMedia
                                                                                            component="img"
                                                                                            image={item.files !== null && item.files !== "" ? imageUrl : ""}
                                                                                            alt="Paella dish"
                                                                                            sx={{ cursor: 'pointer', height: "190px" }}
                                                                                        />
                                                                                    </div>
                                                                            }
                                                                            <CardActions disableSpacing sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
                                                                                <span>{item.file_name && item.file_name.length > 20 ? item.file_name.slice(0, 20) + "..." : item.file_name}</span>
                                                                                <Box sx={{ position: "absolute", top: "0", right: "0" }}>
                                                                                    <IconButton aria-label="add to favorites">
                                                                                        <DeleteIcon sx={{ fontSize: "20px" }} color="error" onClick={(e) => DeleteFile(e, item)} />
                                                                                    </IconButton>
                                                                                    {
                                                                                        item?.file_type === "document" ?
                                                                                            <a href={imageUrl} download style={{ textDecoration: 'none' }}>
                                                                                                <IconButton aria-label="add to favorites">
                                                                                                    <DownloadIcon sx={{ fontSize: "20px" }} color="primary" />
                                                                                                </IconButton>
                                                                                            </a>
                                                                                            :

                                                                                            <IconButton aria-label="add to favorites" onClick={() => downloadImage(imageUrl)} >
                                                                                                <DownloadIcon sx={{ fontSize: "20px" }} color="primary" />
                                                                                            </IconButton>
                                                                                    }
                                                                                </Box>
                                                                            </CardActions>
                                                                        </Card>
                                                                    </Grid>
                                                                }) : <Grid xs={12} width={"100%"} className="my-5"><h4 className='text-center text-muted'>No files found. <br /> Please add some to begin.</h4></Grid>
                                                            }
                                                            {
                                                                studentFileData?.length > 0 &&
                                                                <Grid xs={12}>
                                                                    <div className="d-flex justify-content-between mt-4">
                                                                        <Select
                                                                            menuPlacement="top"
                                                                            className="basic-single"
                                                                            classNamePrefix="select"
                                                                            defaultValue={row_limit[0]}
                                                                            name="color"
                                                                            options={row_limit}
                                                                            onChange={(event) => { setLimit(event.value); setPage(1) }}
                                                                        />
                                                                        <Stack spacing={1} >
                                                                            <Pagination
                                                                                count={Math.ceil(count / limit)}
                                                                                page={page}
                                                                                onChange={(event, value) => setPage(value)}
                                                                                color="primary"
                                                                            />
                                                                        </Stack>
                                                                    </div>
                                                                </Grid>
                                                            }
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
    onGetAllAdhocLeaveByStudentId: userActions.GetAllAdhocLeaveByStudentId,
    onCreateStudentFileStorage: userActions.CreateStudentFileStorage,
    onGetStudentFileStorage: userActions.GetStudentFileStorage,
    onGetStudentFileDownload: userActions.GetStudentFileDownload,
    onDeleteStudentFileStorage: userActions.DeleteStudentFileStorage
};
export default connect(mapStateToProps, mapDispatchToProps)(FileStorage);
