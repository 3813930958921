import React from "react";
import Select from "react-select";
import Validations from "../../Utility/Validations";
import Sidebar from "../../Constant/sidebar";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        "&:hover": {
            backgroundColor: state.isFocused ? "#e7f5fe" : "",
        },
        backgroundColor: state.isSelected ? "#4a8fb8" : "",
    }),
};
const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
class AddAllergicStudent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            student_search_form: {
                class_id: {
                    type: "select",
                    label: "Highest class *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "City",
                    validations: { required: false },
                    options: [],
                },

                dormitory_id: {
                    type: "select",
                    label: "Select Dormitory *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Select Dormitory *",
                    validations: { required: false },
                    options: [],
                },

                gender: {
                    type: "radio",
                    label: "Gender * ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },

                student_age: {
                    type: "select",
                    label: "Select Age *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                    options: [
                        { label: "Select Age", value: "" },
                        { label: "7", value: "7" },
                        { label: "8", value: "8" },
                        { label: "9", value: "9" },
                        { label: "10", value: "10" },
                        { label: "11", value: "11" },
                        { label: "12", value: "12" },
                        { label: "13", value: "13" },
                        { label: "14", value: "14" },
                        { label: "15", value: "15" },
                        { label: "16", value: "16" },
                        { label: "17", value: "17" },
                        { label: "18", value: "18" },
                        { label: "19", value: "19" },
                        { label: "20", value: "20" },
                        { label: "21", value: "21" },
                        { label: "22", value: "22" },
                        { label: "23", value: "23" },
                        { label: "24", value: "24" },
                        { label: "25", value: "25" },
                        { label: "26", value: "26" },
                        { label: "27", value: "27" },
                        { label: "28", value: "28" },
                        { label: "29", value: "29" },
                        { label: "30", value: "30" },
                    ]
                },
            },
            add_allergic_student_form: {
                student_name: {
                    type: "select",
                    label: "Student Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    student_id: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },
                update_allergy_data: [],
                allergy_data: [
                    {
                        allergy_name: {
                            type: "text",
                            label: "Allergy Name",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Allergy Name",
                            validations: { required: true },
                        },
                        allergy_info: {
                            type: "text",
                            label: "Allergy Info",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Treatment",
                            validations: { required: true },
                        },
                        allergy_note: {
                            type: "text",
                            label: "Allergy Note",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Allergy Note",
                            validations: { required: false },
                        },
                    }
                ],
                student_id: {
                    type: "text",
                    label: "student id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
            },
            row: [
                {
                    id: 0,
                    name: "",
                    value: ""
                },
            ],
        };


    }

    componentDidMount() {
        this.GetAllDormitoryStudent();
        this.GetAllClassesDetailsStudent();
        this.GetAllNonAllergicStudentsList();
        this.GetLoginUserPermissions();
    }

    GetAllNonAllergicStudentsList = async () => {
        const search_data = { ...this.state.student_search_form }
        this.props.onGetAllNonAllergicStudentsList(search_data.class_id.value, search_data.dormitory_id.value, search_data.gender.value, search_data.student_age.value).then((response) => {
            let country_level = [];
            const update_add_allergic_student_form = { ...this.state.add_allergic_student_form };
            if (response.success === true) {
                const arrayData = response.data;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].student_first_name + " " + arrayData[key].student_last_name,
                        value: arrayData[key].student_id,
                    });
                }
            }
            update_add_allergic_student_form['student_name'].options = country_level;
            this.setState({ add_allergic_student_form: update_add_allergic_student_form });
        });
    }

    GetLoginUserPermissions() {
        // this.props.onGetLoginUserPermissions().then((response) => {
        //   if (response.success === true) {
        //   } else {
        //   }
        // });
    }


    clickAddAllergy() {
        const update_add_allergic_student_form = { ...this.state.add_allergic_student_form };
        const update_row = update_add_allergic_student_form.allergy_data;
        update_row.push(
            {
                allergy_name: {
                    type: "text",
                    label: "Allergy Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Allergy Name",
                    validations: { required: true },
                },
                allergy_info: {
                    type: "text",
                    label: "Allergy Info",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Treatment",
                    validations: { required: true },
                },
                allergy_note: {
                    type: "text",
                    label: "Allergy Note",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Allergy Note",
                    validations: { required: false },
                },
            }
        )
        this.setState({ allergy_data: update_row })
    }
    clickDeleteAllergyrow = (index, e) => {
        const update_add_allergic_student_form = { ...this.state.add_allergic_student_form };
        const deleteAllergyData = this.state.add_allergic_student_form.allergy_data;
        let edit_row = deleteAllergyData.filter((i) => i !== index);
        update_add_allergic_student_form['allergy_data'] = edit_row;
        this.setState({ add_allergic_student_form: update_add_allergic_student_form });
    };

    handleChange(data, identifier, type) {
        const update_add_allergic_student_form = { ...this.state.add_allergic_student_form };

        // For multi-select, data will be an array of selected options
        const selectedLabels = data.map(option => option.label); // Extract labels
        const selectedValues = data.map(option => option.value); // Extract IDs

        update_add_allergic_student_form[identifier].value = selectedLabels; // Update labels in the state
        update_add_allergic_student_form["student_id"].value = selectedValues; // Update IDs in the state

        this.setState({ add_allergic_student_form: update_add_allergic_student_form });
        // this.props.onGetAllergicStudentDetailById(student_uuid).then((response) => {
        //     const update_add_allergic_student_form = { ...this.state.add_allergic_student_form };
        //     if (response.success === true) {
        //         update_add_allergic_student_form["student_id"].value = response.data.student_id;
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        //     this.setState({ add_allergic_student_form: update_add_allergic_student_form });
        // });
    }

    inputChangeAllergyName(event, i,) {
        if (event.target.value.length <= 35) {
            let setName = this.state.add_allergic_student_form.allergy_data[i];
            setName["allergy_name"].value = event.target.value
            this.setState({ rowname: setName.allergy_name })
        }
    }

    inputChangeAllergyInfo(event, i) {
        if (event.target.value.length <= 70) {
            let setName = this.state.add_allergic_student_form.allergy_data[i];
            setName["allergy_info"].value = event.target.value
            this.setState({ rowname: setName.allergy_info })
        }
    }

    inputChangeAdditional_note(event, i) {
        if (event.target.value.length <= 500) {
            let setName = this.state.add_allergic_student_form.allergy_data[i];
            setName["allergy_note"].value = event.target.value
            this.setState({ rowname: setName.allergy_note })
        }
    }


    SubmitHandler = async (event) => {
        event.preventDefault();
    let is_form_valid = true;
    const update_add_allergic_student_form = { ...this.state.add_allergic_student_form };

    // Validate the `student_name` field
    for (let key in update_add_allergic_student_form) {
        let form_element = update_add_allergic_student_form["student_name"];
        let validate_element = Validations(form_element);
        is_form_valid = validate_element.valid === false ? false : is_form_valid;
        form_element.onBlur_out = true;
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;
        update_add_allergic_student_form["student_name"] = form_element;
    }

    // Validate the `allergy_data` fields
    const allergy_array = update_add_allergic_student_form.allergy_data;
    allergy_array.forEach((element) => {
        for (let key in element) {
            let form_element = element[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_add_allergic_student_form[key] = form_element;
        }
    });

    // Update the state after validation
    this.setState({ add_allergic_student_form: update_add_allergic_student_form });

    if (is_form_valid) {
        const form_data = {};
        const oldArray = update_add_allergic_student_form.allergy_data;
        const student_ids = update_add_allergic_student_form.student_id.value; // This is the array of `student_id`s

        // Transform the data into the desired structure
        const update_allergy_data = oldArray.flatMap((item) => {
            return student_ids.map((student_id) => ({
                allergy_name: item.allergy_name.value,
                allergy_info: item.allergy_info.value,
                allergy_note: item.allergy_note.value,
                student_id: student_id, // Each student_id is mapped to a separate object
            }));
        });

        form_data["allergy_data"] = JSON.stringify(update_allergy_data);
        form_data["student_name"] = update_add_allergic_student_form.student_name.value;

        console.log("formdata:::", form_data);
        // Perform your API call or further processing with `form_data`
        // this.props.onAddStudentNewAllergyDetails(form_data).then((response) => {
        //     if (response.success === true) {
        //         toast.success(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //         setTimeout(() => {
        //             this.setState({
        //                 isAuthenticated: true,
        //                 redirect_url: `/${school_code}/student/allergic-student`,
        //             });
        //         }, 1000);
        //     } else {
        //         toast.error(response.message, {
        //             position: toast.POSITION.TOP_CENTER,
        //         });
        //     }
        // });


        // event.preventDefault();
        // let is_form_valid = true;
        // const update_add_allergic_student_form = this.state.add_allergic_student_form;
        // for (let key in update_add_allergic_student_form) {
        //     let form_element = update_add_allergic_student_form['student_name'];
        //     let validate_element = Validations(form_element);
        //     is_form_valid = validate_element.valid === false ? false : is_form_valid;
        //     form_element.onBlur_out = true;
        //     form_element.valid = validate_element.valid;
        //     form_element.error_msg = validate_element.error_msg;
        //     update_add_allergic_student_form['student_name'] = form_element;
        // }
        // const allergy_array = update_add_allergic_student_form.allergy_data
        // allergy_array.forEach((element) => {
        //     for (let key in element) {
        //         let form_element = element[key];
        //         let validate_element = Validations(form_element);
        //         is_form_valid = validate_element.valid === false ? false : is_form_valid;
        //         form_element.onBlur_out = true;
        //         form_element.valid = validate_element.valid;
        //         form_element.error_msg = validate_element.error_msg;
        //         update_add_allergic_student_form[key] = form_element;
        //     }
        // })


        // this.setState({ add_allergic_student_form: update_add_allergic_student_form });
        // if (is_form_valid) {
        //     const form_data = {};
        //     let oldArray = update_add_allergic_student_form.allergy_data;
        //     let update_allergy_data = oldArray.map((item, index) => {
        //         return ({
        //             allergy_name: item.allergy_name.value,
        //             allergy_info: item.allergy_info.value,
        //             allergy_note: item.allergy_note.value,
        //             student_id: update_add_allergic_student_form.student_id.value,
        //         })
        //     }
        //     )
        //     form_data['update_allergy_data'] = JSON.stringify(update_add_allergic_student_form.update_allergy_data);
        //     form_data['allergy_data'] = JSON.stringify(update_allergy_data);
        //     form_data['student_name'] = update_add_allergic_student_form.student_name.value;
        //     console.log("formdata:::",form_data);
            
            // this.props.onAddStudentNewAllergyDetails(form_data).then((response) => {
            //     if (response.success === true) {
            //         toast.success(response.message, {
            //             position: toast.POSITION.TOP_CENTER,
            //         });
            //         setTimeout(() => {
            //             this.setState({
            //                 isAuthenticated: true,
            //                 redirect_url: `/${school_code}/student/allergic-student`,
            //             });
            //         }, 1000);
            //     } else {
            //         toast.error(response.message, {
            //             position: toast.POSITION.TOP_CENTER,
            //         });
            //     }
            // });
        }
    };
    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_student_search_form = { ...this.state.student_search_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                    });
                }
            }
            update_student_search_form['dormitory_id'].options = country_level;
            this.setState({ student_search_form: update_student_search_form });
        });
    }

    GetAllClassesDetailsStudent = async (form_data) => {
        this.props.onGetAllClassesDetailsStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
            });
            const update_student_search_form = { ...this.state.student_search_form };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_id,
                        class_name: arrayData[key].class_name,

                    });
                }
            }
            update_student_search_form['class_id'].options = country_level;
            this.setState({ student_search_form: update_student_search_form });
        });
    }
    handleChangeSearch(data, identifier, type) {
        const update_student_search_form = { ...this.state.student_search_form };
        if (identifier === 'class_id') {
            update_student_search_form[identifier].value = data.value;
            this.GetAllNonAllergicStudentsList(data.value);
        }
        if (identifier === 'dormitory_id') {
            update_student_search_form[identifier].value = data.value;
            this.GetAllNonAllergicStudentsList(data.value);
        }
        if (identifier === 'student_age') {
            update_student_search_form[identifier].value = data.value;
            this.GetAllNonAllergicStudentsList(data.value);
        }
        if (identifier === 'gender') {
            update_student_search_form[identifier].value = data.target.value;
            this.GetAllNonAllergicStudentsList(data.target.value);
        }
        // update_student_search_form[identifier].value = data.value;
        this.setState({ student_search_form: update_student_search_form });
    }
    render() {
        const { add_allergic_student_form } = this.state;
        const { student_search_form } = this.state;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}

                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed">
                    <div className="d-flex flex-column flex-root">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar />
                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar />
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <div className="school-edit-form">
                                                <div className="" id="Snapshot">


                                                    <h2 className="card-title  mt-0 mb-5">Add Allergic Students</h2>
                                                    <div className="row">
                                                        <div className="col-sm-8">
                                                            <form onSubmit={this.SubmitHandler}>
                                                                <div className="card tabs-outer">
                                                                    <div className="card-header position-relative py-0 details-bg">
                                                                        <h2 className="card-title  m-0">Student Details</h2>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row">
                                                                            <div className="col-lg-5 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{add_allergic_student_form.student_name.label}</label>
                                                                                {/* <Select
                                                                                        isMulti
                                                                                        placeholder="Select Student . . ."
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={add_allergic_student_form.student_name.options}
                                                                                        value={add_allergic_student_form.student_name.options.filter(function (option) {
                                                                                            return option.label === add_allergic_student_form.student_name.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChange(newValue, "student_name")}
                                                                                    /> */}
                                                                                <Select
                                                                                    isMulti
                                                                                    placeholder="Select Student . . ."
                                                                                    styles={customStyles}
                                                                                    className="react-bootstrap-typeahead tag-outer"
                                                                                    options={add_allergic_student_form.student_name.options}
                                                                                    value={add_allergic_student_form.student_name.options.filter(option =>
                                                                                        add_allergic_student_form.student_name.value.includes(option.label) // Match labels for multi-select
                                                                                    )}
                                                                                    onChange={(newValue) => this.handleChange(newValue, "student_name")}
                                                                                />
                                                                                {!add_allergic_student_form.student_name.valid && add_allergic_student_form.student_name.onBlur_out ? (
                                                                                    <div className="error field-error">{add_allergic_student_form.student_name.error_msg}</div>
                                                                                ) : (
                                                                                    ""
                                                                                )}
                                                                            </div>
                                                                            <div className="fw-bold">
                                                                                <label className="fw-bold mb-2">Allergy *</label>
                                                                                {this.state.add_allergic_student_form.allergy_data.map((index, i) => {
                                                                                    return (
                                                                                        <div className="row" key={index.id}>
                                                                                            <div className="col-12">
                                                                                                <div className="row">
                                                                                                    <div className="col-sm-5">
                                                                                                        <input
                                                                                                            type={index.allergy_name.type}
                                                                                                            className="form-control mb-2"
                                                                                                            placeholder={index.allergy_name.placeholder}
                                                                                                            value={index.allergy_name.value}
                                                                                                            onChange={(event) => this.inputChangeAllergyName(event, i)}
                                                                                                        />
                                                                                                        <span className="countLength">Max 35 Characters: {index.allergy_name.value?.length}</span>
                                                                                                        {!index.valid && index.allergy_name.onBlur_out ? (
                                                                                                            <div className="error field-error">{index.allergy_name.error_msg}</div>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-sm-5">
                                                                                                        <input
                                                                                                            type={index.allergy_info.type}
                                                                                                            className="form-control mb-2"
                                                                                                            placeholder={index.allergy_info.placeholder}
                                                                                                            value={index.allergy_info.value}
                                                                                                            onChange={(event) => this.inputChangeAllergyInfo(event, i)}
                                                                                                        />
                                                                                                        <span className="countLength">Max 70 Characters: {index.allergy_info.value?.length}</span>
                                                                                                        {!index.allergy_info.valid && index.allergy_info.onBlur_out ? (
                                                                                                            <div className="error field-error">{index.allergy_info.error_msg}</div>
                                                                                                        ) : (
                                                                                                            ""
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-sm-2">
                                                                                                        <div className="d-flex gap-2 flex-shrink-0">
                                                                                                            {add_allergic_student_form.allergy_data.length === i + 1 ?
                                                                                                                <span
                                                                                                                    className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm pointer"
                                                                                                                    data-bs-toggle="tooltip"
                                                                                                                    title="Add"
                                                                                                                    data-bs-original-title="Add"
                                                                                                                    aria-describedby="tooltip230873"
                                                                                                                    onClick={(i) => this.clickAddAllergy(this.state.row.id)}
                                                                                                                >
                                                                                                                    <span className="svg-icon svg-icon-3 pointer">
                                                                                                                        <i className="fas fa-plus"></i>
                                                                                                                    </span>
                                                                                                                </span> : null
                                                                                                            }
                                                                                                            {add_allergic_student_form.allergy_data.length > 1 ?

                                                                                                                <span className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                                                                                                                    data-bs-toggle="tooltip" title="Delete" data-bs-original-title="Delete"
                                                                                                                    onClick={(e) => this.clickDeleteAllergyrow(index, e)}
                                                                                                                >
                                                                                                                    <span className="svg-icon svg-icon-3">
                                                                                                                        <i className="far fa-trash-alt"></i>
                                                                                                                    </span>
                                                                                                                </span> : null
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                            <div className="col-lg-12 mt-5 mb-5">
                                                                                                <label className="fw-bold mb-2">Additional Notes</label>
                                                                                                <textarea placeholder={"Max 500 Characters"}
                                                                                                    rows="2"
                                                                                                    className="form-control notes-main"
                                                                                                    value={index.allergy_note.value}
                                                                                                    onChange={(event) => this.inputChangeAdditional_note(event, i)} />
                                                                                            </div>
                                                                                            {!index.allergy_note.valid && index.allergy_note.onBlur_out ? (
                                                                                                <div className="error field-error">{index.allergy_note.error_msg}</div>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}

                                                                                        </div>
                                                                                    );
                                                                                })
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-sm-12 d-flex justify-content-end">
                                                                            <div className="d-flex align-items-end">
                                                                                <button type="submit" className="btn btn-sm btn-primary apply-btn min-150px">
                                                                                    Save
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <div className="card ">
                                                                <div className="card  tabs-outer">
                                                                    <form onSubmit={this.SubmitHandler} id="search-form" >
                                                                        <div className="card-header border-bottom border-bottom-1 align-items-center">
                                                                            <h2 className="card-title align-items-start flex-column">
                                                                                <span className="card-label fw-bold text-dark">Student Search</span>
                                                                            </h2>
                                                                            <button type="reset" className="clear-btn fs-6 pointer btn btn-link btn-color-info btn-active-color-primary"
                                                                                onClick={(event) => {
                                                                                    student_search_form["class_id"].value = "";
                                                                                    student_search_form["dormitory_id"].value = "";
                                                                                    student_search_form["gender"].value = "";
                                                                                    student_search_form["student_age"].value = "";
                                                                                    this.setState({ student_search_form })
                                                                                }}
                                                                            >Clear Search</button>
                                                                        </div>
                                                                        <div className="card-body add-new-schools p-6">
                                                                            <div className="row">
                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{student_search_form.class_id.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={student_search_form.class_id.options}
                                                                                        value={student_search_form.class_id.options.filter(function (option) {
                                                                                            return option.value === student_search_form.class_id.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChangeSearch(newValue, "class_id")}
                                                                                    />
                                                                                    {!student_search_form.class_id.valid && student_search_form.class_id.onBlur_out ? <div className="error field-error">{student_search_form.class_id.error_msg}</div> : ""}
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">{student_search_form.dormitory_id.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={student_search_form.dormitory_id.options}
                                                                                        value={student_search_form.dormitory_id.options.filter(function (option) {
                                                                                            return option.value === student_search_form.dormitory_id.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChangeSearch(newValue, "dormitory_id")}
                                                                                    />
                                                                                    {!student_search_form.dormitory_id.valid && student_search_form.dormitory_id.onBlur_out ? <div className="error field-error">{student_search_form.dormitory_id.error_msg}</div> : ""}
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row mb-5">
                                                                                    <label className="fw-bold mb-2">Gender</label>
                                                                                    <div className="d-flex">
                                                                                        <label className="cursor-pointer">
                                                                                            <input type="radio" id="gender" name="gen" className="form-check-input me-2 cursor-pointer" value="male" onChange={(event) => this.handleChangeSearch(event, "gender")} />
                                                                                            Male
                                                                                        </label>

                                                                                        <label className="ms-8 cursor-pointer">
                                                                                            <input type="radio" id="gender1" name="gen" className="form-check-input me-2 cursor-pointer" value="female" onChange={(event) => this.handleChangeSearch(event, "gender")} />
                                                                                            Female
                                                                                        </label>

                                                                                        <label className="ms-8 cursor-pointer">
                                                                                            <input type="radio" id="gender2" name="gen" className="form-check-input me-2 cursor-pointer" value="all" onChange={(event) => this.handleChangeSearch(event, "gender")} defaultChecked />
                                                                                            All
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-12 fv-row">
                                                                                    <label className="fw-bold mb-2">{student_search_form.student_age.label}</label>
                                                                                    <Select
                                                                                        styles={customStyles}
                                                                                        className="react-bootstrap-typeahead tag-outer"
                                                                                        options={student_search_form.student_age.options}
                                                                                        value={student_search_form.student_age.options.filter(function (option) {
                                                                                            return option.value === student_search_form.student_age.value;
                                                                                        })}
                                                                                        onChange={(newValue) => this.handleChangeSearch(newValue, "student_age")}
                                                                                    />
                                                                                    {!student_search_form.student_age.valid && student_search_form.student_age.onBlur_out ? <div className="error field-error">{student_search_form.student_age.error_msg}</div> : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {
    onAddStudentNewAllergyDetails: userActions.AddStudentNewAllergyDetails,
    onGetAllStudentsAllergy: userActions.GetAllStudentsAllergy,
    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllClassesDetailsStudent: userActions.GetAllClassesDetailsStudent,
    onGetAllergicStudentDetailById: userActions.GetAllergicStudentDetailById,
    onDeleteStudentAllergyById: userActions.DeleteStudentAllergyById,
    onGetAllNonAllergicStudentsList: userActions.GetAllNonAllergicStudentsList
};
export default connect(mapStateToProps, mapDispatchToProps)(AddAllergicStudent);
