// import packages
import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import moment from "moment";
// import files
import * as userActions from '../../../actions/index';
import Validations from "../../Utility/Validations";
import Footer from "../../Constant/Footer";
import TopBar from "../../Constant/TopBar";
import SideBarLeave from "../../Constant/SideBarLeave";
import * as actionType from "../../../constants/actionTypes";
import ReactPhoneInput from 'react-phone-input-2'
import { Link } from "react-router-dom";
import Select from 'react-select';
import { Tooltip } from "@mui/material";
import EditBoaderLeaveDepartureTime from '../UniversalComponent/EditBoaderLeaveDepartureTime';
import EditBoarderLeaveReturnTime from '../UniversalComponent/EditBoarderLeaveReturnTime';
import EditBorderLeaveModal from '../LeaveModals/EditBorderLeaveModal';
import CapitalFirstLetter from '../../Utility/CapitalFirstLetter';

const customStyles = {
   option: (provided, state) => ({
      ...provided,
      "&:hover": {
         backgroundColor: state.isFocused ? "#e7f5fe" : "",
      },
      backgroundColor: state.isSelected ? "#4a8fb8" : "",
   }),
};
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;

const EditBoardersLeave = (props) => {
   console.log(props, "::Vpropspropsprops")
   const navigate = useNavigate()
   const dispatch = useDispatch();
   const { border_leave_id } = useParams();
   const textareaRefHost = useRef(null);
   const textareaRefParent = useRef(null);
   const textareaRefStudent = useRef(null);
   const textareaRefBoarding = useRef(null);
   const textareaRefNotes = useRef(null);
   // states
   const [showEditLeaveModal, setShowEditLeaveModal] = useState(false)
   const [leaveData, setLeaveData] = useState({})

   const [border_leave_data, setBorderLeaveData] = useState('')
   const [hostEdit, setHostEdit] = useState(true)
   const [leaveEdit, setLeaveEdit] = useState(true)
   const [travelModeList, setTravelModeList] = useState([])
   const [border_leave_edit_form, setBorderLeaveEditForm] = useState({
      is_host_approval: {
         value: ""
      },
      host_approved_comments: {
         value: ""
      },
      host_rejected_comments: {
         value: ""
      },
      departure_date: {
         type: "date",
         label: "Departure Date",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Departure Date",
         validations: { required: true },
      },
      departure_time: {
         type: "time",
         label: "Departure Time",
         value: '',
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Departure Time",
         validations: { required: true },
      },
      departure_mode_id: {
         type: "hidden",
         label: "Modes Of Travel For Departure",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      departure_mode_name: {
         type: "hidden",
         label: "Modes Of Travel For Departure",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      return_date: {
         type: "date",
         label: "Return Date",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Return Date",
         validations: { required: true },
      },
      return_time: {
         type: "time",
         label: "Return Time",
         value: '',
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Select Return Time",
         validations: { required: true },
      },
      return_mode_id: {
         type: "hidden",
         label: "Modes Of Travel For Return ",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      return_mode_name: {
         type: "hidden",
         label: "Modes Of Travel For Return ",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: true },
         options: []
      },
      remarks_by_host: {
         type: "text",
         label: "Remarks By Host",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Leave Comment Here",
         validations: { required: false },
      },
      remarks_by_parent: {
         type: "text",
         label: "Remarks By Parent",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Leave Comment Here",
         validations: { required: false },
      },
      remarks_by_student: {
         type: "text",
         label: "Remarks By Student",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Leave Comment Here",
         validations: { required: false },
      },
      remarks_by_boarding: {
         type: "text",
         label: "Essential Comment By Boarding",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "Leave Comment Here",
         validations: { required: true, min_length: 10 },
      },
      additional_notes: {
         type: "text",
         label: "Additional Notes",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         placeholder: "",
         validations: { required: false },
      },
      border_leave_id: {
         type: "text",
         label: "Border Id",
         value: "",
         valid: true,
         error_msg: "",
         onBlur_out: false,
         validations: { required: false },
      },


   });

   useEffect(() => {
      GetBorderLeavesDetailById();
      GetAllTravelModes();
   }, []);

   const GetBorderLeavesDetailById = async () => {
      try {
         const response = await dispatch(userActions.GetBorderLeavesDetailById(border_leave_id));
         if (response.success) {
            const leave_data = response.data;
            console.log(leave_data, "::leave_data")
            setBorderLeaveData(leave_data);
            const updatedFormFields = { ...border_leave_edit_form };
            for (const key in updatedFormFields) {
               if (key === "return_mode_name") {
                  updatedFormFields[key].value = leave_data.return_mode_data.travel_mode_name;
               } else if (key === "departure_mode_name") {
                  updatedFormFields[key].value = leave_data.departure_mode_data.travel_mode_name;
               } else if (key === "departure_date" || key === "return_date") {
                  updatedFormFields[key].value = leave_data[key];
                  updatedFormFields[key].placeholder = moment(leave_data[key]).format("DD/MM/YYYY");
               } else if (key === "departure_time" || key === "return_time") {
                  updatedFormFields[key].value = leave_data[key];
                  updatedFormFields[key].placeholder = leave_data[key];
               } else if (key === "is_host_approval") {
                  updatedFormFields[key].value = leave_data[key];
               } else if (key === "host_approved_comments") {
                  updatedFormFields[key].value = leave_data[key];
               } else if (key === "host_rejected_comments") {
                  updatedFormFields[key].value = leave_data[key];
               }
               else {
                  updatedFormFields[key].value = leave_data[key];
               }

            }
            setBorderLeaveEditForm(updatedFormFields);
         }
      } catch (error) {
      }
   };



   const GetAllTravelModes = async () => {
      props.onGetAllTravelModes().then((response) => {
         let country_level = [];
         country_level.push({
            label: "Select Travel Modes",
            value: ""
         })
         if (response.success === true) {
            const arrayData = response.data.rows;
            for (let key in arrayData) {
               country_level.push({
                  label: arrayData[key].travel_mode_name,
                  value: arrayData[key].travel_mode_id,
               });
            }
         }
         setTravelModeList(country_level)
      });
   };


   /* for preview */
   const handlePrint = () => {
      window.print();
   };

   const inputChangeHandler = (event, identifier) => {
      const updatedForm = { ...border_leave_edit_form };
      const updatedElement = { ...updatedForm[identifier] };

      let validate_element = Validations(updatedElement);
      updatedElement.valid = validate_element.valid;
      updatedElement.error_msg = validate_element.error_msg;
      updatedElement.value = identifier === "remarks_by_boarding" ? event.target.value.substring(0, 130) : event.target.value;

      // Determine which textarea is being changed and update its height accordingly
      const currentTextareaRef =
         identifier === "remarks_by_host"
            ? textareaRefHost
            : identifier === "remarks_by_parent"
               ? textareaRefParent
               : identifier === "remarks_by_student"
                  ? textareaRefStudent
                  : identifier === "remarks_by_boarding"
                     ? textareaRefBoarding : textareaRefNotes;

      if (currentTextareaRef.current) {
         currentTextareaRef.current.style.height = 'auto'; // Reset the height to auto
         currentTextareaRef.current.style.height = `${currentTextareaRef.current.scrollHeight}px`; // Set the new height
      }
      updatedForm[identifier] = updatedElement;
      setBorderLeaveEditForm(updatedForm);
   }



   const handleSelectChange = (data, identifier, type) => {
      const update_border_leave_edit_form = { ...border_leave_edit_form };
      if (identifier === "return_mode_id") {
         update_border_leave_edit_form["return_mode_id"].value = data.value;
         update_border_leave_edit_form['return_mode_name'].value = data.label;
      }
      if (identifier === "departure_mode_id") {
         update_border_leave_edit_form['departure_mode_id'].value = data.value;
         update_border_leave_edit_form['departure_mode_name'].value = data.label;
      }

      const form_element = { ...update_border_leave_edit_form[identifier] };
      // Check Validation
      let validate_element = Validations(form_element);
      form_element.valid = validate_element.valid;
      form_element.error_msg = validate_element.error_msg;
      update_border_leave_edit_form[identifier] = form_element;
      setLeaveEdit(update_border_leave_edit_form)
   }

   function getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
   }

   const checkAppliedLeaveTiming = (departure_date) => {
      const update_border_leave_edit_form = { ...border_leave_edit_form };
      const { cut_off_day, cut_off_time } = user_info.user_school;
      let hours = parseInt(cut_off_time.split(':')[0], 10)
      let minute = parseInt(cut_off_time.split(':')[1], 10)
      let days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var currentDate = new Date();
      var daysUntilCutOffDay = ((days.indexOf(cut_off_day)) - currentDate.getDay() + 7) % 7;
      var cut_off_date = new Date(currentDate);
      cut_off_date.setDate(currentDate.getDate() + daysUntilCutOffDay);
      cut_off_date.setHours(hours);
      cut_off_date.setMinutes(minute);
      const depart_date = new Date(departure_date)
      var timeDifference = cut_off_date - depart_date;
      var hoursDifference = timeDifference / (1000 * 60 * 60);
      if (Math.floor(hoursDifference) > 48 || Math.floor(hoursDifference) < 0) {
         // update_border_leave_edit_form['leave_applied_ontime'].value = true;
      } else {
         // update_border_leave_edit_form['leave_applied_ontime'].value = false;
      }
   }



   const dateAndTimeHandler = (event, identifier) => {
      const update_border_leave_edit_form = { ...border_leave_edit_form };
      if (identifier === 'departure_date') {
         checkAppliedLeaveTiming(event.target.value);
         const form_element = { ...update_border_leave_edit_form['departure_date'] };
         form_element.value = event.target.value;
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_border_leave_edit_form[identifier] = form_element;
         // if (update_border_leave_edit_form['return_time'].value !== "") {
         //    update_border_leave_edit_form['return_time'].value = ""
         // }
      } else if (identifier === 'departure_time') {
         const form_element = { ...update_border_leave_edit_form[identifier] };
         form_element.value = moment(event, 'hh:mm A').format('HH:mm');
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_border_leave_edit_form[identifier] = form_element;
         // if (update_border_leave_edit_form['return_time'].value !== "") {
         //    update_border_leave_edit_form['return_time'].value = ""
         // }
      } else if (identifier === 'return_time') {
         const form_element = { ...update_border_leave_edit_form[identifier] };
         form_element.value = moment(event, 'hh:mm A').format('HH:mm');
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_border_leave_edit_form[identifier] = form_element;
      } else if (identifier === 'return_date') {
         const form_element = { ...update_border_leave_edit_form[identifier] };
         form_element.value = event.target.value
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_border_leave_edit_form[identifier] = form_element;
         // if (update_border_leave_edit_form['return_time'].value !== "") {
         //    update_border_leave_edit_form['return_time'].value = ""
         // }
      } else {
         const form_element = { ...update_border_leave_edit_form[identifier] };
         form_element.value = event.target.value
         let validate_element = Validations(form_element);
         form_element.valid = validate_element.valid;
         form_element.error_msg = validate_element.error_msg;
         update_border_leave_edit_form[identifier] = form_element;

      }
      setBorderLeaveEditForm(update_border_leave_edit_form)
   }



   const SubmitHandler = async (event) => {
      event.preventDefault();
      let is_form_valid = true;
      const updatedForm = { ...border_leave_edit_form };
      for (let key in updatedForm) {
         let updatedElement = updatedForm[key];
         let validate_element = Validations(updatedElement);
         is_form_valid = validate_element.valid === false ? false : is_form_valid;
         updatedElement.onBlur_out = true;
         updatedElement.valid = validate_element.valid;
         updatedElement.error_msg = validate_element.error_msg;
         updatedForm[key] = updatedElement;
      }
      setBorderLeaveEditForm(updatedForm);
      if (is_form_valid) {
         let check = updatedForm.departure_date.placeholder === moment(updatedForm.departure_date.value).format('DD/MM/YYYY');
         const form_data = {};
         for (let key in border_leave_edit_form) {
            form_data[key] = border_leave_edit_form[key].value;
         }
         if (check) {
            dispatch(userActions.EditBoardersLeave(form_data)).then((response) => {
               if (response.success === true) {
                  setTimeout(() => {
                     toast.success(response.message, {
                        position: toast.POSITION.TOP_CENTER,
                     });
                  }, 500);
                  navigate(`/${school_code}/leave/boarders-leave-details`)
               } else {
                  toast.error(response.message, {
                     position: toast.POSITION.TOP_CENTER,
                  });
               }
            }).catch((e) => {

            })
         } else {
            setShowEditLeaveModal(true)
            setLeaveData(form_data)
         }
      }
   }

   const hideLeaveModal = (value) => {
      setShowEditLeaveModal(value)
   }

   const EditBoardersLeave = async (leave_data) => {
      dispatch(userActions.EditBoardersLeave(leave_data)).then((response) => {
         if (response.success === true) {
            setTimeout(() => {
               toast.success(response.message, {
                  position: toast.POSITION.TOP_CENTER,
               });
            }, 500);
            navigate(`/${school_code}/leave/boarders-leave-details`)
         } else {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
         }
      }).catch((e) => {

      })
   }
   console.log(border_leave_edit_form, "::border_leave_edit_form897")

   return (
      <>
         {showEditLeaveModal &&
            <EditBorderLeaveModal
               showEditLeaveModal={showEditLeaveModal}
               hideLeaveModal={(value) => hideLeaveModal(value)}
               leaveData={leaveData}
               EditBoardersLeave={(leave) => EditBoardersLeave(leave)}
            />
         }
         <div
            id="kt_body"
            className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed"
         >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <SideBarLeave />
                  <div
                     className="wrapper d-flex flex-column flex-row-fluid "
                     id="kt_wrapper"
                  >
                     <TopBar />
                     <div
                        className="content d-flex flex-column flex-column-fluid "
                        id="kt_content"
                     >
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                           <div id="kt_content_container" className="container-fluid">
                              <form onSubmit={SubmitHandler}>
                                 <div className="card">
                                    <div className="card-header align-items-center">
                                       <div className='d-flex align-items-center'>
                                          <h3 className="card-title">Student Details</h3>
                                          {border_leave_data.leave_applied_ontime == true ? (<span class="badge rounded-pill text-bg-success">On Time </span>) : (<span class="badge rounded-pill text-bg-danger">Late</span>)}
                                          <span className={`badge rounded-pill ${border_leave_data.is_user_approval === "approved" ? 'text-bg-success' : border_leave_data.is_user_approval === "pending" ? ' text-bg-warning' : "text-bg-danger"}`}>
                                             {border_leave_data.is_user_approval}
                                          </span>
                                       </div>
                                       <Link to={`/${school_code}/leave/boarders-leave-details`} className='d-flex align-items-center'><i className="fa fa-angle-left me-3" aria-hidden="true"></i>Back</Link>
                                    </div>
                                    <div className="card-body">
                                       <div className="row">
                                          <div className="col-md-4">
                                             <div className="profile-image-out p-0">
                                                {border_leave_data?.leave_stu_data?.student_avatar === null || border_leave_data?.leave_stu_data?.student_avatar === "" || border_leave_data?.leave_stu_data?.student_avatar === undefined ?
                                                   <img src={border_leave_data?.leave_stu_data?.gender === "male" ? "/media/avatars/boy.jpg" : "/media/avatars/girl.jpg"}
                                                      alt='Student Avatar'
                                                      className="img-thumbnail  custom-thumbnail"
                                                   />
                                                   :
                                                   <img src={`${actionType.environment_url}/${border_leave_data?.leave_stu_data.student_avatar}`}
                                                      alt='Student Avatar'
                                                      className="img-thumbnail  custom-thumbnail"
                                                   />
                                                }
                                             </div>
                                          </div>
                                          <div className="col-md-4">
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Student Name</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.leave_stu_data?.student_name}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Year Name</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.leave_stu_data?.stu_class_data?.class_name}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Dormitory Name</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.leave_stu_data?.dormitory_data?.dormitory_name}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Gender</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.leave_stu_data?.gender.charAt(0).toUpperCase() + border_leave_data.leave_stu_data?.gender.slice(1)}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-4">
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Student E-Mail Address</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.leave_stu_data?.student_email}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Student's Mobile Number</label>
                                                <ReactPhoneInput
                                                   value={border_leave_data?.leave_stu_data?.student_phone}
                                                   disabled={true}
                                                />
                                             </div>
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Unique Pin</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data?.leave_stu_data?.unique_pin} className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                             <div className="mb-4">
                                                <label className='fw-bold mb-3'>Dormitory Contact</label>
                                                <ReactPhoneInput
                                                   value={border_leave_data.leave_stu_data?.dormitory_data?.dormitory_contact}
                                                   disabled={true}
                                                />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                                 <div className="card mt-5 mb-5">
                                    <div className="card-header align-items-center">
                                       <h3 class="card-title">
                                          Host Details {" "}
                                          <Tooltip title="Host details may not be edited here.  Also if you are needing to change the Host, then please . . . please, make a new Leave application." placement="top">
                                             <i className="fas fa-question-circle ms-2"></i>
                                          </Tooltip>
                                       </h3>
                                    </div>
                                    <div className="card-body">
                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="mb-4">
                                                <label className='form-label'>Host Name</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.stu_host_data?.host_name || (border_leave_data?.parent_type == "mother" ? border_leave_data.parent_data?.mother_name : border_leave_data.parent_data?.father_name)}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="mb-4">
                                                <label className='form-label'>Relationship To Student</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.stu_host_data?.stu_host?.host_relation || border_leave_data?.parent_type}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="mb-4">
                                                <label className='form-label'>Host Address</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data?.stu_host_data?.host_address || (border_leave_data?.parent_data?.parent_address[0]?.address_line1)}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="mb-4">
                                                <label className='form-label'>Contact Number</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.stu_host_data?.host_contact || (border_leave_data?.parent_type == "mother" ? border_leave_data.parent_data?.mother_phone : border_leave_data.parent_data?.father_phone)}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                          </div>
                                       </div>
                                       <div className="row">
                                          <div className="col-md-6">
                                             <div className="mb-4">
                                                <label className='form-label'>{border_leave_edit_form.remarks_by_host.label}</label>
                                                <textarea
                                                   type={border_leave_edit_form.remarks_by_host.type}
                                                   value={border_leave_edit_form.remarks_by_host.value ? border_leave_edit_form.remarks_by_host.value : ""}
                                                   className="form-control"
                                                   placeholder={border_leave_edit_form.remarks_by_host.placeholder}
                                                   onChange={(event) => inputChangeHandler(event, "remarks_by_host")}
                                                   ref={textareaRefHost}
                                                   readOnly={hostEdit}
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-6">
                                             <div className="mb-4">
                                                <label className='form-label'>Email</label>
                                                <input
                                                   type="text"
                                                   value={border_leave_data.stu_host_data?.host_email || (border_leave_data?.parent_type == "mother" ? border_leave_data.parent_data?.mother_email : border_leave_data.parent_data?.father_email)}
                                                   className="form-control"
                                                   readOnly
                                                />
                                             </div>
                                          </div>
                                       </div>

                                       {hostEdit ?
                                          <div className="d-flex justify-content-end">
                                             <button
                                                type="button"
                                                class="btn btn-primary px-10 py-2"
                                                onClick={() => setHostEdit(false)}
                                             >
                                                Edit Remarks
                                             </button>
                                          </div>
                                          :
                                          <div className="d-flex justify-content-end">
                                             <button
                                                type="button"
                                                class="btn btn-secondary px-10 py-2 me-4"
                                                onClick={() => setHostEdit(true)}
                                             >
                                                Cancel
                                             </button>
                                             <button
                                                type="Submit"
                                                class="btn btn-primary px-10 py-2"
                                             >
                                                Submit
                                             </button>
                                          </div>
                                       }
                                    </div>
                                 </div>


                                 <div className="card mt-5 mb-5">
                                    <div className="card-header align-items-center">
                                       <h3 class="card-title">Leave Details</h3>
                                    </div>
                                    <div className="card-body">
                                       <div className='row'>
                                          <div className="col-md-4 col-lg-4 col-xxl-4 mb-3">
                                             <label className="mb-2 fw-bold">{border_leave_edit_form.departure_date.label}</label>
                                             <div className='position-relative'>
                                                <span className='position-absolute apply_adhoc_leave_date_and_time' onClick={() => document.getElementById('session-date').click()}>

                                                </span>
                                                <input
                                                   id="session-date"
                                                   type="date"
                                                   className="form-control bg-transparent"
                                                   placeholder={border_leave_edit_form.departure_date.placeholder}
                                                   value={border_leave_edit_form.departure_date.value}
                                                   onChange={(event) => dateAndTimeHandler(event, "departure_date")}
                                                   autoComplete="off"
                                                   required
                                                   min={getCurrentDate()}
                                                   disabled={leaveEdit}
                                                />
                                             </div>
                                             {!border_leave_edit_form.departure_date.valid && border_leave_edit_form.departure_date.onBlur_out ? <div className="error field-error">{border_leave_edit_form.departure_date.error_msg}</div> : ""}
                                          </div>

                                          <div className="col-md-4 col-lg-4 col-xxl-4 mb-3">
                                             <label className="mb-2 fw-bold">{border_leave_edit_form.departure_time.label}</label>
                                             <EditBoaderLeaveDepartureTime
                                                selectDate={border_leave_edit_form.departure_date.value}
                                                selectTime={border_leave_edit_form.departure_time.value}
                                                onChange={(event) => dateAndTimeHandler(event, "departure_time")}
                                                disabled={leaveEdit}
                                             />
                                             {!border_leave_edit_form.departure_time.valid && border_leave_edit_form.departure_time.onBlur_out ? <div className="error field-error">{border_leave_edit_form.departure_time.error_msg}</div> : ""}
                                          </div>

                                          <div className="col-md-4 col-lg-4 col-xxl-4 mb-3">
                                             <label className="mb-2 fw-bold">{border_leave_edit_form.departure_mode_id.label}</label>
                                             <Select
                                                styles={customStyles}
                                                className="react-bootstrap-typeahead tag-outer"
                                                options={travelModeList}
                                                value={travelModeList.filter(function (option) {
                                                   return option.value === border_leave_edit_form.departure_mode_id.value;
                                                })}
                                                onChange={(newValue) => handleSelectChange(newValue, "departure_mode_id", "weekend_day from value")}
                                                autoComplete="off"
                                                isDisabled={leaveEdit}
                                             />
                                             {!border_leave_edit_form.departure_mode_id.valid && border_leave_edit_form.departure_mode_id.onBlur_out ? <div className="error field-error">{border_leave_edit_form.departure_mode_id.error_msg}</div> : ""}
                                          </div>

                                          <div className="col-md-4 col-lg-4 col-xxl-4 mb-3">
                                             <label className="mb-2 fw-bold">{border_leave_edit_form.return_date.label}</label>
                                             <div className='position-relative'>
                                                <span className='position-absolute apply_adhoc_leave_date_and_time' onClick={() => document.getElementById('session-date').click()}>

                                                </span>
                                                <input
                                                   id="session-date"
                                                   type="date"
                                                   className="form-control bg-transparent"
                                                   placeholder={border_leave_edit_form.return_date.placeholder}
                                                   value={border_leave_edit_form.return_date.value}
                                                   onChange={(event) => dateAndTimeHandler(event, "return_date")}
                                                   autoComplete="off"
                                                   required
                                                   min={getCurrentDate()}
                                                   disabled={leaveEdit}
                                                />
                                             </div>
                                             {!border_leave_edit_form.return_date.valid && border_leave_edit_form.return_date.onBlur_out ? <div className="error field-error">{border_leave_edit_form.return_date.error_msg}</div> : ""}
                                          </div>

                                          <div className="col-md-4 col-lg-4 col-xxl-4 mb-3">
                                             <label className="mb-2 fw-bold">{border_leave_edit_form.return_time.label}</label>
                                             <EditBoarderLeaveReturnTime
                                                selectDepartureDate={border_leave_edit_form.departure_date.value}
                                                selectReturnDate={border_leave_edit_form.return_date.value}
                                                selectDepartureTime={border_leave_edit_form.departure_time.value}
                                                placeholder={border_leave_edit_form.return_time.placeholder}
                                                selectTime={border_leave_edit_form.return_time.value}
                                                onChange={(event) => dateAndTimeHandler(event, "return_time")}
                                                disabled={leaveEdit}
                                             />
                                             {!border_leave_edit_form.return_time.valid && border_leave_edit_form.return_time.onBlur_out ? <div className="error field-error">{border_leave_edit_form.return_time.error_msg}</div> : ""}
                                          </div>

                                          <div className="col-md-4 col-lg-4 col-xxl-4 mb-3">
                                             <label className="mb-2 fw-bold">{border_leave_edit_form.return_mode_id.label}</label>
                                             <Select
                                                styles={customStyles}
                                                className="react-bootstrap-typeahead tag-outer"
                                                options={travelModeList}
                                                value={travelModeList.filter(function (option) {
                                                   return option.value === border_leave_edit_form.return_mode_id.value;
                                                })}
                                                onChange={(newValue) => handleSelectChange(newValue, "return_mode_id", "weekend_day from value")}
                                                autoComplete="off"
                                                isDisabled={leaveEdit}
                                             />
                                             {!border_leave_edit_form.return_mode_id.valid && border_leave_edit_form.return_mode_id.onBlur_out ? <div className="error field-error">{border_leave_edit_form.return_mode_id.error_msg}</div> : ""}
                                          </div>
                                       </div>

                                       <div className="row">
                                          <div className="col-md-4">
                                             <div className="mb-3">
                                                <label className='form-label'>{border_leave_edit_form.remarks_by_parent.label}</label>
                                                <textarea
                                                   type={border_leave_edit_form.remarks_by_parent.type}
                                                   value={border_leave_edit_form.remarks_by_parent.value ? border_leave_edit_form.remarks_by_parent.value : ""}
                                                   className="form-control  h-100"
                                                   placeholder={border_leave_edit_form.remarks_by_parent.placeholder}
                                                   onChange={(event) => inputChangeHandler(event, "remarks_by_parent")}
                                                   ref={textareaRefParent}
                                                   disabled={leaveEdit}
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-4">
                                             <div className="mb-3">
                                                <label className='form-label'>
                                                   {border_leave_edit_form.remarks_by_boarding.label}{" "}
                                                   {/* {border_leave_edit_form.remarks_by_boarding.value === "" ? `( Insert at least 10 characters )` :
                                                      border_leave_edit_form.remarks_by_boarding.value.length < 10 && border_leave_edit_form.remarks_by_boarding.value !== ""? `( Add at least ${10-border_leave_edit_form.remarks_by_boarding.value.length} more characters )` :
                                                         ""
                                                   } */}
                                                </label>
                                                <textarea
                                                   type={border_leave_edit_form.remarks_by_boarding.type}
                                                   value={border_leave_edit_form.remarks_by_boarding.value ? border_leave_edit_form.remarks_by_boarding.value : ""}
                                                   className="form-control h-100 mb-2"
                                                   placeholder={border_leave_edit_form.remarks_by_boarding.placeholder}
                                                   onChange={(event) => inputChangeHandler(event, "remarks_by_boarding")}
                                                   ref={textareaRefBoarding}
                                                   disabled={leaveEdit}
                                                />
                                                <span className="countLength">Max 130 Characters: {border_leave_edit_form.remarks_by_boarding.value.length}</span>
                                                {!border_leave_edit_form.remarks_by_boarding.valid && border_leave_edit_form.remarks_by_boarding.onBlur_out ? <div className="error field-error">{border_leave_edit_form.remarks_by_boarding.error_msg}</div> : ""}
                                             </div>

                                          </div>
                                          <div className="col-md-4">
                                             <div className="mb-3">
                                                <label className='form-label'>{border_leave_edit_form.remarks_by_student.label}</label>
                                                <textarea
                                                   type={border_leave_edit_form.remarks_by_student.type}
                                                   value={border_leave_edit_form.remarks_by_student.value ? border_leave_edit_form.remarks_by_student.value : ""}
                                                   className="form-control  h-100"
                                                   placeholder={border_leave_edit_form.remarks_by_student.placeholder}
                                                   onChange={(event) => inputChangeHandler(event, "remarks_by_student")}
                                                   ref={textareaRefStudent}
                                                   disabled={leaveEdit}
                                                />
                                             </div>
                                          </div>
                                          <div className="col-md-4">
                                             <div className="mb-3">
                                                <label className='form-label'>Remark By Host</label>
                                                <textarea
                                                   type="text"
                                                   value={border_leave_edit_form.is_host_approval.value==="approved" ? CapitalFirstLetter(border_leave_edit_form.host_approved_comments.value): CapitalFirstLetter(border_leave_edit_form.host_rejected_comments.value)}
                                                   className="form-control  h-100"
                                                   placeholder="Remark By Host"
                                                   onChange={(event) => inputChangeHandler(event, "remarks_by_parent")}
                                                   // ref={textareaRefParent}
                                                   disabled={true}
                                                />
                                             </div>
                                          </div>
                                          {/* <div className="col-md-4">
                                             <div className="mb-3">
                                                <label className='form-label'>Additional Notes</label>
                                                <textarea
                                                   type={border_leave_edit_form.additional_notes.type}
                                                   value={border_leave_edit_form.additional_notes.value ? border_leave_edit_form.additional_notes.value : ""}
                                                   className="form-control h-100"
                                                   placeholder={border_leave_edit_form.additional_notes.placeholder}
                                                   onChange={(event) => inputChangeHandler(event, "additional_notes")}
                                                   ref={textareaRefNotes}
                                                   disabled={leaveEdit}
                                                />
                                             </div>
                                          </div> */}
                                       </div>

                                       {leaveEdit ?
                                          <div className="d-flex justify-content-end">
                                             <button
                                                type="button"
                                                class="btn btn-primary px-10 py-2"
                                                onClick={() => setLeaveEdit(false)}
                                             >
                                                Edit Leave Details
                                             </button>
                                          </div>
                                          :
                                          <div className="d-flex justify-content-end">
                                             <button
                                                type="button"
                                                class="btn btn-secondary px-10 py-2 me-4"
                                                onClick={() => setLeaveEdit(true)}
                                             >
                                                Cancel
                                             </button>
                                             <button
                                                type="submit"
                                                class="btn btn-primary px-10 py-2"
                                             >
                                                Submit
                                             </button>
                                          </div>
                                       }
                                    </div>
                                 </div>
                                 <div className="d-flex justify-content-end">
                                    <button
                                       type="button"
                                       class="btn btn-outline-dark px-10 py-2 me-4 btn-sm"
                                       onClick={() => navigate(`/${school_code}/leave/boarders-leave-details`)}
                                    >
                                       Back to Leave
                                    </button>
                                    <button
                                       type="button"
                                       class="btn btn-primary px-10 py-2"
                                       onClick={handlePrint}
                                    >
                                       Print
                                    </button>
                                 </div>
                              </form>
                           </div>
                        </div>

                     </div>
                     <Footer />
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}



function mapStateToProps(state) {
   return {

   };
}

const mapDispatchToProps = {
   onAddOrUpdateStudentHost: userActions.AddOrUpdateStudentHost,
   onGetApprovedStudentHost: userActions.GetApprovedStudentHost,
   onGetStudentAndParentById: userActions.GetStudentAndParentById,
   onApplyBorderLeave: userActions.ApplyBorderLeave,
   onGetAllTravelModes: userActions.GetAllTravelModes,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditBoardersLeave);
