import { Tooltip } from '@mui/material';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdb-react-ui-kit';
import moment from 'moment';
import React from 'react'
import { useNavigate, Link } from "react-router-dom";
import CapitalFirstLetter from "../../../Utility/CapitalFirstLetter"


const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const timezone = user_info?.user_school?.timezone;
const current = new Date();
const school_code = user_info?.school_code ?? null;
export default function DepartingAndOnLeave({ leftSide, leftSideHeader }) {
    console.log(leftSide, leftSideHeader, "::leftSide")
    let current_date = moment().tz(timezone).format('MMMM Do YYYY, h:mm:ss a')
    let current_date2 = moment().tz(timezone)
    return (
        <>
            <div className='table-responsive'>
                <MDBTable className='table table-row-bordered'>
                    <MDBTableHead>
                        <tr>
                            <th className='min-w-150px'>Student Name</th>
                            <th>{leftSideHeader == "Students On Leave Now" ?
                                "Duration"
                                :
                                <Tooltip title="Expected Time Departure" placement="top">
                                    ETD
                                </Tooltip>
                            }
                            </th>
                            <th>Dormitory</th>
                            <th>Year</th>
                            <th>Status</th>
                            {leftSideHeader == "Students On Leave Now" && <th>Actions</th>}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {leftSide.map((item, index) => {
                            return (
                                <tr key={item.border_leave_id}>
                                    <td>
                                        {
                                            `${CapitalFirstLetter(item.student_data?.student_first_name) || CapitalFirstLetter(item.intial_leave_data.leave_stu_data.student_first_name)} 
                                            ${item.student_data?.student_last_name || item.intial_leave_data.leave_stu_data.student_last_name}`
                                        }
                                    </td>

                                    <td>
                                        {item.departData !== undefined ?
                                            <span>
                                                From
                                                <strong> {item.departure_date !== undefined ? moment(item.departure_date).format('DD MMM') : moment(item.departData.planned_depart_date).format('DD MMM')} </strong>
                                                To
                                                {current_date2.diff(moment(`${item.planned_return_date} ${item.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") < 24 ?
                                                    <strong> {item.return_date !== undefined ? moment(item.return_date).format('DD MMM') : moment(item.planned_return_date).format('DD MMM')}</strong> :
                                                    <strong className='text-danger'> {item.return_date !== undefined ? moment(item.return_date).format('DD MMM') : moment(item.planned_return_date).format('DD MMM')}</strong>
                                                }
                                            </span>
                                            :
                                            moment(item.planned_depart_time, "HH:mm").format("h:mm A")
                                        }
                                    </td>
                                    <td>
                                        {leftSideHeader === 'Students On Leave Now' ? item.student_data?.dormitory_data?.dormitory_name : item.intial_leave_data?.leave_stu_data?.dormitory_data?.dormitory_name}
                                    </td>
                                    <td>
                                        {leftSideHeader === 'Students On Leave Now' ? item.student_data?.class_name : item.intial_leave_data?.leave_stu_data?.class_name}
                                    </td>
                                    <td>
                                        {
                                            item.departData !== undefined ?
                                                <span className={`badge badge-light-${item.return_status === "pending" || item.return_status === "schedule_changed" ? "danger" : "success"}`}>{item.return_status === "pending" || item.return_status === "schedule_changed" ? "On Leave" : "New Request"}</span>
                                                :
                                                <span >
                                                    {
                                                        item.depart_status === "pending" ? "-" :
                                                            <img src="/assets/media/upside-arrow.png" style={{ maxHeight: "26px" }} />
                                                    }
                                                </span>
                                        }
                                    </td>
                                    {item.departData !== undefined &&
                                        <td>
                                            <Tooltip title="View" placement="top">
                                                <Link to={`/${school_code}/leave/edit-boarders-leave/${item.border_leave_id}`}>
                                                    {
                                                        current_date2.diff(moment(`${item.planned_return_date} ${item.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") < 24 ?
                                                            <i className="bi bi-eye-fill text-primary fs-2 ms-2"></i> : <i className="bi bi-eye-fill text-danger fs-2 ms-2"></i>
                                                    }
                                                </Link>
                                            </Tooltip>
                                        </td>}
                                </tr>
                            )
                        })}

                    </MDBTableBody>
                </MDBTable>
            </div>
            {/* <div className="d-flex justify-content-between">
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={row_limit[1]}
                    name="color"
                    options={row_limit}
                    onChange={(e) => setLimit(e.value)}
                />
                <Stack>
                    <Pagination
                        count={Math.ceil(count / limit)}
                        page={page}
                        onChange={(e, value) => setPage(value)}
                        color="primary"
                    />
                </Stack>
            </div> */}
        </>
    )
}
