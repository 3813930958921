import React, { useState, useEffect, useCallback } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Tooltip } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, connect } from "react-redux";
import moment from "moment";
import "moment-timezone";
import { MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import * as userActions from "../../../actions/index";
import ArchiveBorderLeaveModal from '../LeaveModals/ArchiveBorderLeaveModal';
import DepartureScheduleChangeModal from '../LeaveModals/DepartureScheduleChangeModal';
import DepartureApprovalModal from '../LeaveModals/DepartureApprovalModal';
import IconButton from '@mui/material/IconButton';
import { updateBooleanValue } from '../../../reducers/Snapshot';
import * as actionType from "../../../constants/actionTypes";
import CapitalFirstLetter from '../../Utility/CapitalFirstLetter';
const ManageStudentDeparture = (props) => {
    const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
    const school_code = user_info?.school_code ?? null;
    const timezone = user_info?.user_school?.timezone;
    let current_date = moment()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [rowVisibility, setRowVisibility] = useState({}); // State for row visibility
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState();
    const [sort, setSort] = useState("border_leave_id");
    const [order, setOrder] = useState("desc");
    const [search, setSearch] = useState("");
    const [from_date, setFromDate] = useState("");
    const [to_date, setToDate] = useState("");
    const [BorderLeaveList, setBorderLeaveList] = useState([]);
    const [leaveData, setLeaveData] = useState({})
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    const [showChangeShedule, setShowChangeShedule] = useState(false)
    const [showDepartStudent, setShowDepartStudent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const row_limit = [
        { value: 5, label: 5 },
        { value: 10, label: 10 },
        { value: 15, label: 15 },
        { value: 20, label: 20 },
        { value: 25, label: 25 }
    ]

    // search 
    const handleSearchChange = (e, value) => {
        GetDepartureStudentDetails(from_date, to_date)
    }

    const handleToggleRowVisibility = (index) => {
        setRowVisibility((prevState) => ({
            ...prevState,
            [index]: !prevState[index], // Toggle visibility for the specified index
        }));
    };



    useEffect(() => {
        GetDepartureStudentDetails();
    }, [page, limit, sort, order, search, from_date, to_date]);

    const GetDepartureStudentDetails = async () => {
        props.onGetDepartureStudentDetails(page, limit, sort, order, search, from_date, to_date).then((response) => {
            if (response.success === true) {
                const arrayData = response.data.rows;
                arrayData.forEach((element, i) => {
                    arrayData[i] = { ...element, schedule_change: true }
                });
                setBorderLeaveList(arrayData);
                setCount(response.data.count)
            }
            else {
            }
        });
        // eslint-disable-next-line
    };



    const ArchiveOrUnarchiveBorderLeave = async (form_data) => {
        props.onArchiveOrUnarchiveBorderLeave(form_data).then((response) => {
            if (response.success === true) {
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                navigate(`/${school_code}/leave/boarders-leave-details`)
                GetDepartureStudentDetails();
            } else {
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }

        });
        // eslint-disable-next-line
    };

    const DepartStuLeaveChange = async (form_data) => {
        setIsLoading(true);
        props.onDepartStuLeaveChange(form_data).then((response) => {
            if (response.success === true) {
                setIsLoading(false);
                toast.success(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
                setShowDepartStudent(false)
                setShowChangeShedule(false)
                GetDepartureStudentDetails()
                dispatch(updateBooleanValue(true))
            } else {
                setIsLoading(false);
                toast.error(response.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        })
    };


    const hideModal = (value) => {
        setShowArchiveModal(value)
        setShowChangeShedule(value)
        setShowDepartStudent(value)
    }

    return (
        <>
            {showArchiveModal && <ArchiveBorderLeaveModal
                showArchiveModal={showArchiveModal}
                archiveLeaveData={leaveData}
                ArchiveOrUnarchiveBorderLeave={(form_data) => ArchiveOrUnarchiveBorderLeave(form_data)}
                hideArchiveModal={(value) => hideModal(value)}
            />}
            {showChangeShedule && <DepartureScheduleChangeModal
                showChangeShedule={showChangeShedule}
                archiveLeaveData={leaveData}
                DepartStuLeaveChange={(form_data) => DepartStuLeaveChange(form_data)}
                hideDepartStuLeaveChangeModal={(value) => hideModal(value)}
                isLoading={isLoading}
            />}
            {showDepartStudent && <DepartureApprovalModal
                showDepartStudent={showDepartStudent}
                archiveLeaveData={leaveData}
                DepartStuLeaveChange={(form_data) => DepartStuLeaveChange(form_data)}
                hideDepartStudentModal={(value) => hideModal(value)}
                isLoading={isLoading}
            />}
            <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
                <div className="d-flex flex-column flex-root">
                    <div className="page d-flex flex-row flex-column-fluid">
                        <SideBarLeave />
                        <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                            <TopBar />
                            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                <div className="post d-flex flex-column-fluid" id="kt_post">
                                    <div id="kt_content_container" className="container-fluid">

                                        <div className='row'>
                                            <div className='col-md-3 my-auto'>
                                                <div className="border border-gray-300 border-dashed rounded p-4">
                                                    <div className="fs-6 text-gray-800 fw-bolder">
                                                        Today-{moment().tz(timezone)?.format('MMMM Do YYYY, h:mm a')}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-9 bg-white p-8'>
                                                <div className='row'>
                                                    <div className='col-4'>
                                                        <label className="form-label">From Date</label>
                                                        <div className='position-relative'>
                                                            <span className='position-absolute apply_adhoc_leave_date_and_time'>

                                                            </span>
                                                            <input
                                                                id="session-date"
                                                                type="date"
                                                                className="form-control bg-transparent"
                                                                placeholder='From Date'
                                                                value={from_date}
                                                                onChange={(e) => setFromDate(e.target.value)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='col-4'>
                                                        <label className="form-label">To Date</label>
                                                        <div className='position-relative'>
                                                            <span className='position-absolute apply_adhoc_leave_date_and_time'>

                                                            </span>
                                                            <input
                                                                id="session-date"
                                                                type="date"
                                                                className="form-control bg-transparent"
                                                                placeholder='To Date'
                                                                value={to_date}
                                                                onChange={(e) => setToDate(e.target.value)}
                                                                min={from_date}
                                                                required
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className='col-4 mt-auto text-right'>
                                                        <button
                                                            type="reset"
                                                            className="ms-3  btn btn-secondary btn-sm btn-color-gray-700 btn-flex btn-active-primary  border-0 fw-bolder px-4 px-lg-6 me-2 me-lg-3 w-200 white-space"
                                                            onClick={(event) => {
                                                                setFromDate("");
                                                                setToDate("");
                                                            }}
                                                        >
                                                            Clear Search
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-primary btn-flex btn-sm  border-0 fw-bolder px-4 px-lg-6"
                                                            onClick={handleSearchChange}
                                                        >
                                                            Search
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='card mt-5'>
                                            <div className='card-header align-items-center'>
                                                <h3 className="card-title">Manage Student Departure</h3>
                                            </div>
                                            <div className='card-body'>
                                                <div className='table-responsive'>
                                                    <MDBTable className='table table-row-bordered'>
                                                        <MDBTableHead>
                                                            <tr>
                                                                <th></th>
                                                                {/* <th>Sr.No</th> */}
                                                                <th>Student Name</th>
                                                                <th>Student Host</th>
                                                                <th className='min-w-150px'>Depart Date</th>
                                                                <th className='min-w-100px'>Depart Time</th>
                                                                {/* <th className='min-w-100px'>Planned Mode Of Travel Departure</th> */}
                                                                <th className='min-w-100px'>Travel Mode</th>
                                                                <th className='min-w-100px'>Parent Approval</th>
                                                                <th className='min-w-100px'>Host Approval</th>
                                                                <th className='min-w-100px'>Manager Approval</th>
                                                                <th>Depart Status</th>
                                                                <th>Schedule Status</th>
                                                                <th className='text-center'>Actions</th>
                                                            </tr>
                                                        </MDBTableHead>
                                                        <MDBTableBody>
                                                            {BorderLeaveList.map((item, index) => {
                                                                return (
                                                                    <React.Fragment key={item.border_leave_id}>
                                                                        <tr key={item.border_leave_id}>
                                                                            <td>
                                                                                <button
                                                                                    className={`btn ${rowVisibility[index]
                                                                                        ? 'btn-light-secondary '
                                                                                        : 'btn-light-secondary '
                                                                                        } btn-sm w-32px`}
                                                                                    type="button"
                                                                                    onClick={() => handleToggleRowVisibility(index)}
                                                                                >
                                                                                    <i
                                                                                        className={`bi ${rowVisibility[index]
                                                                                            ? 'bi bi-chevron-up'
                                                                                            : 'bi bi-chevron-down'
                                                                                            } p-0`}
                                                                                    ></i>
                                                                                </button>
                                                                            </td>
                                                                            <td>
                                                                                <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                                                                                    {item.leave_stu_data?.student_avatar == null || item.leave_stu_data?.student_avatar == "" ?
                                                                                        <span className='round_text'>{item.leave_stu_data?.student_name.split("")[0]}</span>
                                                                                        :
                                                                                        <img src={`${actionType.environment_url}/${item.leave_stu_data?.student_avatar}`} />
                                                                                    }
                                                                                </div>
                                                                                {CapitalFirstLetter(item.leave_stu_data?.student_name) || 'N/A'}
                                                                            </td>
                                                                            <td>{item.host_id ? item?.stu_host_data?.host_name : item.parent_id && item.parent_type === 'father' ? item?.parent_data?.father_name : item?.parent_data?.mother_name || 'N/A'}</td>
                                                                            <td>{item.departure_students_data?.depart_status === 'schedule_changed' ? moment(item.departure_students_data?.planned_depart_date).format('Do MMM. YYYY') : moment(item.departure_date).format('Do MMM. YYYY')}</td>
                                                                            <td>{item.departure_students_data?.depart_status === 'schedule_changed' ? item.departure_students_data?.planned_depart_time : item.departure_time}</td>
                                                                            <td>{item.departure_students_data?.depart_status === 'schedule_changed' ? CapitalFirstLetter(item.departure_students_data?.planned_depart_mode.travel_mode_name): CapitalFirstLetter(item.departure_mode_data?.travel_mode_name)}</td>
                                                                            <td>
                                                                                <span className={`badge me-2 badge-light-${item?.is_user_approval === 'approved' ? 'success' : 'danger'}`}>
                                                                                    {`${item?.is_parent_approval}`}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <span className={`badge me-2 badge-light-${item?.is_host_approval === 'approved' ? 'success' : 'danger'}`}>
                                                                                    {`${item?.is_host_approval}`}
                                                                                </span>
                                                                            </td>

                                                                            <td>
                                                                                <span className={`badge me-2 badge-light-${item?.is_user_approval === 'approved' ? 'success' : 'danger'}`}>
                                                                                    {`${item?.is_user_approval}`}
                                                                                </span>
                                                                            </td>
                                                                            {console.log("fffffffffffffffffffffff", item)}
                                                                            
                                                                            <td>
                                                                                <span className='fw-bold'>{`${item.departure_students_data.depart_status === "departed" ? "Departed" : "Not Departed"}`}</span>
                                                                                {/* {item.departure_students_data?.depart_status === 'departed' ?
                                                                                    <img src="/assets/media/departure_student.png" alt="" class="h-50px" title="Departed" />
                                                                                    :
                                                                                    item.departure_students_data?.depart_status === 'schedule_changed'
                                                                                        ?
                                                                                        <img src="/assets/media/schedule_change.png" alt="" class="h-50px" />
                                                                                        :
                                                                                        <i class="fas fa-solid fa-arrow-right fas fa-beat h-50px" style={{ color: "#bce660", fontSize: "36px" }} ></i>
                                                                                } */}

                                                                            </td>
                                                                            <td>
                                                                                {console.log("date_and_time===============", current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours"))}
                                                                                {
                                                                                    36 > current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 24 ?
                                                                                        <img src="/assets/media/late2.png" alt="" class="h-60px" />
                                                                                        :
                                                                                        48 > current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 36 ?
                                                                                        <img src="/assets/media/late2.png" alt="" class="h-60px" />
                                                                                            :
                                                                                            72 > current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") && current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") > 48 ?
                                                                                            <img src="/assets/media/late2.png" alt="" class="h-60px" />
                                                                                                :
                                                                                                current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "days") >= 3 ?
                                                                                                    `${current_date.diff(moment(`${item.departure_students_data?.planned_depart_date} ${item.departure_students_data?.planned_depart_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "days")} days late`
                                                                                                    :
                                                                                                    item.departure_students_data?.depart_status === 'schedule_changed'
                                                                                                        ?
                                                                                                        <img src="/assets/media/schedule_change.png" alt="" class="h-50px" />
                                                                                                        :
                                                                                                            moment().tz(timezone)?.format('YYYY-MM-DD') > item.departure_date ?
                                                                                                            <img src="/assets/media/late2.png" alt="" class="h-60px" />
                                                                                                        :
                                                                                                        <span className='fw-bold'>On Schedule</span>


                                                                                }
                                                                            </td>
                                                                            <td >

                                                                                {
                                                                                    item.departure_students_data.depart_status === "departed" ? 
                                                                                    <span className='badge me-2 badge-light-success'>Departed</span> :
                                                                                        <div>
                                                                                            <Tooltip title="Schedule Change" placement="top">
                                                                                                <IconButton
                                                                                                    className='me-4'
                                                                                                    onClick={() => {
                                                                                                        //navigate(`/${school_code}/leave/student-return`)
                                                                                                        setShowChangeShedule(true);
                                                                                                        setLeaveData(item);
                                                                                                    }}
                                                                                                >
                                                                                                    <img src="/assets/media/change.png" style={{ filter: "invert(60%) sepia(7%) saturate(93%) hue-rotate(182deg) brightness(92%) contrast(85%)" }} />
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                            <Tooltip title="Sign em Out" placement="top">
                                                                                                <IconButton
                                                                                                    onClick={() => {
                                                                                                        setShowDepartStudent(true);
                                                                                                        setLeaveData(item);
                                                                                                    }}
                                                                                                >
                                                                                                    <img src="/assets/media/airport.png" style={{ filter: "invert(88%) sepia(4%) saturate(34%) hue-rotate(314deg) brightness(79%) contrast(85%)" }} />
                                                                                                    {/* <i class="fas fa-plane-departure fs-6"></i> */}
                                                                                                </IconButton>
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                        {rowVisibility[index] && (
                                                                            <>
                                                                                <tr className='text-bg-light'>
                                                                                    <th></th>
                                                                                    <th colSpan={2}>Actual Departure Date</th>
                                                                                    <th colSpan={2}>Actual Departure Time</th>
                                                                                    <th colSpan={2}> Actual Mode Of Travel Departure</th>
                                                                                    <th colSpan={2}>Submit Date</th>
                                                                                    <th colSpan={2}>Submit Time</th>
                                                                                    <th>Action</th>
                                                                                </tr>
                                                                                <tr className='text-bg-light'>
                                                                                    <td></td>
                                                                                    <td colSpan={2}>{moment(item.departure_date).format('Do MMM. YYYY')}</td>
                                                                                    <td colSpan={2}>{item.departure_time}</td>
                                                                                    <td colSpan={2}>{item.departure_mode_data?.travel_mode_name}</td>
                                                                                    <td colSpan={2}>{moment(item.created_date).format('Do MMM. YYYY')}</td>
                                                                                    <td colSpan={2}>{moment(item.created_date).format('hh:mm a')}</td>
                                                                                    <td>
                                                                                        <span 
                                                                                            onClick={() => {
                                                                                                setShowArchiveModal(true);
                                                                                                setLeaveData(item);
                                                                                            }}
                                                                                            className='text-warning cursor-pointer'
                                                                                        >
                                                                                            Archive?
                                                                                        </span>
                                                                                        {/* <IconButton
                                                                                            onClick={() => {
                                                                                                setShowArchiveModal(true);
                                                                                                setLeaveData(item);
                                                                                            }}
                                                                                        >
                                                                                            <i className="bi bi-bell-slash me-2 fs-6"></i> 
                                                                                        </IconButton> */}
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                )
                                                            })}
                                                        </MDBTableBody>
                                                    </MDBTable>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        defaultValue={row_limit[1]}
                                                        name="color"
                                                        options={row_limit}
                                                        onChange={(e) => setLimit(e.value)}
                                                    />
                                                    <Stack>
                                                        <Pagination
                                                            count={Math.ceil(count / limit)}
                                                            page={page}
                                                            onChange={(e, value) => setPage(value)}
                                                            color="primary"
                                                        />
                                                    </Stack>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}
// export default ManageStudentDeparture;

function mapStateToProps(state) {
    return {

    };
}

const mapDispatchToProps = {
    onArchiveOrUnarchiveBorderLeave: userActions.ArchiveOrUnarchiveBorderLeave,
    onDepartStuLeaveChange: userActions.DepartStuLeaveChange,
    onGetDepartureStudentDetails: userActions.GetDepartureStudentDetails,
    onDepartStuLeaveChange: userActions.DepartStuLeaveChange
};
export default connect(mapStateToProps, mapDispatchToProps)(ManageStudentDeparture);