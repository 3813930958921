import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Auth User

import SignIn from "./containers/Auth/signin";
import Logout from "./containers/Auth/logout";
import ForgotPassword from "./containers/Auth/ForgotPassword"

// superadmin User
import SuperAdminDashboard from "./containers/Superadmin/School";

// Admin User
import School from './containers/School';
import Snapshot from './containers/Snapshot';
// import Dashboardrollcall from './containers/Dashboard';
import Manager from './containers/Manager';
import Dashboard from './component/Dashboard/dashboard';

import Class from "./containers/Classes";
import Dormitory from "./containers/Dormitory";
import Reason from "./containers/Reason";
import Permission from "./containers/permission";
import Report from "./containers/Reports";
import Message from "./containers/Messages";
import CustomLinks from "./containers/CustomLink";
import Student from "./containers/Student";

import AuthRouter from "./AuthRouter";
import ProtectedRouter from "./ProtectedRouters";
import SuperAdminRouter from "./SuperAdminRouter";
import ResetPassword from "./containers/Auth/ResetPassword";

import Settings from "./containers/Settings"
import ManagerAccount from "./component/Account/ManagerAccount";
import Activities from "./component/Activities/Activities";

import ManageLeave from "./containers/Leave/ManageLeave"
import Reports from "./containers/Leave/Reports";

import HostLeaveApproval from "./containers/Auth/HostLeaveApproval";

const permission_info = JSON.parse(localStorage.getItem("permissions"));
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;


const Router = (props) => (
        <Routes>
                {/*** Auth Route ***/}
                <Route path="/" element={<AuthRouter><SignIn /></AuthRouter>} />
                <Route path="/login" element={<AuthRouter><SignIn /></AuthRouter>} />
                {<Route path="/logout" exact element={<Logout />} />}
                <Route path="/forgot-password" element={<AuthRouter><ForgotPassword /></AuthRouter>} />
                <Route path="/reset-password" element={<AuthRouter><ResetPassword /></AuthRouter>} />
                <Route path="/host-leave-approval/:uuid/:school_code/:country_host/:timezone1/:timezone2" element={<AuthRouter><HostLeaveApproval/></AuthRouter>} />
                <Route path="/account" element={<ProtectedRouter> <ManagerAccount /> </ProtectedRouter>} />

                {/*** Superadmin Route ***/}
                <Route path='superadmin/dashboard' element={<SuperAdminRouter><SuperAdminDashboard /> </SuperAdminRouter>} />
                <Route path='superadmin/school' element={<SuperAdminRouter><SuperAdminDashboard /> </SuperAdminRouter>} />
                <Route path='superadmin/school/school-details/:id' element={<SuperAdminRouter><SuperAdminDashboard /> </SuperAdminRouter>} />
                <Route path='superadmin/add-school' element={<SuperAdminRouter><SuperAdminDashboard /></SuperAdminRouter>} />
                <Route path='superadmin/edit-school/:id' element={<SuperAdminRouter><SuperAdminDashboard /></SuperAdminRouter>} />
                <Route path='superadmin/edit-boarding-head/:id' element={<SuperAdminRouter><SuperAdminDashboard /></SuperAdminRouter>} />
                <Route path='superadmin/subscriptions' element={<SuperAdminRouter><SuperAdminDashboard /></SuperAdminRouter>} />


                {/*** Country state and city Route ***/}
                <Route path='superadmin/country' element={<SuperAdminRouter><SuperAdminDashboard /></SuperAdminRouter>} />
                <Route path='superadmin/state/:id' element={<SuperAdminRouter><SuperAdminDashboard /></SuperAdminRouter>} />
                <Route path='superadmin/city/:country_id/:state_id' element={<SuperAdminRouter><SuperAdminDashboard /></SuperAdminRouter>} />

                {/*** Admin Route ***/}
                {/*** Dashboard Route ***/}

                {/* <Route path="/dashboard" element={<ProtectedRouter>< Dashboard /> </ProtectedRouter>}></Route> */}

                <Route path={`/${school_code}/dashboard`} element={<ProtectedRouter>< Dashboard /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}`} element={<ProtectedRouter>< Dashboard /> </ProtectedRouter>}></Route>
                {/* {console.log("route",`${school_code}/dashboard`)} */}
                {/* <Route path='/snapshot' element={<ProtectedRouter><Snapshot></Snapshot> </ProtectedRouter>}></Route> */}
                <Route path={`/${school_code}/snapshot`} element={<ProtectedRouter><Snapshot></Snapshot> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/conduct-rollcall`} element={<ProtectedRouter><Snapshot></Snapshot> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/rollcall-report`} element={<ProtectedRouter><Snapshot></Snapshot> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/activities`} element={<ProtectedRouter><Activities></Activities></ProtectedRouter>}></Route>

                {/* <Route path='/rollcall-count' element={<ProtectedRouter><Dashboardrollcall></Dashboardrollcall> </ProtectedRouter>}></Route>
                <Route path='/discarded-rollcall' element={<ProtectedRouter><Dashboardrollcall></Dashboardrollcall> </ProtectedRouter>}></Route> */}


                {permission_info?.view_school_profile !== false && (<Route path={`${school_code}/school/:id`} element={<ProtectedRouter><School /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_school_profile !== false && (<Route path={`${school_code}/school/edit-school/:id`} element={<ProtectedRouter><School /> </ProtectedRouter>}></Route>)}

                {permission_info?.view_user !== false && (<Route path={`${school_code}/manager-list`} element={<ProtectedRouter><Manager /> </ProtectedRouter>}></Route>)}
                {permission_info?.add_user !== false && (<Route path={`${school_code}/manager-list/add-manager`} element={<ProtectedRouter><Manager /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_user !== false && (<Route path={`${school_code}/manager-list/edit-manager/:id`} element={<ProtectedRouter><Manager /> </ProtectedRouter>}></Route>)}
                {permission_info?.view_user !== false && (<Route path={`${school_code}/manager-list/manager-profile/:id`} element={<ProtectedRouter><Manager /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_user !== false && (<Route path={`${school_code}/manager-list/edit-manager-permission`} element={<ProtectedRouter><Manager /> </ProtectedRouter>}></Route>)}
                {permission_info?.view_user !== false && (<Route path={`${school_code}/manager-list/manager-history`} element={<ProtectedRouter><Manager /> </ProtectedRouter>}></Route>)}


                {permission_info?.view_class !== false && (<Route path={`${school_code}/class-list`} element={<ProtectedRouter><Class /> </ProtectedRouter>}></Route>)}
                {permission_info?.add_class !== false && (<Route path={`${school_code}/class-list/add-class`} element={<ProtectedRouter><Class /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_class !== false && (<Route path={`${school_code}/class-list/edit-class/:id`} element={<ProtectedRouter><Class /> </ProtectedRouter>}></Route>)}

                {permission_info?.view_dormitory !== false && (<Route path={`${school_code}/dormitory-list`} element={<ProtectedRouter><Dormitory /> </ProtectedRouter>}></Route>)}
                {permission_info?.add_dormitory !== false && (<Route path={`${school_code}/dormitory-list/add-dormitory`} element={<ProtectedRouter><Dormitory /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_dormitory !== false && (<Route path={`${school_code}/dormitory-list/edit-dormitory/:id`} element={<ProtectedRouter><Dormitory /> </ProtectedRouter>}></Route>)}
                <Route path={`${school_code}/settings/change-dormitory`} element={<ProtectedRouter><Dormitory /> </ProtectedRouter>}></Route>

                <Route path={`${school_code}/reason-list`} element={<ProtectedRouter><Reason /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/reason-list/add-reason`} element={<ProtectedRouter><Reason /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/reason-list/edit-reason`} element={<ProtectedRouter><Reason /> </ProtectedRouter>}></Route>

                {permission_info?.view_permission !== false && (<Route path={`${school_code}/permissions-list`} element={<ProtectedRouter><Permission /> </ProtectedRouter>}></Route>)}
                {permission_info?.add_permission !== false && (<Route path={`${school_code}/permissions-list/add-permission`} element={<ProtectedRouter><Permission /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_permission !== false && (<Route path={`${school_code}/permissions-list/edit-permission/:id`} element={<ProtectedRouter><Permission /> </ProtectedRouter>}></Route>)}
                {/* <Route path='/permissions-list/hostorical-permission/:id' element={<ProtectedRouter><Permission /> </ProtectedRouter>}></Route> */}

                <Route path={`${school_code}/student/allergic-student`} element={<ProtectedRouter><Student /></ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/flag-student`} element={<ProtectedRouter><Student /></ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/grounded-student`} element={<ProtectedRouter><Student /></ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/import-student`} element={<ProtectedRouter><Student /></ProtectedRouter>}></Route>


                {permission_info?.view_reports !== false && (<Route path={`${school_code}/settings/reports`} element={<ProtectedRouter><Report /> </ProtectedRouter>}></Route>)}
                {permission_info?.view_message !== false && (<Route path={`${school_code}/settings/messages`} element={<ProtectedRouter><Message /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_message !== false && (<Route path={`${school_code}/settings/messages/edit-message/:id`} element={<ProtectedRouter><Message /> </ProtectedRouter>}></Route>)}
                <Route path={`${school_code}/settings/messages/add-message`} element={<ProtectedRouter><Message /> </ProtectedRouter>}></Route>

                <Route path={`${school_code}/student/allergic-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/flag-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/grounded-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/duplicate-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                {permission_info?.add_student !== false && (<Route path={`${school_code}/student/add-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>)}
                {permission_info?.edit_student !== false && (<Route path={`${school_code}/student/student-list/edit-student/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>)}
                <Route path={`${school_code}/student/grounded-student/add-grounded-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/flag-student/add-generic-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/allergic-student/add-allergic-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/allergic-student/edit-allergic-student/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/grounded-student/edit-grounded-student/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/flag-student/edit-generic-student/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/allergic-student/edit-allergic-student`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                {permission_info?.view_student !== false && (<Route path={`${school_code}/student/student-list/student-profile/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>)}
                <Route path={`${school_code}/student/student-list/approved-host/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/leave-history/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/attendance-history/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/pastoral-notes/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/medical-notes/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/allergy-reports/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/adhoc-leave/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/adhoc-actual/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/file-storage/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/student/student-list/student-activities/:id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>



                {permission_info?.create_tempid !== false && (<Route path={`${school_code}/student/student-list/temp-id`} element={<ProtectedRouter><Student /> </ProtectedRouter>}></Route>)}

                <Route path={`${school_code}/settings/calendar-holidays`} element={<ProtectedRouter> <Settings /> </ProtectedRouter>}></Route>

                <Route path={`${school_code}/settings/on-campus-location`} element={<ProtectedRouter> <Settings /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/on-campus-location/edit-on-campus-location/:id`} element={<ProtectedRouter> <Settings /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/custom-link`} element={<ProtectedRouter><CustomLinks /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/custom-link/edit-custom-link/:id`} element={<ProtectedRouter><CustomLinks /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/custom-link/add-custom-link`} element={<ProtectedRouter><CustomLinks /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/rollover-student`} element={<ProtectedRouter><Settings /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/roll-call/:id`} element={<ProtectedRouter><Settings /> </ProtectedRouter>}></Route>
                <Route path={`${school_code}/settings/roll-call`} element={<ProtectedRouter><Settings /> </ProtectedRouter>}></Route>






                {/* border leave  routes */}

                <Route path={`/${school_code}/leave/manage-boarders-leave`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/boarders-leave-details`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/present-student-list`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/archive-leaves`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/manage-student-departure`} element={<ProtectedRouter><ManageLeave /></ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/apply-border-leave/:id`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/edit-boarders-leave/:border_leave_id`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/student-return`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>

                {/* adhoc leave routes   */}

                <Route path={`/${school_code}/leave/Adhoc-leave-details`} element={<ProtectedRouter><ManageLeave /></ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/present-student-list-for-adhoc`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/apply-adhoc-leave`} element={<ProtectedRouter><ManageLeave /></ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/edit-adhoc-leave/:adhoc_leave_detail_uuid`} element={<ProtectedRouter><ManageLeave /></ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/sign-boarders-out-in`} element={<ProtectedRouter><ManageLeave /></ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/activity-list`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/ad-hoc-activity-off-campus/add-ad-hoc-activity`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/ad-hoc-activity-off-campus/edit-ad-hoc-activity/:id`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>

                {/* leave settings routes */}

                <Route path={`/${school_code}/leave/manage-host`} element={<ProtectedRouter><ManageLeave /></ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/manage-leave-rejection-reason`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/manage-leave-rejection-reason/add-manage-leave-rejection-reason`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/manage-leave-rejection-reason/edit-manage-leave-rejection-reason/:id`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/manage-mode-of-travels`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/manage-mode-of-travels/add-manage-mode-of-travels`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/manage-mode-of-travels/edit-mode-of-travels/:id`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/host-responsibility`} element={<ProtectedRouter><ManageLeave /> </ProtectedRouter>}></Route>

                {/* reports routes */}

                <Route path={`/${school_code}/leave/reports/approved-leave-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/non-return-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/departure-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/due-back-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/catering-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/border-leave-history-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/adhoc-leave-history-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/student-connect-to-host-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/adhoc-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/allergic-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/host-connect-to-student-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>
                <Route path={`/${school_code}/leave/reports/host-report`} element={<ProtectedRouter> <Reports /> </ProtectedRouter>}></Route>

                <Route path={`/${school_code}/no-permission`} element={<ProtectedRouter><Permission /> </ProtectedRouter>}></Route>
                <Route path="*" element={<Navigate to="/" replace />} />

        </Routes>
);
export default Router;