import React from "react";
import Select from "react-select";
import Sidebar from "../../../Constant/sidebar";
import Footer from "../../../Constant/Footer";
import TopBar from "../../../Constant/TopBar";
import * as userActions from "../../../../actions/index";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
// import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as actionType from "../../../../constants/actionTypes";
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Link } from "react-router-dom";
// import aprovedlogp from "../../../../../public/assets/media/host-approved.png"
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const school_code = user_info?.school_code === null || user_info === null ? "" : user_info?.school_code;
class StudentProfile extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            hiddenpassword: true,
            isOpen: false,

            edit_student_page: {
                student_first_name: {
                    type: "text",
                    label: "First Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_last_name: {
                    type: "text",
                    label: "Last Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                preferred_name: {
                    type: "text",
                    label: "Preferred Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_email: {
                    type: "email",
                    label: "Student E-mail address",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                // class_name: {
                //     type: "select",
                //     label: "Select class *",
                //     value: "",
                //     valid: true,
                //     error_msg: "",
                //     onBlur_out: false,
                //     placeholder: "",
                //     validations: { required: true },
                //     options: [],
                // },

                class_name: {
                    type: "text",
                    label: "class",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                campus_name: {
                    type: "text",
                    label: "Campus",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },
                dormitory_name: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false },
                },

                dormitory_id: {
                    type: "select",
                    label: "Dormitory",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                manager_name: {
                    type: "text",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "9876543210",
                    validations: { required: false },
                },

                user_id: {
                    type: "select",
                    label: "User id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [],
                },

                sporting_house: {
                    type: "text",
                    label: "Sporting House",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                student_phone: {
                    type: "text",
                    label: "Student's Mobile Number *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                laundry_number: {
                    type: "text",
                    label: "Laundry Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                tutor_name: {
                    type: "text",
                    label: "PC or Tutor or Carer",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                tutor_email: {
                    type: "email",
                    label: "E-mail address(PC or Tutor or Carer)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, email: true },
                },

                date_of_birth: {
                    type: "date",
                    label: "Date Of Birth",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                student_age: {
                    type: "text",
                    label: "Age",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                kiosk_mode_pin: {
                    type: "number",
                    label: "Kiosk Mode PIN",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false, min_length: 8, number: true },
                },

                salutation: {
                    type: "text",
                    label: "Salutation",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                father_email: {
                    type: "email",
                    label: "Father's Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                parent_address_data: [{
                    parent_address_type: {
                        type: "text",
                        label: "",
                        value: "father",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    address_line1: {
                        type: "text",
                        label: "Address Line 1",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    address_line2: {
                        type: "text",
                        label: "Address Line 2",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_state: {
                        type: "text",
                        label: "State",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_city: {
                        type: "text",
                        label: "City",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_postcode: {
                        type: "text",
                        label: "Post Code",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_country: {
                        type: "text",
                        label: "Country",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_address_id: "",
                },
                {
                    address_line1: {
                        type: "text",
                        label: "Address Line 1",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    address_line2: {
                        type: "text",
                        label: "Address Line 2",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_state: {
                        type: "text",
                        label: "State",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_city: {
                        type: "text",
                        label: "City",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_postcode: {
                        type: "text",
                        label: "Post Code",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_country: {
                        type: "text",
                        label: "Country",
                        value: "",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_address_type: {
                        type: "text",
                        label: "Country",
                        value: "mother",
                        valid: true,
                        error_msg: "",
                        onBlur_out: false,
                        placeholder: "",
                        validations: { required: true },
                    },
                    parent_address_id: "",
                },
                ],

                mother_email: {
                    type: "email",
                    label: "Mother's Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true, email: true },
                },

                father_name: {
                    type: "text",
                    label: "Father's Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                mother_name: {
                    type: "text",
                    label: "Mother's Name",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                father_phone: {
                    type: "text",
                    label: "Father's Mobile Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                mother_phone: {
                    type: "text",
                    label: "Mother's Mobile Number",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },

                father_home_phone: {
                    type: "text",
                    label: "Home phone(Father)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                father_work_phone: {
                    type: "text",
                    label: "Work phone(Father)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                mother_home_phone: {
                    type: "text",
                    label: "Home phone(Mother)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                mother_work_phone: {
                    type: "text",
                    label: "Work phone(Mother)",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                first_point_contact: {
                    type: "select",
                    label: "First Point of Contact(Number) *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { label: "First Point of Contact(Number)", value: "" },
                        { label: "Father's Contact Number", value: "f" },
                        { label: "Mother's Contact Number", value: "m" },
                    ],
                },

                first_point_email: {
                    type: "select",
                    label: "First Point of Contact(Email) *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                    options: [
                        { label: "First Point of Contact(Email)", value: "" },
                        { label: "Father's Email Address", value: "f" },
                        { label: "Mother's Email Address", value: "m" },
                    ],
                },

                show_parent_detail_app: {
                    type: "checkbox",
                    label: "Show Parent Details on Liberty App",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                exclude_automatic_host: {
                    type: "checkbox",
                    label: "Exclude as automatic Host ",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                allergy_unauth_access: {
                    type: "checkbox",
                    label: "Allow Unauthenticated Access to student Allergy or Emergency details ",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                withdraw_leave: {
                    type: "checkbox",
                    label: "Withdraw Ad-Hoc Leave",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                permitted_student_app_signout: {
                    type: "checkbox",
                    label: "Permitted to Sign Out using Student App",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                display_image_required: {
                    type: "radio",
                    label: "Want to display Image? * ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                student_allergy_status: {
                    type: "radio",
                    label: "Allergic",
                    value: "",
                    valid: true,
                    status: false,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: false },
                },

                gender: {
                    type: "radio",
                    label: "Gender * ",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    validations: { required: true },
                },

                student_avatar: {
                    label: "File",
                    type: "file",
                    value: '',
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                    // thumbnail_image: "/assets/media/school-logo.png",
                    thumbnail_image: "",
                },
                school_detail_id: {
                    type: "hidden",
                    label: "",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: false },
                },

                student_uuid: {
                    type: "text",
                    label: "student uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },

                parent_uuid: {
                    type: "text",
                    label: "student uuid",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                student_id: {
                    type: "text",
                    label: "student id",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "school_detail_uuid",
                    validations: { required: false },
                },
                country_code: {
                    type: "hidden",
                    label: "country_host",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    // placeholder: "dikonia@gmail.com",
                    validations: { required: false },
                },

                allergy_data:
                    [{
                        allergy_name: {
                            type: "text",
                            label: "Allergic *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Allergic",
                            validations: { required: false },
                        },

                        allergy_info: {
                            type: "text",
                            label: "Allergic Info *",
                            value: "",
                            valid: true,
                            error_msg: "",
                            onBlur_out: false,
                            placeholder: "Allergic",
                            validations: { required: false },
                        },

                    }]

            },

        };
        this.toggleShow = this.toggleShow.bind(this);
    }

    toggleShow() {
        this.setState({ hiddenpassword: !this.state.hiddenpassword });
    }

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    componentDidMount() {
        this.GetAllDormitoryStudent();
        this.GetStudentAndParentById();
        this.GetAllManagersBySchoolIdStudent();
        this.GetAllClassesDetailsStudent();
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
        const update_edit_student_page = { ...this.state.edit_student_page };
        update_edit_student_page['country_code'].value = user_info.user_school.country_code;
        this.setState({ edit_student_page: update_edit_student_page });
    }
    GetStudentAndParentById() {
        this.props.onGetStudentAndParentById(this.props.user_uuid).then((response) => {
            if (response.success === true) {
                let student_data = response.data;
                const update_edit_student_page = { ...this.state.edit_student_page };
                for (let key in update_edit_student_page) {
                    let form_element = update_edit_student_page[key];
                    if (student_data[key]) {
                        if (student_data.student_avatar !== null && student_data.student_avatar !== '') {
                            update_edit_student_page['student_avatar'].thumbnail_image = actionType.environment_url + '/' + student_data.student_avatar;
                            update_edit_student_page['student_avatar'].value = student_data.student_avatar;
                        }
                        if (key === 'class_name') {
                            form_element.value = parseInt(student_data[key]);
                        }
                        else {
                            form_element.value = student_data[key];
                        }
                        // update_edit_student_page['manager_name'].value = student_data.manager_data.first_name + " " + student_data.manager_data.last_name;
                        update_edit_student_page['student_id'].value = student_data.student_id;
                        update_edit_student_page['dormitory_name'].value = student_data.dormitory_data.dormitory_name;
                        update_edit_student_page['class_name'].value = student_data.class_name;
                        update_edit_student_page['student_first_name'].value = student_data.student_first_name;
                        update_edit_student_page['student_last_name'].value = student_data.student_last_name;
                        update_edit_student_page['preferred_name'].value = student_data.preferred_name;
                        update_edit_student_page['student_email'].value = student_data.student_email;
                        update_edit_student_page['campus_name'].value = student_data.campus_name;
                        update_edit_student_page['sporting_house'].value = student_data.sporting_house;
                        update_edit_student_page['laundry_number'].value = student_data.laundry_number;
                        update_edit_student_page['tutor_name'].value = student_data.tutor_name;
                        update_edit_student_page['tutor_email'].value = student_data.tutor_email;
                        update_edit_student_page['date_of_birth'].value = student_data.date_of_birth;
                        update_edit_student_page['student_age'].value = student_data.student_age;
                        // update_edit_student_page['salutation'].value = student_data.salutation;
                        update_edit_student_page['gender'].value = student_data.gender;

                        update_edit_student_page['display_image_required'].value = student_data.display_image_required;
                        update_edit_student_page['display_image_required'].isChecked = student_data.display_image_required;
                        update_edit_student_page['permitted_student_app_signout'].value = student_data.permitted_student_app_signout;
                        update_edit_student_page['permitted_student_app_signout'].isChecked = student_data.permitted_student_app_signout;
                        update_edit_student_page['withdraw_leave'].value = student_data.withdraw_leave;
                        update_edit_student_page['withdraw_leave'].isChecked = student_data.withdraw_leave;
                        update_edit_student_page['allergy_unauth_access'].value = student_data.allergy_unauth_access;
                        update_edit_student_page['allergy_unauth_access'].isChecked = student_data.allergy_unauth_access;
                        update_edit_student_page['exclude_automatic_host'].value = student_data.exclude_automatic_host;
                        update_edit_student_page['exclude_automatic_host'].isChecked = student_data.exclude_automatic_host;
                        update_edit_student_page['show_parent_detail_app'].value = student_data.show_parent_detail_app;
                        update_edit_student_page['show_parent_detail_app'].isChecked = student_data.show_parent_detail_app;


                        update_edit_student_page['student_uuid'].value = student_data.student_uuid;

                        this.setState({ edit_student_page: update_edit_student_page });
                        update_edit_student_page[key] = form_element;
                        console.log("updated edit_student_page", update_edit_student_page)
                    }
                }

                if (student_data.parent_data) {

                    update_edit_student_page['father_email'].value = student_data.parent_data.father_email;
                    update_edit_student_page['mother_email'].value = student_data.parent_data.mother_email;
                    update_edit_student_page['father_name'].value = student_data.parent_data.father_name;
                    update_edit_student_page['mother_name'].value = student_data.parent_data.mother_name;
                    update_edit_student_page['father_phone'].value = student_data.parent_data.father_phone;
                    update_edit_student_page['mother_phone'].value = student_data.parent_data.mother_phone;
                    update_edit_student_page['father_home_phone'].value = student_data.parent_data.father_home_phone;
                    update_edit_student_page['father_work_phone'].value = student_data.parent_data.father_work_phone;
                    update_edit_student_page['mother_home_phone'].value = student_data.parent_data.mother_home_phone;
                    update_edit_student_page['mother_work_phone'].value = student_data.parent_data.mother_work_phone;
                    update_edit_student_page['parent_uuid'].value = student_data.parent_data.parent_uuid;
                    update_edit_student_page['salutation'].value = student_data.parent_data.salutation;
                    if (student_data.parent_data.parent_address) {
                        console.log("student_data.parent_address", student_data.parent_data.parent_address)
                        const fatherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'father')
                        console.log("fatherAddress", fatherAddress)
                        if (fatherAddress) {
                            update_edit_student_page['parent_address_data'][0].address_line1.value = fatherAddress.address_line1;
                            update_edit_student_page['parent_address_data'][0].address_line2.value = fatherAddress.address_line2;
                            update_edit_student_page['parent_address_data'][0].parent_state.value = fatherAddress.parent_state;
                            update_edit_student_page['parent_address_data'][0].parent_city.value = fatherAddress.parent_city;
                            update_edit_student_page['parent_address_data'][0].parent_country.value = fatherAddress.parent_country;
                            update_edit_student_page['parent_address_data'][0].parent_postcode.value = fatherAddress.parent_postcode;
                            update_edit_student_page['parent_address_data'][0].parent_address_id = fatherAddress.parent_address_id;
                            update_edit_student_page['parent_address_data'][0].parent_address_type.value = fatherAddress.parent_address_type;
                            this.setState({ selectedfatherCountry: fatherAddress.parent_country });
                        }
                        const motherAddress = student_data.parent_data.parent_address.find(address => address.parent_address_type === 'mother')
                        console.log("motherAddress", motherAddress)
                        if (motherAddress) {
                            update_edit_student_page['parent_address_data'][1].address_line1.value = motherAddress.address_line1;
                            update_edit_student_page['parent_address_data'][1].address_line2.value = motherAddress.address_line2;
                            update_edit_student_page['parent_address_data'][1].parent_state.value = motherAddress.parent_state;
                            update_edit_student_page['parent_address_data'][1].parent_city.value = motherAddress.parent_city;
                            update_edit_student_page['parent_address_data'][1].parent_country.value = motherAddress.parent_country;
                            update_edit_student_page['parent_address_data'][1].parent_postcode.value = motherAddress.parent_postcode;
                            update_edit_student_page['parent_address_data'][1].parent_address_id = motherAddress.parent_address_id;
                            update_edit_student_page['parent_address_data'][1].parent_address_type.value = motherAddress.parent_address_type;
                            this.setState({ selectedmotherCountry: motherAddress.parent_country });
                        }
                        if (student_data.parent_data.mother_name && fatherAddress && !motherAddress) {
                            update_edit_student_page['parent_address_data'][1].address_line1.value = fatherAddress.address_line1;
                            update_edit_student_page['parent_address_data'][1].address_line2.value = fatherAddress.address_line2;
                            update_edit_student_page['parent_address_data'][1].parent_state.value = fatherAddress.parent_state;
                            update_edit_student_page['parent_address_data'][1].parent_city.value = fatherAddress.parent_city;
                            update_edit_student_page['parent_address_data'][1].parent_country.value = fatherAddress.parent_country;
                            update_edit_student_page['parent_address_data'][1].parent_postcode.value = fatherAddress.parent_postcode;
                            update_edit_student_page['parent_address_data'][1].parent_address_id = fatherAddress.parent_address_id;
                            update_edit_student_page['parent_address_data'][1].parent_address_type.value = fatherAddress.parent_address_type;
                            this.setState({ selectedmotherCountry: fatherAddress.parent_country });
                        }
                    }

                }

                this.setState({ edit_student_page: update_edit_student_page });
                console.log("check edit_student_page update -- API call---", update_edit_student_page)
            } else {
            }
        });

    }

    GetAllClassesDetailsStudent = async (form_data) => {
        this.props.onGetAllClassesDetailsStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Class",
                value: "",
            });
            const update_edit_student_page = { ...this.state.edit_student_page };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].class_name,
                        value: arrayData[key].class_name,
                        class_name: arrayData[key].class_name,
                    });
                }
            }
            update_edit_student_page['class_name'].options = country_level;
            this.setState({ edit_student_page: update_edit_student_page });
        });
    }

    GetAllDormitoryStudent = async (form_data) => {
        this.props.onGetAllDormitoryStudent().then((response) => {
            let country_level = [];
            country_level.push({
                label: "Select Dormitory",
                value: "",
            });
            const update_edit_student_page = { ...this.state.edit_student_page };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].dormitory_name,
                        value: arrayData[key].dormitory_id,
                    });
                }
            }
            update_edit_student_page['dormitory_id'].options = country_level;
            this.setState({ edit_student_page: update_edit_student_page });
        });
    }

    GetAllManagersBySchoolIdStudent = async (form_data) => {
        this.props.onGetAllManagersBySchoolIdStudent().then((response) => {
            let state_level = [];
            state_level.push({
                label: "Select Dormitory Head",
                value: "",
            });
            const update_edit_student_page = { ...this.state.edit_student_page };
            if (response.success === true) {
                const arrayData = response.data.rows;
                for (let key in arrayData) {
                    if (arrayData[key].is_user_activate === true) {
                        state_level.push({
                            label: arrayData[key].first_name + " " + arrayData[key].last_name,
                            value: arrayData[key].user_id,

                        });
                    }
                }
            }
            update_edit_student_page['user_id'].options = state_level;
            this.setState({ edit_student_page: update_edit_student_page });
        });
    }

    render() {
        const { edit_student_page } = this.state;
        console.log("edit_student_page========", edit_student_page);
        const user_info = JSON.parse(localStorage.getItem("bss_authuser"));

        let curr = new Date();
        curr.setDate(curr.getDate());
        let date = curr.toISOString().substring(0, 10);
        let setEnterUserAge = edit_student_page.date_of_birth.value;
        let setNew = new Date(setEnterUserAge)
        let d = new Date();
        let age_now = d.getFullYear() - setNew.getFullYear();
        let monthsDiff = d.getMonth() - setNew.getMonth();
        let dayDiff = d.getDate() - setNew.getDate();
        if (dayDiff < 0) {
            let tempDate = new Date(d.getFullYear(), d.getMonth() - 1, 0);
            dayDiff += tempDate.getDate();
            monthsDiff--;
            // monthsDiff--;
            // dayDiff += new Date(d.getFullYear(), d.getMonth(), 0).getDate();
        }
        if (monthsDiff < 0 || (monthsDiff === 0 && dayDiff < 0)) {
            age_now--;
            monthsDiff += 12;
        }
        let years = age_now > 0 ? age_now + " year" + (age_now > 1 ? "s" : "") : "";
        let months = monthsDiff > 0 ? monthsDiff + " month" + (monthsDiff > 1 ? "s" : "") : "";
        let year = years && months ? years + " " + months : years + months;
        edit_student_page.student_age.value = year;
        return (
            <React.Fragment>
                {this.state.isAuthenticated ? <Navigate to={this.state.redirect_url} /> : null}
                <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed demo h-100">
                    <div className="d-flex flex-column flex-root h-100">
                        <div className="page d-flex flex-row flex-column-fluid">
                            <Sidebar></Sidebar>

                            <div className="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
                                <TopBar></TopBar>
                                <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                                    <div className="post d-flex flex-column-fluid" id="kt_post">
                                        <div id="kt_content_container" className="container-fluid">
                                            <ul className="nav nav-tabs home-tabs" id="myTab" role="tablist" style={{display:"flex", justifyContent:"space-between", alignItems:"flex-end"}}>
                                                <div style={{display:"flex", overflowX: "scroll"}}>    
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/student-profile/${this.props.user_uuid}`} className="nav-link  active" style={{whiteSpace: "nowrap"}} id="StudentProfile-tab" type="button" aria-controls="home" aria-selected="true">
                                                            Student Profile
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/approved-host/${this.props.user_uuid}`} className="nav-link" id="ApprovedHosts-tab" style={{whiteSpace: "nowrap"}} type="button" aria-controls="process" aria-selected="true">
                                                            Approved Hosts
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/leave-history/${this.props.user_uuid}`} className="nav-link" id="LeaveHistory-tab" style={{whiteSpace: "nowrap"}} type="button" aria-controls="process" aria-selected="false">
                                                            Leave History
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/attendance-history/${this.props.user_uuid}`} className="nav-link" id="AttendanceHistory-tab"  style={{whiteSpace: "nowrap"}} type="button" aria-controls="process" aria-selected="false">
                                                            Attendance History
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/pastoral-notes/${this.props.user_uuid}`} className="nav-link" id="PastoralNotes-tab" type="button" style={{whiteSpace: "nowrap"}}  aria-controls="process" aria-selected="false">
                                                            Pastoral Notes
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/medical-notes/${this.props.user_uuid}`} className="nav-link" id="MedicalNotes-tab" type="button" style={{whiteSpace: "nowrap"}}  aria-controls="process" aria-selected="false">
                                                            Medical Notes
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/allergy-reports/${this.props.user_uuid}`} className="nav-link" id="AllergyReport-tab" type="button" style={{whiteSpace: "nowrap"}}  aria-controls="process" aria-selected="false">
                                                            Allergy Reports
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/adhoc-leave/${this.props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}}  aria-controls="process" aria-selected="false">
                                                            AdhocLeave
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/adhoc-actual/${this.props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}}  aria-controls="process" aria-selected="false">
                                                            Adhoc Actual
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/file-storage/${this.props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}}  aria-controls="process" aria-selected="false">
                                                            File Storage
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item me-6" role="presentation">
                                                        <Link to={`/${school_code}/student/student-list/student-activities/${this.props.user_uuid}`} className="nav-link" id="AdhocLeave-tab" type="button" style={{whiteSpace: "nowrap"}}  aria-controls="process" aria-selected="false">
                                                            Student Activities
                                                        </Link>
                                                    </li>
                                                </div>
                                                <div className="d-flex align-items-center gap-2">
                                                    <b> {this.props?.studentData?.student_first_name ? this.props?.studentData?.student_first_name + " " + this.props?.studentData?.student_last_name : "BSS"}</b>
                                                    <div id="topaccountmenudata" className="symbol symbol-30px symbol-md-40px">
                                                        <img alt="Logo" style={{marginBottom:"4px"}} 
                                                            src={
                                                                this.props?.studentData?.student_avatar !== null && this.props?.studentData?.student_avatar !== "" 
                                                                ? "https://bssnew.dikonia.in/" + this.props?.studentData?.student_avatar
                                                                : (this.props?.studentData?.gender === "male" 
                                                                    ? "https://bssnew.dikonia.in/media/avatars/boy.jpg" 
                                                                    : "https://bssnew.dikonia.in/media/avatars/girl.jpg")

                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="StudentProfile" role="tabpanel" aria-labelledby="StudentProfile-tab">
                                                    <form onSubmit={this.SubmitHandler}>
                                                        <div className="card mb-5 tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg">
                                                                {/* <!--begin::Card title--> */}
                                                                <h2 className="card-title  m-0">Student Profile</h2>
                                                                {/* <div className="card-toolbar">
                                                                    <span className="view-id ms-4" >ID</span>
                                                                </div> */}
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-xl-4">
                                                                        <div className="col-lg-12 fv-row mb-5">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">

                                                                                    <div className="profile-image-out">

                                                                                        <div className="d-flex">
                                                                                            <div className="col-sm-12">                                                                                                <img
                                                                                                alt="profile"
                                                                                                className="img-fluid"
                                                                                                src={
                                                                                                    edit_student_page?.student_avatar?.thumbnail_image !== "" ? edit_student_page?.student_avatar?.thumbnail_image
                                                                                                        :
                                                                                                        (edit_student_page.gender.value === "male" ? "/media/avatars/boy.jpg" : "/media/avatars/girl.jpg")

                                                                                                }
                                                                                            />

                                                                                                <div className=" h-100 flex-column align-items-center">
                                                                                                    <input
                                                                                                        hidden
                                                                                                        type="file"
                                                                                                        accept=".png, .jpg, .jpeg"
                                                                                                        onBlur={(event) => {
                                                                                                            edit_student_page.userName.onBlur_out = true;
                                                                                                            this.setState({
                                                                                                                edit_student_page: edit_student_page,
                                                                                                            });
                                                                                                        }}
                                                                                                        readOnly
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-block">
                                                                            <input
                                                                                type={edit_student_page?.school_detail_id?.type}
                                                                                value={user_info.user_school.school_detail_id ? user_info.user_school.school_detail_id : ""}
                                                                                className="form-control"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-8 col-xl-8">
                                                                        <div className="row">
                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page?.student_first_name?.label}</label>
                                                                                <input
                                                                                    type={edit_student_page?.student_first_name?.type}
                                                                                    value={edit_student_page?.student_first_name?.value ? edit_student_page?.student_first_name?.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page?.student_first_name?.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page?.student_last_name?.label}</label>
                                                                                <input
                                                                                    type={edit_student_page.student_last_name.type}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.student_last_name.placeholder}
                                                                                    value={edit_student_page.student_last_name.value ? edit_student_page.student_last_name.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page?.preferred_name?.label}</label>
                                                                                <input
                                                                                    type={edit_student_page?.preferred_name?.type}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.preferred_name.placeholder}
                                                                                    value={edit_student_page.preferred_name.value ? edit_student_page.preferred_name.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>



                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2 ">{edit_student_page.student_email.label}</label>

                                                                                <input
                                                                                    type={edit_student_page.student_email.type}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.student_email.placeholder}
                                                                                    value={edit_student_page.student_email.value ? edit_student_page.student_email.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.student_phone.label}</label>
                                                                                <ReactPhoneInput
                                                                                    countryCodeEditable={false}
                                                                                    country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                                    value={edit_student_page.student_phone.value ? edit_student_page.student_phone.value : ""}
                                                                                    onChange={(event) => this.inputChangeHandler(event, "student_phone")}
                                                                                    enableLongNumbers={true}
                                                                                    disabled
                                                                                    autoComplete="off"
                                                                                />
                                                                                {!edit_student_page.student_phone.valid && edit_student_page.student_phone.onBlur_out ? <div className="error field-error">{edit_student_page.student_phone.error_msg}</div> : ""}
                                                                            </div>
                                                                            <div className="col-lg-4 col-xl-4  fv-row mb-5">
                                                                                <label className="fw-bold mb-2">Gender * </label>
                                                                                <div className="d-flex mt-3">
                                                                                    <label className="cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="Gender"
                                                                                            name="Gender"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            checked={edit_student_page.gender.value === "male" ? true : false}
                                                                                            value="male"
                                                                                        />
                                                                                        Male
                                                                                    </label>
                                                                                    <label className="ms-8 cursor-pointer">
                                                                                        <input
                                                                                            type="radio"
                                                                                            id="Gender1"
                                                                                            name="Gender"
                                                                                            className="form-check-input me-2 cursor-pointer"
                                                                                            value="female"
                                                                                            checked={edit_student_page.gender.value === "female" ? true : false}
                                                                                        />
                                                                                        Female
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.date_of_birth.label}</label>
                                                                                <input
                                                                                    type={edit_student_page.date_of_birth.type}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.date_of_birth.placeholder}
                                                                                    value={edit_student_page.date_of_birth.value ? edit_student_page.date_of_birth.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.student_age.label}</label>
                                                                                <input
                                                                                    // style = {{ backgroundColor: "lightgray"}}
                                                                                    type={edit_student_page.student_age.type}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.student_age.placeholder}
                                                                                    value={edit_student_page.student_age.value ? edit_student_page.student_age.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-4  col-xl-4 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.class_name.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control "
                                                                                    value={edit_student_page.class_name.value ? edit_student_page.class_name.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                          

                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="row">
                                                                        <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.campus_name.label} </label>
                                                                                <input
                                                                                    type={edit_student_page.campus_name.type}
                                                                                    value={edit_student_page.campus_name.value ? edit_student_page.campus_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.campus_name.placeholder}
                                                                                    readOnly
                                                                                />
                                                                                {!edit_student_page.campus_name.valid && edit_student_page.campus_name.onBlur_out ? <div className="error field-error">{edit_student_page.campus_name.error_msg}</div> : ""}
                                                                            </div>


                                                                            <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.dormitory_id.label}</label>
                                                                                <input
                                                                                    type="text"
                                                                                    name="fname"
                                                                                    className="form-control "
                                                                                    value={edit_student_page.dormitory_name.value ? edit_student_page.dormitory_name.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-3 fv-row mb-5">

                                                                                <label className="fw-bold mb-2">{edit_student_page.sporting_house.label} </label>
                                                                                <input
                                                                                    type={edit_student_page.sporting_house.type}
                                                                                    value={edit_student_page.sporting_house.value ? edit_student_page.sporting_house.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.sporting_house.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.laundry_number.label} </label>
                                                                                <input
                                                                                    type={edit_student_page.laundry_number.type}
                                                                                    value={edit_student_page.laundry_number.value ? edit_student_page.laundry_number.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.laundry_number.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>
                                                                            <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.tutor_name.label} </label>
                                                                                <input
                                                                                    type={edit_student_page.tutor_name.type}
                                                                                    value={edit_student_page.tutor_name.value ? edit_student_page.tutor_name.value : ""}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.tutor_name.placeholder}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.tutor_email.label}</label>
                                                                                <input
                                                                                    type={edit_student_page.tutor_email.type}
                                                                                    className="form-control"
                                                                                    placeholder={edit_student_page.tutor_email.placeholder}
                                                                                    value={edit_student_page.tutor_email.value ? edit_student_page.tutor_email.value : ""}
                                                                                    readOnly
                                                                                />
                                                                            </div>

                                                                            <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                                <label className="fw-bold mb-2">{edit_student_page.kiosk_mode_pin.label}</label>
                                                                                <div className="position-relative">
                                                                                    <input
                                                                                        type={edit_student_page.kiosk_mode_pin.type}
                                                                                        className="form-control"
                                                                                        placeholder={edit_student_page.kiosk_mode_pin.placeholder}
                                                                                        value={edit_student_page.kiosk_mode_pin.value ? edit_student_page.kiosk_mode_pin.value : ""}
                                                                                        readOnly
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="card tabs-outer">
                                                            <div className="card-header position-relative py-0 details-bg d-flex align-items-center">
                                                                <h2 className="card-title  m-0">Parent Details</h2>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row">
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_name.label}</label>
                                                                        <input
                                                                            type={edit_student_page.father_name.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.father_name.placeholder}
                                                                            value={edit_student_page.father_name.value ? edit_student_page.father_name.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_name.label}</label>
                                                                        <input
                                                                            type={edit_student_page.mother_name.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.mother_name.placeholder}
                                                                            value={edit_student_page.mother_name.value ? edit_student_page.mother_name.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_email.label}</label>
                                                                        <input
                                                                            type={edit_student_page.father_email.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.father_email.placeholder}
                                                                            value={edit_student_page.father_email.value ? edit_student_page.father_email.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_email.label}</label>
                                                                        <input
                                                                            type={edit_student_page.mother_email.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.mother_email.placeholder}
                                                                            value={edit_student_page.mother_email.value ? edit_student_page.mother_email.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>


                                                                    <div className="mb-5">
                                                                        <h6> Father Address </h6>
                                                                        <hr />
                                                                    </div>
                                                                    {/* <div className="col-lg-6 fv-row  d-flex">
                                                                                    <label className="fw-bold"> If Parent details are same as father
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            className="form-check-input ms-4"
                                                                                            // value={host_data.is_host_approved.value}
                                                                                            // defaultChecked={host_data.is_host_approved.value}
                                                                                            // onChange={(event) => this.inputChangehostCheckbox(event)}
                                                                                            autoComplete="off"
                                                                                        /></label>
                                                                                </div> */}

                                                                    {/* <div className="col-lg-6 fv-row  d-flex ">
                                                                                    <label className="fw-bold"> 
                                                                                        <input
                                                                                            type="hidden"
                                                                                            className="form-check-input ms-4"
                                                                                            // value={host_data.is_host_approved.value}
                                                                                            // defaultChecked={host_data.is_host_approved.value}
                                                                                            // onChange={(event) => this.inputChangehostCheckbox(event)}
                                                                                            autoComplete="off"
                                                                                        /></label>
                                                                                </div> */}

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].address_line1.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].address_line1.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].address_line1.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].address_line1.value ? edit_student_page.parent_address_data[0].address_line1.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].address_line2.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].address_line2.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].address_line2.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].address_line2.value ? edit_student_page.parent_address_data[0].address_line2.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_state.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].parent_state.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].parent_state.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].parent_state.value ? edit_student_page.parent_address_data[0].parent_state.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_city.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].parent_city.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].parent_city.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].parent_city.value ? edit_student_page.parent_address_data[0].parent_city.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].address_line1.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[1].address_line1.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[1].address_line1.placeholder}
                                                                            value={edit_student_page.parent_address_data[1].address_line1.value ? edit_student_page.parent_address_data[1].address_line1.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].address_line2.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[1].address_line2.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[1].address_line2.placeholder}
                                                                            value={edit_student_page.parent_address_data[1].address_line2.value ? edit_student_page.parent_address_data[1].address_line2.value : ""}
                                                                            onChange={(event) => this.inputChangeCountry(event, 1, "address_line2")}
                                                                            readOnly
                                                                        />
                                                                    </div>


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_state.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[1].parent_state.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[1].parent_state.placeholder}
                                                                            value={edit_student_page.parent_address_data[1].parent_state.value ? edit_student_page.parent_address_data[1].parent_state.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_city.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[1].parent_city.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[1].parent_city.placeholder}
                                                                            value={edit_student_page.parent_address_data[1].parent_city.value ? edit_student_page.parent_address_data[1].parent_city.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_postcode.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[0].parent_postcode.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[0].parent_postcode.placeholder}
                                                                            value={edit_student_page.parent_address_data[0].parent_postcode.value ? edit_student_page.parent_address_data[0].parent_postcode.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_postcode.label}</label>
                                                                        <input
                                                                            type={edit_student_page.parent_address_data[1].parent_postcode.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.parent_address_data[1].parent_postcode.placeholder}
                                                                            value={edit_student_page.parent_address_data[1].parent_postcode.value ? edit_student_page.parent_address_data[1].parent_postcode.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[0].parent_country.label}</label>
                                                                        <input
                                                                            className="form-control"
                                                                            value={edit_student_page.parent_address_data[0].parent_country.value ? edit_student_page.parent_address_data[0].parent_country.value : ""}
                                                                            onChange={(newValue) => this.handleChangeCountry(newValue, "country_id_father")}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.parent_address_data[1].parent_country.label}</label>
                                                                        <input
                                                                            className="form-control"
                                                                            // options={country_id.options}
                                                                            value={edit_student_page.parent_address_data[1].parent_country.value ? edit_student_page.parent_address_data[1].parent_country.value : ""}
                                                                        />
                                                                    </div>


                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.father_phone.value ? edit_student_page.father_phone.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            disabled
                                                                            country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.mother_phone.value ? edit_student_page.mother_phone.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_home_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.father_home_phone.value ? edit_student_page.father_home_phone.value : ""}
                                                                            readOnly
                                                                            enableLongNumbers={true}

                                                                        />

                                                                        {!edit_student_page.father_home_phone.valid && edit_student_page.father_home_phone.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.father_home_phone.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_home_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.mother_home_phone.value ? edit_student_page.mother_home_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "mother_home_phone")}
                                                                            enableLongNumbers={true}

                                                                        />

                                                                        {!edit_student_page.mother_home_phone.valid && edit_student_page.mother_home_phone.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.mother_home_phone.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>

                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.father_work_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.father_work_phone.value ? edit_student_page.father_work_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "father_work_phone")}
                                                                            enableLongNumbers={true}

                                                                        />
                                                                        {!edit_student_page.father_work_phone.valid && edit_student_page.father_work_phone.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.father_work_phone.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="col-lg-4  col-xl-3 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.mother_work_phone.label}</label>
                                                                        <ReactPhoneInput
                                                                            countryCodeEditable={false}
                                                                            country={edit_student_page.country_code.value ? edit_student_page.country_code.value : ""}
                                                                            value={edit_student_page.mother_work_phone.value ? edit_student_page.mother_work_phone.value : ""}
                                                                            onChange={(event) => this.inputChangeHandler(event, "mother_work_phone")}
                                                                            enableLongNumbers={true}

                                                                        />
                                                                        {!edit_student_page.mother_work_phone.valid && edit_student_page.mother_work_phone.onBlur_out ? (
                                                                            <div className="error field-error">{edit_student_page.mother_work_phone.error_msg}</div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                    <div className="col-lg-8  col-xl-6 fv-row mb-5">
                                                                        <label className="fw-bold mb-2">{edit_student_page.salutation.label}</label>
                                                                        <input
                                                                            type={edit_student_page.salutation.type}
                                                                            className="form-control"
                                                                            placeholder={edit_student_page.salutation.placeholder}
                                                                            value={edit_student_page.salutation.value ? edit_student_page.salutation.value : ""}
                                                                            readOnly
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                </div >
                                <Footer />
                            </div >
                        </div >
                    </div >
                </div>
            </React.Fragment >
        );
    }
}


function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {


    onGetAllDormitoryStudent: userActions.GetAllDormitoryStudent,
    onGetAllManagersBySchoolIdStudent: userActions.GetAllManagersBySchoolIdStudent,
    onGetStudentAndParentById: userActions.GetStudentAndParentById,
    onUpdateStudentPersonal: userActions.UpdateStudentPersonal,
    onUpdateParentPersonal: userActions.UpdateParentPersonal,
    onGetAllClassesDetailsStudent: userActions.GetAllClassesDetailsStudent,


};
export default connect(mapStateToProps, mapDispatchToProps)(StudentProfile);