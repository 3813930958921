import React, { createRef } from "react";
import { Modal } from "react-bootstrap";
import Validations from "../../Utility/Validations";
import Select from "react-select";
import { connect } from "react-redux";
import * as userActions from "../../../actions/index";
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import TextareaAutosize from 'react-autosize-textarea';
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import ArrowKeysReact from 'arrow-keys-react';
import IncludeEmailModal from "../../Utility/IncludeEmailModal";


const customStyles = {
    control: (base, state) => ({
        ...base,
        borderRadius: 0,

    }),
    menuList: base => ({
        ...base,
        padding: 0
    }),
    indicatorSeparator: base => ({
        ...base,
        display: 'none'
    }),
};
let str_length;
class AddPastrolComment extends React.Component {
    constructor(props) {
        super(props);
        this.contentEditable = React.createRef();
        this.myRef = React.createRef(null);
        this.state = {
            show_include_modal: false,
            content: "ssssssssssssssssssssssssssssssss",
            showMenu: false,
            activeIndex: 0,
            number: 0,
            name_list: false,
            name_list_array: [],
            diary_comment_form: {
                student_ids: {
                    type: "select",
                    label: "Student Name *",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Student Name",
                    validations: { required: true },
                    options: [],
                },
                diary_comment_desc: {
                    type: "text",
                    label: "Add Comment",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                include_mail: {
                    type: "checkbox",
                    label: "Include Primary eMail Contact",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },

                // Added By K
                include_medical: {
                    type: "checkbox",
                    label: "Issued Medication",
                    value: false,
                    valid: true,
                    error_msg: "",
                    validations: { required: false },
                },



                diary_comment_type: {
                    type: "text",
                    label: "diary_comment_type",
                    value: "pastoral",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },

        };

        ArrowKeysReact.config({
            left: () => {
                this.setState({
                    content: 'left key detected.'
                });
            },
            right: () => {
                this.setState({
                    content: 'right key detected.'
                });
            },
            up: () => {
                this.setState({
                    content: 'up key detected.'
                });
            },
            down: () => {
                this.setState({
                    content: 'down key detected.'
                });
            }
        });


    }

    componentDidMount() {
        this.GetAllStudentsGeneric();
    }

    GetAllStudentsGeneric = async (form_data) => {
        this.props.onGetAllStudentsGeneric().then((response) => {
            let country_level = [];
            country_level.push({
                label: "All Student",
                value: "All",
            });
            const update_diary_comment_form = { ...this.state.diary_comment_form };
            if (response.success === true) {

                const arrayData = response.data.rows;
                console.log(arrayData);
                for (let key in arrayData) {
                    country_level.push({
                        label: arrayData[key].student_first_name + " " + arrayData[key].student_last_name,
                        value: arrayData[key].student_id,
                        student_id: JSON.stringify([arrayData[key].student_id]),
                    });
                }
            }
            update_diary_comment_form['student_ids'].options = country_level;
            this.setState({ diary_comment_form: update_diary_comment_form });
        });
    }



    // add_name(e, item) {
    //     const update_diary_comment_form = { ...this.state.diary_comment_form };
    //     const comment_value = update_diary_comment_form['diary_comment_desc'].value;

    //     const get_length = comment_value.length
    //     if (str_length < get_length) {
    //         const str_start_value = comment_value.slice(0, str_length);
    //         const str_end_value = comment_value.slice(str_length, get_length)
    //         const get_last_Index = str_start_value.lastIndexOf("@")
    //         const replace_value = str_start_value.slice(get_last_Index, get_length);
    //         let arraydata = update_diary_comment_form.student_ids.options.map(item => "@" + item.label)
    //         let filter_array = arraydata.filter((element) => {
    //             return element.toLowerCase().startsWith(replace_value.toLowerCase());
    //         })
    //         if (filter_array.length > 0) {
    //             const latest_comment = comment_value.slice(0, get_last_Index)
    //             update_diary_comment_form['diary_comment_desc'].value = latest_comment + item + "  " + str_end_value
    //         }
    //         this.myRef.current.focus();
    //         this.setState({ name_list: false });
    //         this.setState({ diary_comment_form: update_diary_comment_form })
    //     } else {
    //         const get_last_Index = comment_value.lastIndexOf("@")
    //         const replace_value = comment_value.slice(get_last_Index, get_length);

    //         let arraydata = update_diary_comment_form.student_ids.options.map(item => "@" + item.label)
    //         let filter_array = arraydata.filter((element) => {
    //             return element.toLowerCase().startsWith(replace_value.toLowerCase());
    //         })

    //         if (filter_array.length > 0) {
    //             const latest_comment = comment_value.slice(0, get_last_Index)
    //             update_diary_comment_form['diary_comment_desc'].value = latest_comment + item + "  "
    //         }
    //         this.myRef.current.focus();
    //         this.setState({ name_list: false });
    //         this.setState({ diary_comment_form: update_diary_comment_form })
    //     }
    // }

    // inputChangeHandler(event, identifier) {
    //     const update_diary_comment_form = { ...this.state.diary_comment_form };
    //     const comment_value = event.target.value;
    //     console.log("pastoral_comment", comment_value);
    //     if (event.target.selectionStart < comment_value.length) {
    //         str_length = event.target.selectionStart;
    //         const end_value = comment_value.slice(0, event.target.selectionStart);
    //         const a_lastIndexOf = end_value.lastIndexOf(" @");
    //         let str_value_2 = end_value.slice(a_lastIndexOf, str_length)
    //         this.state.name_array = update_diary_comment_form.student_ids.options.map(item => "@" + item.label)

    //         let filter_array = [];
    //         if (end_value.at(0) === "@" || str_value_2.startsWith(" @")) {
    //             if (end_value.at(0) === "@") {
    //                 filter_array = this.state.name_array.filter((element) => {
    //                     if (element.toLowerCase().startsWith(end_value.toLowerCase().trim()))
    //                         return element;
    //                 })
    //             }
    //             if (str_value_2.startsWith(" @")) {
    //                 filter_array = this.state.name_array.filter((element) => {
    //                     if (element.toLowerCase().startsWith(str_value_2.toLowerCase().trim()))
    //                         return element;
    //                 })
    //             }
    //         }

    //         if (end_value.at(0) === "@" || (end_value.at(end_value.length - 1) === "@" && end_value.at(end_value.length - 2) === " ") || filter_array.length > 0) {
    //             this.setState({ name_list: true });
    //             this.setState({ name_list_array: filter_array });
    //             update_diary_comment_form["diary_comment_desc"].value = event.target.value;
    //         } else {
    //             this.setState({ name_list: false });
    //             update_diary_comment_form["diary_comment_desc"].value = event.target.value;
    //         }
    //     } else {
    //         str_length = event.target.selectionStart;
    //         const get_length = comment_value.length;
    //         let get_last_Index;
    //         if (comment_value.endsWith(" ")) {
    //         } else {
    //             get_last_Index = comment_value.lastIndexOf("@");
    //         }
    //         const str_value = comment_value.slice(get_last_Index - 1, get_length);
    //         this.state.name_array = update_diary_comment_form.student_ids.options.map(item => "@" + item.label)
    //         let filter_array = [];
    //         if (comment_value.at(0) === "@" || str_value.startsWith(" @")) {
    //             if (comment_value.at(0) === "@") {
    //                 filter_array = this.state.name_array.filter((element) => {
    //                     if (element.toLowerCase().startsWith(comment_value.toLowerCase().trim())) {
    //                         return element;
    //                     }
    //                 })
    //             }
    //             if (str_value.startsWith(" @")) {
    //                 filter_array = this.state.name_array.filter((element) => {
    //                     if (element.toLowerCase().startsWith(str_value.toLowerCase().trim())) {
    //                         return element;
    //                     }
    //                 })
    //             }
    //         }
    //         if (comment_value.at(0) === "@" || (comment_value.at(comment_value.length - 1) === "@" && comment_value.at(comment_value.length - 2) === " ") || filter_array.length > 0) {
    //             this.setState({ name_list: true });
    //             this.setState({ name_list_array: filter_array });
    //             update_diary_comment_form["diary_comment_desc"].value = event.target.value;
    //         } else {
    //             this.setState({ name_list: false });
    //             update_diary_comment_form["diary_comment_desc"].value = event.target.value;
    //         }
    //     }
    //     this.setState({ diary_comment_form: update_diary_comment_form })
    // }




    // handleChange(data, identifier, type) {
    //     const update_diary_comment_form = { ...this.state.diary_comment_form };
    //     update_diary_comment_form[identifier].value = data.value;
    //     this.setState({ diary_comment_form: update_diary_comment_form });
    // }


    // SubmitHandler = (event) => {
    //     event.preventDefault();
    //     let is_form_valid = true;
    //     const update_diary_comment_form = this.state.diary_comment_form;
    //     for (let key in update_diary_comment_form) {
    //         let form_element = update_diary_comment_form[key];
    //         let validate_element = Validations(form_element);

    //         is_form_valid = validate_element.valid === false ? false : is_form_valid;
    //         form_element.onBlur_out = true;
    //         form_element.valid = validate_element.valid;
    //         form_element.error_msg = validate_element.error_msg;

    //         update_diary_comment_form[key] = form_element;
    //     }
    //     this.setState({ diary_comment_form: update_diary_comment_form });
    //     if (is_form_valid) {
    //         const form_data = {};
    //         for (let key in this.state.diary_comment_form) {
    //             if (key === "student_ids") {
    //                 let student_ids = []
    //                 const diary_comment = this.state.diary_comment_form.diary_comment_desc.value
    //                 if (diary_comment.includes("@All Student")) {
    //                     student_ids = this.state.diary_comment_form.student_ids.options.reduce((acc, item) => {
    //                         if (item.value !== "All") {
    //                             acc.push(item.value)
    //                         }
    //                         return acc;
    //                     }, [])
    //                     form_data[key] = JSON.stringify(student_ids)
    //                 } else {
    //                     student_ids = this.state.diary_comment_form.student_ids.options.reduce((acc, item) => {
    //                         if (diary_comment.includes("@" + item.label)) {
    //                             acc.push(item.value)
    //                         }
    //                         return acc
    //                     }, [])
    //                     form_data[key] = JSON.stringify(student_ids)
    //                 }
    //             } else {

    //                 form_data[key] = this.state.diary_comment_form[key].value;
    //             }
    //         }
    //         if (JSON.parse(form_data.student_ids).length === 0) {
    //             toast.error(" Please add a student name to save Pastrol Comments", {
    //                 position: toast.POSITION.TOP_CENTER,
    //             });
    //         } else {
    //             this.props.CreateDiaryPastoralComments(form_data);
    //         }

    //     }
    // };

    // handlekeydown(e) {
    //     const { activeIndex } = this.state;
    //     if (e.keyCode === 38) {
    //         this.setState({
    //             activeIndex: activeIndex === 0 ? this.state.name_list_array.length - 1 : activeIndex - 1 // If the index goes below 0, wrap around
    //         });
    //     }
    //     if (e.keyCode === 40) {
    //         this.setState({
    //             activeIndex: activeIndex === this.state.name_list_array.length - 1 ? 0 : activeIndex + 1 // If the index goes above the length of the array, wrap around
    //         });
    //     }

    //     if (e.keyCode === 13) {
    //         this.setState({ name_list: false });
    //         const update_diary_comment_form = { ...this.state.diary_comment_form };
    //         const comment_value = update_diary_comment_form['diary_comment_desc'].value;

    //         const get_length = comment_value.length
    //         if (str_length < get_length) {
    //             const str_start_value = comment_value.slice(0, str_length);
    //             const str_end_value = comment_value.slice(str_length, get_length)
    //             const get_last_Index = str_start_value.lastIndexOf("@")
    //             const replace_value = str_start_value.slice(get_last_Index, get_length);
    //             let arraydata = update_diary_comment_form.student_ids.options.map(item => "@" + item.label)
    //             let filter_array = arraydata.filter((element) => {
    //                 return element.toLowerCase().startsWith(replace_value.toLowerCase());
    //             })
    //             if (filter_array.length > 0) {
    //                 const latest_comment = comment_value.slice(0, get_last_Index)
    //                 update_diary_comment_form['diary_comment_desc'].value = latest_comment + this.state.name_list_array[activeIndex] + "  " + str_end_value
    //                 e.preventDefault()
    //             }
    //             this.myRef.current.focus();
    //             this.setState({ name_list: false });
    //             this.setState({ diary_comment_form: update_diary_comment_form })
    //         } else {
    //             const get_last_Index = comment_value.lastIndexOf("@")
    //             const replace_value = comment_value.slice(get_last_Index, get_length);

    //             let arraydata = update_diary_comment_form.student_ids.options.map(item => "@" + item.label)
    //             let filter_array = arraydata.filter((element) => {
    //                 return element.toLowerCase().startsWith(replace_value.toLowerCase());
    //             })

    //             if (filter_array.length > 0) {
    //                 const latest_comment = comment_value.slice(0, get_last_Index)
    //                 let latest_comment_value = latest_comment + this.state.name_list_array[activeIndex] + "  "
    //                 e.preventDefault()
    //                 update_diary_comment_form['diary_comment_desc'].value = latest_comment_value;
    //             }
    //             this.myRef.current.focus();
    //         }
    //     }
    // }


    handleChange(data, identifier) {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        let pastoralCommentArray = [];
        let student_ids = [];
        data.forEach((item) => {
            pastoralCommentArray.push(item.label)
            student_ids.push(item.value)
        })
        update_diary_comment_form[identifier].value = student_ids;
        this.setState({ diary_comment_form: update_diary_comment_form });
    }


    inputChangeHandler(event, identifier) {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        update_diary_comment_form[identifier].value = event.target.value;
        this.setState({ diary_comment_form: update_diary_comment_form });
    }

    inputChangeHandlerCheckbox(event, identifier) {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        const form_element = { ...update_diary_comment_form[identifier] };
        form_element.value = event.target.checked;
        update_diary_comment_form[identifier] = form_element;
        this.setState({ show_include_modal: event.target.checked })
        this.setState({ diary_comment_form: update_diary_comment_form });
    }

    // // Added By K
    inputChangeHandlerCheckboxMedical(event, identifier) {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        const form_element = { ...update_diary_comment_form[identifier] };
        form_element.value = event.target.checked;
        update_diary_comment_form[identifier] = form_element;
        // this.setState({ show_include_modal: event.target.checked })
        this.setState({ diary_comment_form: update_diary_comment_form });
    }



    SubmitHandler = (event) => {
        event.preventDefault();
        let is_form_valid = true;
        const update_diary_comment_form = this.state.diary_comment_form;
        for (let key in update_diary_comment_form) {
            let form_element = update_diary_comment_form[key];
            let validate_element = Validations(form_element);
            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;
            update_diary_comment_form[key] = form_element;
        }
        this.setState({ diary_comment_form: update_diary_comment_form });
        if (is_form_valid) {
            const form_data = {};
            for (let key in this.state.diary_comment_form) {
                if (key === "student_ids") {
                    if (this.state.diary_comment_form[key].value.includes("All")) {
                        let filter_ids = []
                        this.state.diary_comment_form[key].options.forEach((item) => {
                            if (item.value !== "All") {
                                filter_ids.push(item.value)
                            }
                        })
                        form_data[key] = JSON.stringify(filter_ids);
                    } else {
                        form_data[key] = JSON.stringify(this.state.diary_comment_form[key].value);
                    }
                } else {
                    form_data[key] = this.state.diary_comment_form[key].value;
                }
            }
            this.props.CreateDiaryPastoralComments(form_data);

        }
    };

    setIncludeModalVlaue = (val) => {
        const update_diary_comment_form = { ...this.state.diary_comment_form };
        update_diary_comment_form["include_mail"].value = val;
        this.setState({ show_include_modal: false })
        this.setState({ diary_comment_form: update_diary_comment_form });
    }





    render() {

        const { diary_comment_form } = this.state;
        const { show_include_modal } = this.state;

        return (
            <React.Fragment>
                {show_include_modal &&
                    <IncludeEmailModal
                        includes={show_include_modal}
                        setIncludeModalVlaue={(val) => this.setIncludeModalVlaue(val)}
                    />
                }
                <Modal
                    size="lg"
                    backdrop={true}
                    keyboard={false}
                    className="add_pastrol_comment_modal confirm_modal"
                    show={this.props.add_pastrol_comment_modal}
                    onHide={() => {
                        this.props.add_pastrol_comment_modal_action(false);
                    }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add Pastoral Comments</Modal.Title>
                    </Modal.Header>
                    <form className="form w-100" onSubmit={this.SubmitHandler}>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    {/* <div className="col-lg-12 fv-row mb-4">
                                            <label className="fw-bold mb-2">
                                                <Tooltip title='To Add Student Name in comments, just use @StudentNames' placement="top">
                                                    <i className="fas fa-question-circle me-2"></i>
                                                </Tooltip>
                                                {diary_comment_form.diary_comment_desc.label}
                                            </label>
                                            <div className="position-relative">
                                                {this.state.name_list_array.length === 0 ? "" : (this.state.name_list ?
                                                    <div className="list_class_@ position-absolute w-100" style={{ top: "130%", }}>
                                                        <Box sx={{ width: '100%', maxHeight: "225px", overflowY: "scroll", bgcolor: 'background.paper' }}>
                                                            <nav aria-label="secondary mailbox folders" className='border'>
                                                                <List className="list_item" ref={element => { this.dropdownMenu = element } } style={{maxHeight: '100%', overflow: 'auto'}}>
                                                                    {this.state.name_list_array.map((item,index) => {
                                                                        return (
                                                                            <ListItem disablePadding  >
                                                                                <ListItemButton  className={`${index === this.state.activeIndex ? " bg-primary text-light" : ""}`}>
                                                                                    <ListItemText  primary={item} onClick={(e) => this.add_name(e, item)} />
                                                                                </ListItemButton>
                                                                            </ListItem>
                                                                        )
                                                                    })}
                                                                </List>
                                                            </nav>
                                                        </Box>
                                                    </div>
                                                    : ""
                                                )}

                                                <div className="">
                                                    <TextareaAutosize
                                                        id="Add_pastoral_Comment"
                                                        ref={this.myRef}
                                                        minRows={1}
                                                        maxRows={10}
                                                        value={diary_comment_form.diary_comment_desc.value ? diary_comment_form.diary_comment_desc.value : ""}
                                                        onChange={(event) => this.inputChangeHandler(event, "diary_comment_desc")}
                                                        style={{ maxHeight: "150px", overflowY: "scroll", height: "inherit", border: "1px solid #ccc", borderRadius: "5px", padding: "8px 10px", borderRadius: "5px", outline: "none", width: "100%" }}
                                                        onKeyDown={(e) => this.handlekeydown(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}



                                    <div>
                                        <label className="fw-bold mb-2">{diary_comment_form.student_ids.label}</label>
                                        <Select
                                            styles={customStyles}
                                            className="react-bootstrap-typeahead tag-outer rect-select"
                                            options={diary_comment_form.student_ids.options}
                                            value={diary_comment_form.student_ids.options.filter(function (option) {
                                                return diary_comment_form.student_ids.value.includes(option.value);
                                            })}
                                            onChange={(newValue) => this.handleChange(newValue, "student_ids")}
                                            isMulti
                                        />
                                        {!diary_comment_form.student_ids.valid && diary_comment_form.student_ids.onBlur_out ? (
                                            <div className="error field-error">{diary_comment_form.student_ids.error_msg}</div>
                                        ) : (
                                            ""
                                        )}
                                    </div>

                                    <div className="my-5">
                                        <label className="fw-bold mb-2">{diary_comment_form.diary_comment_desc.label}</label>
                                        <TextareaAutosize
                                            minRows={1}
                                            maxRows={10}
                                            placeholder={diary_comment_form.diary_comment_desc.placeholder}
                                            value={diary_comment_form.diary_comment_desc.value ? diary_comment_form.diary_comment_desc.value : ""}
                                            onChange={(event) => this.inputChangeHandler(event, "diary_comment_desc")}
                                            className="form-control min-h-100px"
                                        />
                                        {!diary_comment_form.diary_comment_desc.valid && diary_comment_form.diary_comment_desc.onBlur_out ? <div className="error field-error">{diary_comment_form.diary_comment_desc.error_msg}</div> : ""}
                                    </div>

                                    <div>
                                        <label className="fw-bold d-flex align-items-center gap-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value={diary_comment_form.include_mail.value}
                                                checked={diary_comment_form.include_mail.value}
                                                onChange={(event) => this.inputChangeHandlerCheckbox(event, "include_mail")}
                                            />
                                             {diary_comment_form.include_mail.label}
                                        </label>
                                        <label className="fw-bold d-flex align-items-center gap-2">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value={diary_comment_form.include_medical.value}
                                                checked={diary_comment_form.include_medical.value}
                                                onChange={(event) => this.inputChangeHandlerCheckboxMedical(event, "include_medical")}
                                            />
                                             {diary_comment_form.include_medical.label}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="row">
                                <div className="col-12">
                                    <button type="submit" className="btn btn-sm btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        );
    }
}
function mapStateToProps(state) {
    return {
        // error: state.students.error,
        // loader: state.students.loader,
        // isAuthenticated: state.students.isAuthenticated,
    };
}
const mapDispatchToProps = {


    onGetAllStudentsGeneric: userActions.GetAllStudentsGeneric,




};
export default connect(mapStateToProps, mapDispatchToProps)(AddPastrolComment);

