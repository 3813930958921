import React, { useState, useEffect, useCallback} from "react";
import Chart from "react-google-charts";
import { connect, useDispatch } from "react-redux";
import * as userActions from "../../../actions/index";



export function ReportPiechart() {
    const dispatch = useDispatch();
    const [present, setPresent] = useState("")
    const [absent, setAbsent] = useState("")
 

    const GetWeeklyAttendanceCountForChart = useCallback(async () => {
        dispatch(userActions.GetWeeklyAttendanceCountForChart()).then((response) => {
            if (response.success === true) {
                const arrayData = response.data

                // console.log("GetWeeklyAttendanceCountForChart response", response.data)

                let absent;
                let present;

            if (arrayData[0]?.is_attendance == false) {
                // console.log("Response in present first")
                absent = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                present = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);
                setPresent(present);
                setAbsent(absent);
                
            } else if (arrayData[0]?.is_attendance == true) {
                present = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                absent = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);
                setPresent(present);
                setAbsent(absent);

            } else {
                present = 1;
                absent = 1;
                setPresent(present);
                setAbsent(absent);
            } 
            } else {}
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetWeeklyAttendanceCountForChart()
       
    }, [])

    return (
        <>
            <div className="card card-width heading-border">
                <div className="card-header">
                    <h2 className="card-title">
                        Weekly Attendance
                    </h2>
                </div>

                <Chart
                    width={"300px"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Pie Chart</div>}
                     data={[
                        ["class", "Student"],
                        [`Present  ${present}`, present],
                        [`Absent ${absent}`, absent],
                    ]}
                    options={{
                        title: "Weekly Attendance",
                        is3D: true,
                    }}
                />
            </div>
        </>
    );
}

export function ReportPiechart2() {
    const dispatch = useDispatch();
    const [present, setPresent] = useState("")
    const [absent, setAbsent] = useState("")
 

    const GetMonthlyAttendanceCountForChart = useCallback(async () => {
        dispatch(userActions.GetMonthlyAttendanceCountForChart()).then((response) => {
            if (response.success === true) {
                // console.log("GetMonthlyAttendanceCountForChart response", response.data)
                let absent;
                let present;

            if (response.data[0]?.is_attendance === false) {
                absent = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                present = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);
             
                setPresent(present);
                setAbsent(absent);
            } else if (response.data[0]?.is_attendance === true) {
                present = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                absent = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);
               
                setPresent(present);
                setAbsent(absent);
            } else {

                present = 1;
                absent = 1;
                setPresent(present);
                setAbsent(absent);
            } 
            } else {}
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetMonthlyAttendanceCountForChart()
       
    }, [])

// console.log("attendance present", present)
// console.log("attendance present", absent)

    return (
        <>
            <div className="card card-width heading-border">
                <div className="card-header">
                    <h2 className="card-title">
                        Monthly Attendance
                    </h2>
                </div>
            <div class=" cart-align">
                <Chart
                    width={"100%"}
                    height={"220px"}
                    chartType="PieChart"
              
                    loader={<div>Loading Pie Chart</div>}
                    data={[
                        ["class", "Student"],
                        [`Present  ${present}`, present],
                        [`Absent ${absent}`, absent],
                    ]}
                    options={{
                        title: "Monthly Attendance",
                        is3D: true,
                        colors: ['#61cdbb', '#f57560'],
                    }}
                />
                </div>
            </div>
        </>
    );
}

export function ReportPiechart3() {
    const dispatch = useDispatch();
    
    const [present, setPresent] = useState()
    const [absent, setAbsent] = useState()
 

    const GetYearlyAttendanceCountForChart = useCallback(async () => {
        dispatch(userActions.GetYearlyAttendanceCountForChart()).then((response) => {
            if (response.success === true) {
                console.log("GetYearlyAttendanceCountForChart response", response.data)
                let absent;
                let present;
            if (response.data[0]?.is_attendance === false) {
                absent = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                present = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);
                setPresent(present);
                setAbsent(absent);
            } else if (response.data[0]?.is_attendance === true) {

                present = JSON.parse(response?.data[0]?.atten_count ? response?.data[0]?.atten_count : 0);
                absent = JSON.parse(response?.data[1]?.atten_count ? response?.data[1]?.atten_count : 0);

                setPresent(present);
                setAbsent(absent);
            }
           else {
                present = 1;
                absent = 1;
                setPresent(present);
                setAbsent(absent);
            }
             
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetYearlyAttendanceCountForChart()
       
    }, [])

    return (
        <>
            <div className="card card-width card-width-right heading-border">
                <div className="card-header">
                    <h2 className="card-title">
                        Yearly Attendance
                    </h2>
                    {/* <button className="btn btn-mini btn-primary btn-block pull-right" style={{marginLeft:"250px" , marginTop:"-37px" ,  marginBottom:"20px"}}>Excel</button> */}
                </div>
                <div class=" cart-align">
                <Chart
                    width={"100%"}
                    height={"220px"}
                    chartType="PieChart"
                    loader={<div>Loading Pie Chart</div>}
                    data={[
                        ["class", "Student"],
                        [`Present  ${present}`, present],
                        [`Absent ${absent}`, absent],
                    ]}
                    options={{
                        title: "Yearly Attendance",
                        is3D: true,
                        colors: ['#61cdbb', '#f57560'],
                    }}
                />
                </div>
            </div>
        </>
    );
}

export function ReportPiechart4() {
    const dispatch = useDispatch();
  
    const [KL, setKL] = useState("")
    const [OnCampus, setOnCampus] = useState("")
    const [SL, setSL] = useState("")
    const [MIA, setMIA] = useState("")
 
    const GetWeeklyAbsentReasonForChart = useCallback(async () => {
        dispatch(userActions.GetWeeklyAbsentReasonForChart()).then((response) => {
            if (response.success === true) {
                console.log("GetWeeklyAbsentReasonForChart response", response.data)
                if (response?.data != "") {

                   response?.data.map((item) => {
                        if(item.atten_reason_data.reason_name === "MIA"){
                            var mia = JSON.parse(item?.reason_count ? item?.reason_count : 0)
                            setMIA(mia)
                        } else if(item.atten_reason_data.reason_name === "KL"){
                            var kl = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setKL(kl);
                        } else if(item.atten_reason_data.reason_name === "SL"){
                            var sl = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setSL(sl);
                        } else if(item.atten_reason_data.reason_name === "OnCampus"){
                            var oncampus = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setOnCampus(oncampus);
                        }
                    })
                  
            } else {
                setKL(1);
                setOnCampus(1);
                setSL(1);
                setMIA(1);
            }
            }
            else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetWeeklyAbsentReasonForChart()
       
    }, [])

    console.log("KL", KL)
    console.log("OnCampus", OnCampus)
    console.log("SL", SL)
    console.log("MIA", MIA)
    return (
        <>
            <div className="card card-width heading-border">
                <div className="card-header">
                    <h2 className="card-title">
                        Weekly Absent Reason
                    </h2>
                    {/* <button className="btn btn-mini btn-primary btn-block pull-right" style={{marginLeft:"250px" , marginTop:"-37px" ,  marginBottom:"20px"}}>Excel</button> */}
                </div>
                <div class=" cart-align">
                <Chart
                    width={"100%"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Pie Chart</div>}
                    data={[
                        ["className", "Student"],
                        [`KL  ${KL}`, KL],
                        [`OC  ${OnCampus}`, OnCampus],
                        [`SL  ${SL}`, SL],
                        [`MIA  ${MIA}`, MIA],
                    ]}
                    options={{
                        title: "Weekly Absent Reason",
                        is3D: true,
                        colors: ['#61cdbb', '#f57560','#f3a691', '#e8a838'],
                    }}
                />
                </div>
            </div>
        </>
    );
}

export function ReportPiechart5() {
    const dispatch = useDispatch();

    const [KL, setKL] = useState("")
    const [OnCampus, setOnCampus] = useState("")
    const [SL, setSL] = useState("")
    const [MIA, setMIA] = useState("")
 

    const GetMonthlyAbsentReasonForChart = useCallback(async () => {
        dispatch(userActions.GetMonthlyAbsentReasonForChart()).then((response) => {
            if (response.success === true) {
                console.log("GetMonthlyAbsentReasonForChart response", response.data)
                if (response?.data != "") {
                    response?.data.map((item) => {
                        if(item.atten_reason_data.reason_name === "MIA"){
                            var mia = JSON.parse(item?.reason_count ? item?.reason_count : 0)
                            setMIA(mia)
                        } else if(item.atten_reason_data.reason_name === "KL"){
                            var kl = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setKL(kl);
                        } else if(item.atten_reason_data.reason_name === "SL"){
                            var sl = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setSL(sl);
                        } else if(item.atten_reason_data.reason_name === "OnCampus"){
                            var oncampus = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setOnCampus(oncampus);
                        }
                    })

                
            } else {
                setKL(1);
                setOnCampus(1);
                setSL(1);
                setMIA(1);
            }
         } else {
            }
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetMonthlyAbsentReasonForChart()
       
    }, [])

    
    return (
        <>
            <div className="card card-width heading-border">
                <div className="card-header">
                    <h2 className="card-title">
                        Monthly Absent Reason
                    </h2>
                    {/* <button className="btn btn-mini btn-primary btn-block pull-right" style={{marginLeft:"250px" , marginTop:"-37px" ,  marginBottom:"20px"}}>Excel</button> */}
                </div>
                <div class=" cart-align">
                <Chart
                    width={"100%"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Pie Chart</div>}
                    data={[
                        ["className", "Student"],
                        [`KL  ${KL}`, KL],
                        [`OC  ${OnCampus}`, OnCampus],
                        [`SL  ${SL}`, SL],
                        [`MIA  ${MIA}`, MIA],
                    ]}
                    options={{
                        title: "Monthly Absent Reason",
                        is3D: true,
                        colors: ['#61cdbb', '#f57560','#f3a691', '#e8a838'],
                    }}
                />
            </div>
            </div>
        </>
    );
}

export function ReportPiechart6() {
    const dispatch = useDispatch();
    
    const [KL, setKL] = useState("")
    const [OnCampus, setOnCampus] = useState("")
    const [SL, setSL] = useState("")
    const [MIA, setMIA] = useState("")

    const GetYearlyAbsentReasonForChart = useCallback(async () => {
        dispatch(userActions.GetYearlyAbsentReasonForChart()).then((response) => {
            if (response.success === true) {
                console.log("GetYearlyAbsentReasonForChart response", response?.data)
                if (response.data[0]) {

                    response?.data.map((item) => {
                        if(item.atten_reason_data.reason_name === "MIA"){
                            var mia = JSON.parse(item?.reason_count ? item?.reason_count : 0)
                            setMIA(mia)
                        } else if(item.atten_reason_data.reason_name === "KL"){
                            var kl = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setKL(kl);
                        } else if(item.atten_reason_data.reason_name === "SL"){
                            var sl = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setSL(sl);
                        } else if(item.atten_reason_data.reason_name === "OnCampus"){
                            var oncampus = JSON.parse(item?.reason_count ? item?.reason_count : 0) 
                            setOnCampus(oncampus);
                        }
                    })
            } else {
              
                setKL(1);
                setOnCampus(1);
                setSL(1);
                setMIA(1);
            }
        }else {}
        });
        // eslint-disable-next-line
    }, [dispatch]);

    useEffect(() => {
        GetYearlyAbsentReasonForChart()
       
    }, [])


    return (
        <>
            <div className="card card-width card-width-right heading-border">
                <div className="card-header">
                    <h2 className="card-title" >
                        Yearly Absent Reason
                    </h2>
                    {/* <button className="btn btn-mini btn-primary btn-block pull-right" style={{marginLeft:"250px" , marginTop:"-37px" ,  marginBottom:"20px"}}>Excel</button> */}
                </div>
                <div class=" cart-align">

                <Chart
                    width={"100%"}
                    height={"200px"}
                    chartType="PieChart"
                    loader={<div>Loading Pie Chart</div>}
                    data={[
                        ["className", "Student"],
                        [`KL  ${KL}`, KL],
                        [`OC  ${OnCampus}`, OnCampus],
                        [`SL  ${SL}`, SL],
                        [`MIA  ${MIA}`, MIA],
                    ]}
                    options={{
                        title: "Yearly Absent Reason",
                        is3D: true,
                        colors: ['#61cdbb', '#f57560','#f3a691', '#e8a838'],
                    }}
                />
                </div>
            </div>
        </>
    );
}


const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps);