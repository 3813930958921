import React, { useState, useEffect, useCallback } from 'react';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-datepicker/dist/react-datepicker.css";
import TopBar from "../../Constant/TopBar";
import Footer from "../../Constant/Footer";
import SideBarLeave from "../../Constant/SideBarLeave";
import * as userActions from "../../../actions/index";
import { Tooltip } from "@mui/material";
import Select from 'react-select';
import DepartingAndOnLeave from './ATC/DepartingAndOnLeave';
import ReturningAndDelayed from './ATC/ReturningAndDelayed';

const customStyles = {
   option: (provided, state) => ({
      ...provided,
      "&:hover": {
         backgroundColor: state.isFocused ? "#e7f5fe" : "",
      },
      backgroundColor: state.isSelected ? "#4a8fb8" : "",
   }),
};
const user_info = JSON.parse(localStorage.getItem("bss_authuser"));
const timezone = user_info?.user_school?.timezone;
const current = new Date();
const school_code = user_info?.school_code ?? null;

const ManageBoarderLeaveDashboard = () => {
   const dispatch = useDispatch();
   const permission_info = JSON.parse(localStorage.getItem("permissions"));
   const navigate = useNavigate();
   let current_date = moment().tz(timezone).format('MMMM Do YYYY, h:mm:ss a')
   let current_date2 = moment().tz(timezone)
   const [studentsOnLeaveTodayCount, setstudentsOnLeaveTodayCount] = useState('');
   const [studentsReturningTodayCount, setstudentsReturningTodayCount] = useState('');
   const [newLeaveRequestsTodayCount, setnewLeaveRequestsTodayCount] = useState('');
   const [numberOfStudentLateOrDelayedCount, setnumberOfStudentLateOrDelayedCount] = useState('');
   const [studentDepartureTodayCount, setStudentDepartureTodayCount] = useState([])

   const [studentsOnLeaveTodayList, setstudentsOnLeaveTodayList] = useState([]);
   const [studentsReturningTodayList, setstudentsReturningTodayList] = useState([]);
   const [newLeaveRequestsTodayList, setnewLeaveRequestsTodayList] = useState([]);
   const [numberOfStudentLateOrDelayedList, setnumberOfStudentLateOrDelayedList] = useState([]);
   const [studentDepartureToday, setStudentDepartureToday] = useState([])

   const [showLeave, setShowLeave] = useState("on_leave")
   const [returnAndLate, setReturnAndLate] = useState("returning_today")

   const [rightSide, setRightSide] = useState([]);
   const [rightSideHeader, setRightSideHeader] = useState("Students Returning Today");
   const [leftSide, setLeftSide] = useState([])
   // const [leftSideHeader, setLeftSideHeader] = useState("Students On Leave Now / New Leave Requests Today")
   const [dormitory_ids, setdormitory_ids] = useState([]);
   const [dormitory_id_left, setdormitory_id_left] = useState("");
   const [dormitory_id_left2, setdormitory_id_left2] = useState("");

   const [dormitory_id_right, setdormitory_id_right] = useState("");
   const [dormitory_id_right2, setdormitory_id_right2] = useState("");




   const [leftSideHeader, setLeftSideHeader] = useState("Students Departure Today")

   // Students On Leave Now

   const handleErrorResponse = (response) => {
      try {
         if (response.status === 401) {
            toast.error(response.message, {
               position: toast.POSITION.TOP_CENTER,
            });
            setTimeout(() => {
               navigate('/logout');
            }, 5000);
         } else {
            //  error cases
         }
      }
      catch (e) {
      }
   };

   useEffect(() => {
      const ManageBoarderLeaveDashboard = async () => {
         dispatch(userActions.ManageBoarderLeaveDashboard(dormitory_id_right, dormitory_id_right2, dormitory_id_left, dormitory_id_left2)).then((response) => {
            if (response.success === true) {
               const arrayData = response.data;
               setstudentsOnLeaveTodayCount(arrayData[0].studentsOnLeaveToday.count);
               setStudentDepartureTodayCount(arrayData[0].studentstodayDepartures.count)
               //setnewLeaveRequestsTodayCount(arrayData[0].newLeaveRequestsToday.count);
               setstudentsReturningTodayCount(arrayData[0].studentsReturningToday.count)
               setnumberOfStudentLateOrDelayedCount(arrayData[0].numberOfStudentLateOrDelayed.count);

               setstudentsOnLeaveTodayList(arrayData[0].studentsOnLeaveToday.rows);
               setStudentDepartureToday(arrayData[0].studentstodayDepartures.rows)

               // setnewLeaveRequestsTodayList(arrayData[0].newLeaveRequestsToday.rows)
               setstudentsReturningTodayList(arrayData[0].studentsReturningToday.rows)


               const filterNumberOfStudentLateOrDelayed = arrayData[0].numberOfStudentLateOrDelayed.rows.map((item) => {
                  const dateString = item.planned_return_date; // Replace with your date
                  const timeString = item.planned_return_time;   // Replace with your time
                  const [year, month, day] = dateString.split('-');
                  const [hours, minutes] = timeString.split(':');
                  const combinedDateTime = new Date();
                  combinedDateTime.setFullYear(parseInt(year, 10));
                  combinedDateTime.setMonth(parseInt(month, 10) - 1); // Month is 0-indexed in JavaScript
                  combinedDateTime.setDate(parseInt(day, 10));
                  combinedDateTime.setHours(parseInt(hours, 10));
                  combinedDateTime.setMinutes(parseInt(minutes, 10));
                  const timezone = user_info?.user_school?.timezone;
                  const current = new Date();
                  const currentDate = new Date(moment(current)?.tz(timezone).format('MM/DD/YYYY, h:mm:ss a'));
                  const diff = currentDate - combinedDateTime
                  const date1 = moment(combinedDateTime);
                  const date2 = moment(currentDate);
                  const timeDifference = date2.diff(date1);
                  const duration = moment.duration(timeDifference);
                  // const daysDifference = duration.days();
                  // const hoursDifference = duration.hours();
                  // const minutesDifference = duration.minutes();

                  const hourss = duration.asHours();
                  const Finalhours = Math.floor(hourss);
                  const Finalminutes = Math.floor((hourss - Finalhours) * 60);

                  // return { ...item, formattedDelay: daysDifference > 1 ? `${daysDifference} days` : `${fhours} hours, ${fminutes + 1} minutes` }
                  return { ...item, formattedDelay: `${Finalhours} hours, ${Finalminutes + 1} minutes` }
               })
               setnumberOfStudentLateOrDelayedList(filterNumberOfStudentLateOrDelayed)

               // setRightSide([...arrayData[0].studentsReturningToday.rows, ...filterNumberOfStudentLateOrDelayed])
               // setLeftSide([...arrayData[0].studentsOnLeaveToday.rows, ...arrayData[0].studentstodayDepartures.rows])

               if (leftSideHeader === "Students On Leave Now") {
                  setLeftSide(arrayData[0].studentsOnLeaveToday.rows);
               } else if (leftSideHeader === "Students Departure Today") {
                  setLeftSide(arrayData[0].studentstodayDepartures.rows);
               }
               
               if (rightSideHeader === "Students Returning Today") {
                  console.log(filterNumberOfStudentLateOrDelayed, "::filterNumberOfStudentLateOrDelayed")
                  setRightSide(arrayData[0].studentsReturningToday.rows);
               } else if (rightSideHeader === "Students Late or Delayed") {
                  setRightSide(filterNumberOfStudentLateOrDelayed);
               }

               // setRightSide([...arrayData[0].studentsReturningToday.rows])
               // setLeftSide([...arrayData[0].studentstodayDepartures.rows])
            }
            else {
               handleErrorResponse(response);
            }
         });
      };
      ManageBoarderLeaveDashboard()
   }, [dormitory_id_right, dormitory_id_left, dormitory_id_right2, dormitory_id_left2])



   useEffect(() => {
      // ManageBoarderLeaveDashboard();
      GetAllDormitoryStudent();
   }, []);

   const myFunction = (identifire) => {
      if (identifire === "returning_today") {
         setRightSide(studentsReturningTodayList)
         setRightSideHeader("Students Returning Today");
      }
      if (identifire === "late_and_delay") {
         setRightSide(numberOfStudentLateOrDelayedList)
         setRightSideHeader("Students Late or Delayed")
      }
   }
   const myFunction2 = (identifire) => {
      if (identifire === "on_leave") {
         setLeftSide(studentsOnLeaveTodayList)
         setLeftSideHeader("Students On Leave Now")
      }
      if (identifire === "request_leave") {
         // setLeftSide(newLeaveRequestsTodayList)
         //setLeftSideHeader("New Leave Requests Today")
         setLeftSide(studentDepartureToday)
         setLeftSideHeader("Students Departure Today")
      }
   }

   const GetAllDormitoryStudent = useCallback(() => {
      dispatch(userActions.GetAllDormitoryStudent()).then((response) => {
         if (response.success === true) {
            let state_data = [];
            state_data.push({
               label: "Select Dormitory",
               value: "",
            });
            const arrayData = response.data.rows;
            // console.log('Array Data::::', arrayData);
            if (permission_info) {
               const permClassDormArray = permission_info?.perm_class_dorm;
               const filteredPermDorm = permClassDormArray.filter(item => item?.dormitory_id !== null)
               const allowedDormIds = filteredPermDorm.map(item => item?.dormitory_id);
               const filteredData = arrayData.filter(item => allowedDormIds.includes(item.dormitory_id));
               for (let key in filteredData) {
                  state_data.push({
                     label: filteredData[key].dormitory_name,
                     value: filteredData[key].dormitory_id,
                     dormitory_id: filteredData[key].dormitory_id,
                  });
               }
            } else {
               for (let key in arrayData) {
                  state_data.push({
                     label: arrayData[key].dormitory_name,
                     value: arrayData[key].dormitory_id,
                     dormitory_id: arrayData[key].dormitory_id,
                  });
               }
            }
            setdormitory_ids(state_data);
         } else {
         }
      });
   }, [dispatch]);

   return (
      <>
         <div id="kt_body" className="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-fixed" >
            <div className="d-flex flex-column flex-root">
               <div className="page d-flex flex-row flex-column-fluid">
                  <SideBarLeave />
                  <div className="wrapper d-flex flex-column flex-row-fluid " id="kt_wrapper">
                     <TopBar />
                     <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                        <div className="post d-flex flex-column-fluid" id="kt_post">
                           {/* <!--begin::Container--> */}
                           <div id="kt_content_container" className="container-fluid">
                              <div className="row">
                                 <div className="col-lg-12 col-xl-6">
                                    <div className='row mb-5'>
                                       <div className="col-lg-6">
                                          <button className='border-0 bg-transparent p-0 m-0 w-100'
                                             // onClick={() => myFunction2("request_leave")}
                                             onClick={() => { setLeftSideHeader("Students Departure Today"); setLeftSide(studentDepartureToday) }}
                                          >
                                             <div className="card bg-light-primary card-xl-stretch">
                                                <div className="card-body d-flex align-items-center p-5 top-card-header">
                                                   <span className="card-title fw-bolder text-dark  fs-6 d-block mb-0">Students Departing Today</span>
                                                   <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center ms-auto">
                                                      <span className="text-dark fs-1 fw-bolder ">{studentDepartureTodayCount}</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </button>
                                       </div>
                                       <div className="col-lg-6">
                                          <button className='border-0 bg-transparent p-0 m-0 w-100'
                                             // onClick={() => myFunction2("on_leave")}
                                             onClick={() => { setLeftSideHeader("Students On Leave Now"); setLeftSide(studentsOnLeaveTodayList) }}
                                          >
                                             <div className="card bg-light-warning card-xl-stretch">
                                                <div className="card-body d-flex align-items-center p-5 top-card-header">
                                                   <span className="card-title text-dark fw-bolder fs-6 d-block mb-0">Students On Leave Now</span>
                                                   <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center ms-auto">
                                                      <span className="text-dark fs-1 fw-bolder ">{studentsOnLeaveTodayCount}</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </button>
                                       </div>

                                    </div>
                                    <div className="card">
                                       <div className="card-header align-items-center">
                                          <h3 className="card-title">{leftSideHeader}</h3>
                                       </div>

                                       <div className="card-body border_leave_dashboard_height">
                                          <div className="col-lg-6 mb-9">
                                             <label className="form-label">Select Dormitory</label>
                                             <Select
                                                className="react-bootstrap-typeahead tag-outer"
                                                options={dormitory_ids}
                                                value={dormitory_ids.find((option) =>
                                                   leftSideHeader === 'Students Departure Today'
                                                      ? option.value === dormitory_id_left
                                                      : option.value === dormitory_id_left2
                                                )}
                                                onChange={(event) => {
                                                   if (leftSideHeader === 'Students Departure Today') {
                                                      setdormitory_id_left(event.value);
                                                   } else {
                                                      setdormitory_id_left2(event.value);
                                                   }
                                                }}
                                             />
                                          </div>
                                          {leftSide.length > 0 ?
                                             <>
                                                <DepartingAndOnLeave leftSide={leftSide} leftSideHeader={leftSideHeader} />
                                             </>
                                             : <div><h4 className='text-center text-muted'>No record found</h4></div>
                                          }
                                          {/* {leftSide.length > 0 ? leftSide.map((item, index) => {
                                             console.log('leftSide:::::', leftSide);
                                             return (
                                                <React.Fragment>
                                                   <div className="row mb-5" key={item.border_leave_id} >
                                                      {leftSide[0].depart_status !== undefined && index === 0 &&
                                                         <div className='col-lg-12 mb-3'>
                                                            <div className='row'>
                                                               <div className='col-7'>
                                                                  <h5 className='font-weight-bold'>Student Name</h5>
                                                               </div>
                                                               <div className='col-5'>
                                                                  <div className="d-flex justify-content-between">
                                                                     <Tooltip title="Expected Time Departure" placement="top">
                                                                        <h5 className='font-weight-bold'>ETD</h5>
                                                                     </Tooltip>
                                                                     <h5 className='font-weight-bold'>Dormitory</h5>
                                                                     <h5 className='font-weight-bold'>Year</h5>
                                                                     <h5 className='font-weight-bold'>Status</h5>

                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      }

                                                      <div className="col-lg-7">
                                                         <div className='d-flex'>
                                                            <h5 className="text-muted">
                                                               {
                                                                  `${item.student_data?.student_first_name || item.intial_leave_data.leave_stu_data.student_first_name} 
                                                                  ${item.student_data?.student_last_name || item.intial_leave_data.leave_stu_data.student_last_name}`
                                                               }
                                                            </h5>
                                                            {item.departData !== undefined && <span className={`ms-3 badge badge-light-${item.return_status === "pending" || item.return_status === "schedule_changed" ? "danger" : "success"}`}>{item.return_status === "pending" || item.return_status === "schedule_changed" ? "On Leave" : "New Request"}</span>}
                                                         </div>
                                                         {item.departData !== undefined &&
                                                            <span className="text-gray-400">
                                                               From
                                                               <strong>{item.departure_date !== undefined ? moment(item.departure_date).format('DD MMM') : moment(item.departData.planned_depart_date).format('DD MMM')}</strong>
                                                               To
                                                               {current_date2.diff(moment(`${item.planned_return_date} ${item.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") < 24 ?
                                                                  <strong> {item.return_date !== undefined ? moment(item.return_date).format('DD MMM') : moment(item.planned_return_date).format('DD MMM')}</strong> :
                                                                  <strong className='text-danger'> {item.return_date !== undefined ? moment(item.return_date).format('DD MMM') : moment(item.planned_return_date).format('DD MMM')}</strong>
                                                               }
                                                            </span>
                                                         }
                                                      </div>
                                                      {console.log("Dsfsdgdgdfhfghf", item)}
                                                      {item.departData !== undefined ?
                                                         <div className="col-lg-5">
                                                            <div className="d-flex justify-content-end">
                                                               {console.log("Dsfsdgfdfgdfhdfh", current_date2.diff(moment(`${item.planned_return_date} ${item.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours"))}

                                                               <Tooltip title="View" placement="top">
                                                                  <Link to={`/${school_code}/leave/edit-boarders-leave/${item.border_leave_id}`}>
                                                                     {
                                                                        current_date2.diff(moment(`${item.planned_return_date} ${item.planned_return_time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'), "hours") < 24 ?
                                                                           <i className="bi bi-eye-fill text-primary fs-2 ms-2"></i> : <i className="bi bi-eye-fill text-danger fs-2 ms-2"></i>
                                                                     }
                                                                  </Link>
                                                               </Tooltip>
                                                            </div>
                                                         </div>
                                                         :
                                                         <div className="col-lg-5">
                                                            <div className="d-flex justify-content-between">
                                                               <h5 className="text-muted">{moment(item.planned_depart_time, "HH:mm").format("h:mm A")}</h5>
                                                               <h5 className="text-muted">Dormitory</h5>
                                                               <h5 className="text-muted">Year</h5>
                                                               <span >
                                                                  {
                                                                     item.depart_status === "pending" ? "-" :
                                                                        <img src="/assets/media/upside-arrow.png" style={{ maxHeight: "26px" }} />
                                                                  }
                                                               </span>
                                                            </div>
                                                         </div>
                                                      }

                                                   </div>
                                                </React.Fragment>)
                                          }) : <div><h4 className='text-center text-muted'>No record found</h4></div>} */}
                                       </div>
                                       {/* <div className="card-footer text-right">
                                          <Link to={`/${school_code}/leave/boarders-leave-details`} className="btn btn-sm btn-light-primary me-4">View All Leave Request
                                          </Link>
                                          <Link to={`/${school_code}/leave/present-student-list`} className="btn btn-sm btn-primary me-4">Add New Leave Request
                                          </Link>
                                       </div> */}
                                    </div>
                                 </div>



                                 <div className="col-lg-12 col-xl-6">
                                    <div className='row mb-5'>
                                       <div className="col-lg-6">
                                          <button className='border-0 bg-transparent p-0 m-0 w-100'
                                             // onClick={() => myFunction("returning_today")}
                                             onClick={() => { setRightSideHeader("Students Returning Today"); setRightSide(studentsReturningTodayList) }}
                                          >
                                             <div className="card bg-light-success card-xl-stretch">
                                                <div className="card-body d-flex align-items-center p-5 top-card-header">
                                                   <span href="#" className="card-title fw-bolder text-dark  fs-6 d-block mb-0">Students Returning Today</span>
                                                   <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center ms-auto">
                                                      <span className="text-dark fs-1 fw-bolder ">{studentsReturningTodayCount}</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </button>
                                       </div>
                                       <div className="col-lg-6">
                                          <button className='border-0 bg-transparent p-0 m-0 w-100'
                                             // onClick={() => myFunction("late_and_delay")}
                                             onClick={() => { setRightSideHeader("Students Late or Delayed"); setRightSide(numberOfStudentLateOrDelayedList) }}
                                          >
                                             <div className="card bg-light-danger  card-xl-stretch">
                                                <div className="card-body d-flex align-items-center p-5 top-card-header">
                                                   <span className="card-title fw-bolder text-dark  fs-6 d-block mb-0">Students Late or Delayed</span>
                                                   <div className="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 ms-4 text-center ms-auto">
                                                      <span className="text-dark fs-1 fw-bolder ">{numberOfStudentLateOrDelayedCount}</span>
                                                   </div>
                                                </div>
                                             </div>
                                          </button>
                                       </div>
                                    </div>
                                    <div className="card">
                                       <div className="card-header align-items-center">
                                          <h3 className="card-title">{rightSideHeader}</h3>
                                       </div>
                                       <div className="card-body pb-4 border_leave_dashboard_height">
                                          <div className="col-lg-6 mb-9">
                                             <label className="form-label">Select Dormitory</label>
                                             <Select
                                                className="react-bootstrap-typeahead tag-outer"
                                                options={dormitory_ids}
                                                value={dormitory_ids.find((option) =>
                                                   rightSideHeader === 'Students Returning Today'
                                                      ? option.value === dormitory_id_right
                                                      : option.value === dormitory_id_right2
                                                )}
                                                onChange={(event) => {
                                                   if (rightSideHeader === 'Students Returning Today') {
                                                      setdormitory_id_right(event.value);
                                                   } else {
                                                      setdormitory_id_right2(event.value);
                                                   }
                                                }}
                                             />
                                          </div>
                                          {rightSide.length > 0 ?
                                             <>
                                                <ReturningAndDelayed rightSide={rightSide} rightSideHeader={rightSideHeader} />
                                             </>
                                             : <div><h4 className='text-center text-muted'>No record found</h4></div>
                                          }
                                          {/* {rightSide.length > 0 ? rightSide.map((item, index) => {
                                             { console.log("item==============", item) }
                                             return (<React.Fragment>
                                                <div className="row mb-5" key={item.border_leave_id}>
                                                   {rightSide[0].return_status !== undefined && index == 0 &&
                                                      <div className='col-lg-12 mb-3'>
                                                         <div className='row'>
                                                            <div className='col-7'>
                                                               <h5>Student Name</h5>
                                                            </div>
                                                            <div className='col-5'>
                                                               <div className="d-flex justify-content-between">
                                                                  <Tooltip title=" Expected Time Return" placement="top">
                                                                     <h5 className='font-weight-bold'>ETR</h5>
                                                                  </Tooltip>
                                                                  <h5 className='font-weight-bold'>Dormitory</h5>
                                                                  <h5 className='font-weight-bold'>Year</h5>
                                                                  <h5 className='font-weight-bold'>Status</h5>

                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   }

                                                   <div className="col-lg-7">
                                                      <h5 className="text-muted">{`${item?.student_first_name === undefined ? item.student_data.student_first_name : item.student_first_name} ${item.student_last_name === undefined ? item.student_data.student_last_name : item.student_last_name}`}</h5>
                                                      {item.return_status === undefined &&
                                                         <span className="text-gray-400">From <strong>{moment(item.departData.planned_depart_date).format('DD MMM')}</strong> To <strong> {moment(item.planned_return_date).format('DD MMM')}</strong></span>
                                                      }
                                                   </div>
                                                   {
                                                      item.return_status !== undefined ?
                                                         <div className="col-lg-5 ">
                                                            <div className="d-flex justify-content-between">
                                                               <h5 className="text-muted">{moment(item.planned_return_time, "HH:mm").format("h:mm A")}</h5>
                                                               <h5 className="text-muted">Dormitory</h5>
                                                               <h5 className="text-muted">Year</h5>
                                                               <span>
                                                                  {
                                                                     item.return_status === "pending" ?
                                                                        current_date > moment(`${item.planned_return_date} ${item.planned_return_time}`, 'YYYY-MM-DD HH:mm:ss').format('MMMM Do YYYY, h:mm:ss a') ? <img src="/assets/media/late.png" style={{ maxHeight: "45px" }} /> : "-"
                                                                        : <img src="/assets/media/down-arrow.png" style={{ maxHeight: "26px" }} />
                                                                  }
                                                               </span>
                                                            </div>
                                                         </div>
                                                         :
                                                         <div className="col-lg-5 ">
                                                            <div className='d-flex flex-column'>
                                                               <Tooltip title="View" placement="top">
                                                                  <Link className='ms-auto' to={`/${school_code}/leave/edit-boarders-leave/${item.departData.border_leave_id}`}>
                                                                     <i className="bi bi-eye-fill text-primary fs-2 ms-auto"></i>
                                                                  </Link>
                                                               </Tooltip>
                                                               {item.formattedDelay &&
                                                                  <div className="border border-gray-300 border-dashed rounded min-w-100px w-100 py-1 px-4">
                                                                     {item.formattedDelay}
                                                                  </div>
                                                               }
                                                            </div>
                                                         </div>
                                                   }
                                                </div>
                                             </React.Fragment>)
                                          }) : <div><h4 className='text-center text-muted'>No record found</h4></div>} */}
                                       </div>
                                       {/* <div className="card-footer text-right">
                                          <Link to={`/${school_code}/leave/student-return`} className="btn btn-sm btn-primary">View All Students Returning</Link>
                                       </div> */}
                                    </div>
                                 </div>
                              </div>

                           </div>
                           {/* <!--end::Container--> */}
                        </div>
                     </div>
                     <Footer></Footer>
                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
export default ManageBoarderLeaveDashboard;